import { usePaymentsStore } from '@/store/PaymentsStore.ts'
import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { setConstructedPaymentData } from '@/Pages/PaymentsPage/PaymentPageHelpers.ts'
import { IPaymentProps } from '@/types/IPayments.ts'

function PaymentConfirmList({ setStep }: IPaymentProps) {
    const {
        selectedPaymentServices,
        confirmedIndexes,
        totalCost,
        inputCost,
        setSelectedPaymentServices,
        setTotalCost,
        setInputCost,
    } = usePaymentsStore()

    const handleChangeInputData = (index: number) => {
        if (+inputCost >= 100) {
            const newSelectedPaymentServices = [...selectedPaymentServices]
            newSelectedPaymentServices[index].services.cost = +inputCost
            newSelectedPaymentServices[index].services.changed = true
            setSelectedPaymentServices(newSelectedPaymentServices)
        } else {
            toast('Минимальная сумма - 100 руб.')
        }

        if (!confirmedIndexes.includes(index)) {
            confirmedIndexes.push(index)
        }

        setInputCost(0)
    }

    const handleEditInputData = (index: number) => {
        const newSelectedPaymentServices = [...selectedPaymentServices]
        if (confirmedIndexes.includes(index)) {
            newSelectedPaymentServices[index].services.cost = 0
            setSelectedPaymentServices(newSelectedPaymentServices)
        }
    }

    useEffect(() => {
        let sum = 0
        selectedPaymentServices?.forEach((paymentService) => {
            sum += paymentService.services?.cost
        })
        setTotalCost(sum + sum * 0.02)
    }, [selectedPaymentServices])

    const stepBack = () => {
        setStep('variants')
    }

    const handleDeleteService = (index: number) => {
        if (confirm('Вы уверены, что хотите удалить платеж?')) {
            const newSelectedPaymentServices = [...selectedPaymentServices]
            newSelectedPaymentServices.splice(index, 1)
            setSelectedPaymentServices(newSelectedPaymentServices)
            confirmedIndexes.splice(index, 1)
        }
    }

    const handleShowPaymentMethods = () => {
        if (selectedPaymentServices.length && totalCost > 100) {
            setConstructedPaymentData()
            setStep('method')
        } else {
            toast(
                'Необходимо добавить хотя бы 1 платеж на сумму не менее 100 руб.',
            )
        }
    }

    return (
        <div className="main__card-body-confirm-list">
            {selectedPaymentServices.map((paymentService, index) => (
                <div className="main__card-body-confirm-list-item" key={index}>
                    <p className="main__card-body-confirm-list-item-category-name">
                        {paymentService?.category?.name}
                    </p>
                    <p className="main__card-body-confirm-list-item-service-name">
                        {paymentService?.services?.name}
                        <FontAwesomeIcon
                            icon={['fas', 'times']}
                            className="main__card-body-confirm-list-item-service-name-delete-icon"
                            onClick={() => handleDeleteService(index)}
                        />
                    </p>
                    <div className="main__card-body-confirm-list-item-cost">
                        {selectedPaymentServices[index]?.services?.cost !==
                        0 ? (
                            <>
                                <p>
                                    {
                                        selectedPaymentServices[index]?.services
                                            ?.cost
                                    }{' '}
                                    руб.
                                </p>
                                {confirmedIndexes.includes(index) && (
                                    <FontAwesomeIcon
                                        icon={['fas', 'pencil']}
                                        onClick={
                                            () =>
                                                handleEditInputData(
                                                    index,
                                                ) /*!todo При редактировании инпут пустой*/
                                        }
                                        className="main__card-body-confirm-list-item-cost-edit-cost-icon"
                                    />
                                )}
                            </>
                        ) : (
                            <div className="input-group">
                                <input
                                    type="number"
                                    pattern="[0-9]"
                                    className="main__card-body-confirm-list-item-cost-input form-control"
                                    placeholder="Минимум 100р"
                                    onChange={(e) =>
                                        setInputCost(e.target.value)
                                    }
                                />
                                <button
                                    className="main__card-body-confirm-list-item-cost-submit-btn btn btn-success"
                                    type="button"
                                    onClick={() => handleChangeInputData(index)}
                                >
                                    <FontAwesomeIcon icon={['fas', 'check']} />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            ))}
            <button
                className="main__card-body-confirm-list-add btn btn-outline-warning"
                onClick={() => stepBack()}
            >
                <FontAwesomeIcon icon={['fas', 'plus']} />
                <p>Добавить услугу</p>
            </button>
            <p className="main__card-body-confirm-list-cost-result">
                На баланс будет зачислено:{' '}
                <span>{Math.ceil(totalCost - totalCost * 0.02)} руб.</span>
            </p>
            <p className="main__card-body-confirm-list-cost-result mb-2">
                Использование образовательной платформы:
                <span> 2%</span>
            </p>
            <button
                className="main__card-body-confirm-list-cost-pay-bill btn btn-success"
                onClick={() => handleShowPaymentMethods()}
            >
                Оплатить {Math.floor(totalCost)} руб.
            </button>
        </div>
    )
}

export default PaymentConfirmList
