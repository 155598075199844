import { INegative } from '@/types/IPracticeData.ts'
import { Dispatch, SetStateAction } from 'react'

interface Props {
    negativeItems: INegative[]
    selectedNegatives: string[]
    setSelectedNegatives: Dispatch<SetStateAction<string[]>>
    selectedRating: number
}

const NegativesList = ({
    negativeItems,
    selectedNegatives,
    setSelectedNegatives,
    selectedRating,
}: Props) => {
    const handleSelectNegatives = (name: string) => {
        if (selectedNegatives.includes(name)) {
            setSelectedNegatives(
                selectedNegatives.filter((item) => item !== name),
            )
        } else {
            setSelectedNegatives([...selectedNegatives, name])
        }
    }

    return (
        <>
            {selectedRating < 5 && (
                <div className="practice__review-modal-negatives">
                    <h3 className="practice__review-modal-negatives-title">
                        Что вас разочаровало?
                    </h3>
                    <div className="practice__review-modal-negatives-list list-group">
                        {negativeItems?.map((negative, index) => (
                            <div
                                key={index}
                                className={`practice__review-modal-negatives-list-item list-group-item ${
                                    selectedNegatives.includes(negative.name)
                                        ? 'selected'
                                        : ''
                                } `}
                                onClick={() =>
                                    handleSelectNegatives(negative.name)
                                }
                            >
                                {negative.name}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}

export default NegativesList
