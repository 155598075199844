import RestorePhone from './RestorePhone'
import RestoreCode from './RestoreCode'
import RestorePassword from './RestorePassword'
import { useRestoreAccountStore } from '@/store/RestoreAccountStore.ts'
import { useFadeTransition } from '@/components/FadeTransition.tsx'
import { animated } from 'react-spring'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function RestorePasswordPage() {
    const { restorePhoneChecked, resetRestoreData } = useRestoreAccountStore()
    const animate = useFadeTransition(restorePhoneChecked)
    const location = useLocation()

    useEffect(() => {
        if (location.pathname !== '/restore') {
            resetRestoreData()
        }
    }, [location.pathname])

    return (
        <div className="authorization__form-container">
            <h1 className="authorization__form-header">
                Восстановление пароля
            </h1>
            {/*!todo Делить на 3 компонента, где useForm, где-то нет, + глобальный стор (который остаётся памяти даже когда выйдешь со страницы) так себе получается*/}
            {/*Но в целом компонент работает и код читабельный, так что переделывать наверно не стоит*/}
            <RestorePhone />
            <>
                {animate(
                    (style, item) =>
                        item && (
                            <animated.div style={style}>
                                <RestoreCode />
                                <RestorePassword />
                            </animated.div>
                        ),
                )}
            </>
        </div>
    )
}

export default RestorePasswordPage
