import Skeleton from 'react-loading-skeleton'

const InstructorProfileSkeleton = () => {
    return (
        <>
            <div className="d-flex align-items-center p-4 mt-5">
                <div className="px-3 d-flex flex-column align-items-center">
                    <Skeleton height={200} width={150} />
                    <Skeleton width={100} className="mt-2" />
                </div>
                <div className="w-100">
                    <Skeleton count={6} />
                </div>
            </div>
            <div className="page d-flex flex-column p-4">
                <div className="mt-2 mt-md-5 col-lg-4">
                    <Skeleton borderRadius={0} height={200} />
                </div>
                <div className="card col-lg-4 p-0">
                    <div className="card-footer fs-lg-14 fs-md-12 fs-sm-11 p-4 bg-white">
                        <Skeleton count={3} />
                    </div>
                </div>
                <div className="my-5">
                    <div className="mb-3">
                        <Skeleton width={200} className="mb-1" />
                        <Skeleton width={400} />
                    </div>
                    <div className="card">
                        <Skeleton width={200} />
                        <Skeleton height={50} />
                    </div>
                </div>
                <div>
                    <div className="mb-3">
                        <Skeleton width={200} className="mb-1" />
                        <Skeleton width={400} />
                    </div>
                    <div className="card my-3">
                        <Skeleton count={4} />
                    </div>
                    <div className="card my-3">
                        <Skeleton count={4} />
                    </div>
                    <div className="card my-3">
                        <Skeleton count={4} />
                    </div>
                    <div className="card my-3">
                        <Skeleton count={4} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default InstructorProfileSkeleton
