import { useVideoListStore } from '@/store/VideoListStore.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'
import VideoLessonsPlayer from '@/Pages/VideoLessons/VideoLessonsPlayer.tsx'
import { IVideoIconProps } from '@/types/IPlayer.ts'

function VideoLessonsList() {
    const { videoList, setCurrentVideo, currentVideo } = useVideoListStore()

    const videoIcon = ({ rewind, open, viewed }: IVideoIconProps) => {
        let icon = rewind ? (
            <FontAwesomeIcon icon={['fal', 'star']} color="orange" size="xl" />
        ) : (
            <FontAwesomeIcon icon={['fal', 'circle']} color="black" size="xl" />
        )

        if (open) {
            if (viewed) {
                icon = (
                    <FontAwesomeIcon
                        icon={['fal', 'circle-check']}
                        color="green"
                        size="xl"
                    />
                )
            } else {
                icon = (
                    <FontAwesomeIcon
                        icon={['fal', 'circle-pause']}
                        color="orange"
                        size="xl"
                    />
                )
            }
        }

        return icon
    }

    return (
        <>
            <h2 className="common__title">Видеоуроки</h2>
            {videoList &&
                videoList.map((videoLesson, index) => (
                    <div
                        key={index}
                        className="video__page-card"
                        onClick={() => setCurrentVideo(videoLesson)}
                    >
                        <div className="video__page-card-body">
                            <div className="video__page-card-body-icon">
                                {videoIcon(videoLesson)}
                            </div>
                            <div className="video__page-card-body-text">
                                <p className="video__page-card-body-text-name">
                                    {videoLesson.name}
                                </p>
                                <p className="video__page-card-body-text-status">
                                    {videoLesson.description}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            <Modal size="xl" show={!!currentVideo} centered backdrop="static">
                <VideoLessonsPlayer />
            </Modal>
        </>
    )
}
export default VideoLessonsList
