import Skeleton from 'react-loading-skeleton'

const PaymentMethodSkeleton = () => {
    return (
        <>
            <div className="row d-flex mb-4 align-items-stretch">
                <div className="col-6 col-md-3 px-3 mb-1">
                    <div className="pick-payment-card-skeleton">
                        <Skeleton count={3} />
                    </div>
                </div>
                <div className="col-6 col-md-3 px-3 mb-1">
                    <div className="pick-payment-card-skeleton">
                        <Skeleton count={3} />
                    </div>
                </div>
                <div className="col-6 col-md-3 px-3 mb-1">
                    <div className="pick-payment-card-skeleton">
                        <Skeleton count={3} />
                    </div>
                </div>
                <div className="col-6 col-md-3 px-3 mb-1">
                    <div className="pick-payment-card-skeleton">
                        <Skeleton count={3} />
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column flex-md-row gap-2 gap-md-4 mb-1 px-3">
                <div className="flex-grow-1">
                    <Skeleton height={40} />
                </div>
                <div className="flex-grow-1">
                    <Skeleton height={40} />
                </div>
            </div>
            <div className="mb-3 px-3 w-25">
                <Skeleton />
            </div>
            <div className="mb-1 col-12 col-sm-3">
                <Skeleton height={40} className="mx-3" />
            </div>
            <div className="px-3" style={{ lineHeight: '.8' }}>
                <Skeleton className="mb-3" />
                <Skeleton count={2} height={10} />
            </div>
        </>
    )
}

export default PaymentMethodSkeleton
