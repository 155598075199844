import api from '@/api/api.ts'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { FormToastError } from '@/lib/FormToastError.ts'
import TelegramAuthModal from '@/components/Modals/TelegramAuthModal.tsx'
import { useEffect } from 'react'
import { useAuthWithTelegramStore } from '@/store/AuthWithTelegramStore.ts'
import { useAuthUserStore } from '@/store/AuthUserStore.ts'
import { useAuthTokenStore } from '@/store/AuthTokenStore.ts'
import { useAuthStatusStore } from '@/store/AuthStatusStore.ts'
import { LoadingSpinner } from '@/components/Forms/Loading.tsx'
import { useLoadingStateStore } from '@/store/LoadingStateStore.ts'
import PhoneFormat from '@/components/PhoneFormat.tsx'
import { customToast } from '@/lib/CustomToast.ts'

const LOGIN_URL = '/auth/login'
const CHECK_TELEGRAM = '/auth/telegram/check'

const cleanPhone = (phone: string) => phone.replace(/[\s-\D]/g, '')

const checkRequest = async (phone: string) => {
    const { setPhone } = useAuthUserStore.getState()
    const { setTelegramConfirm, setPasswordFieldVisible, setModalVisible } =
        useAuthWithTelegramStore.getState()
    const { setButtonLoading } = useLoadingStateStore.getState()

    setPhone(phone)
    setButtonLoading(true)
    await api
        .post(
            CHECK_TELEGRAM,
            { phone },
            { headers: { 'Content-Type': 'application/json' } },
        )
        .then((response) => {
            if (response.data.tg_confirm) {
                setTelegramConfirm(true)
                setModalVisible(true)
            } else {
                setTelegramConfirm(false)
                setPasswordFieldVisible(true)
            }
        })
        .catch((error) =>
            customToast({
                message: error.response.data.message,
                type: 'error',
            }),
        )
}

const submitLogin = async (phone: string, password: string) => {
    const { setButtonLoading } = useLoadingStateStore.getState()
    const { setPhone, setPassword } = useAuthUserStore.getState()
    const { setToken } = useAuthTokenStore.getState()
    const { setPasswordFieldVisible } = useAuthWithTelegramStore.getState()
    const { setAuthenticated } = useAuthStatusStore.getState()

    setButtonLoading(true)
    setPhone(phone)
    setPassword(password)
    await api
        .post(
            LOGIN_URL,
            { phone, password },
            { headers: { 'Content-Type': 'application/json' } },
        )
        .then((response) => setToken(response.data.access_token))
        .catch((error) =>
            customToast({
                message: error.response.data.message,
                type: 'error',
            }),
        )
        .finally(() => {
            setButtonLoading(false)
            setPasswordFieldVisible(false)
            setAuthenticated(true)
        })
}

const handleCloseTelegramModal = () => {
    const { setTelegramConfirm, setModalVisible } =
        useAuthWithTelegramStore.getState()
    setTelegramConfirm(false)
    setModalVisible(false)
}

interface IFormData {
    phone: string
    password: string
}

export default function LoginPage() {
    const navigate = useNavigate()
    const location = useLocation()

    const {
        telegramConfirm,
        passwordFieldVisible,
        setPasswordFieldVisible,
        modalVisible,
    } = useAuthWithTelegramStore()
    const {
        setPageLoading: setIsPageLoading,
        buttonLoading: isButtonLoading,
        setButtonLoading: setIsButtonLoading,
    } = useLoadingStateStore()
    const { registerConfirmed, restoreAccountConfirmed } = useAuthStatusStore()
    const { phone } = useAuthUserStore()

    const { register, handleSubmit, control, setValue } = useForm<IFormData>({
        defaultValues: {
            phone: registerConfirmed || restoreAccountConfirmed ? phone : '',
            password: '',
        },
    })

    const onSubmit = async (formData: IFormData) => {
        const phone = cleanPhone(formData.phone)
        if (!telegramConfirm && passwordFieldVisible) {
            //!todo+ Убери then
            await submitLogin(phone, formData.password)
            navigate('/')
        }
        if (!telegramConfirm && !passwordFieldVisible) {
            //!todo+ Убери then
            await checkRequest(phone)
            setIsButtonLoading(false)
        }
    }

    useEffect(() => {
        if (location.pathname !== '/login') {
            setPasswordFieldVisible(false)
        }
    }, [location.pathname])

    return (
        <div className="authorization__form-container">
            <h1 className="authorization__form-header">
                Личный кабинет ученика
            </h1>
            <form
                id="login-form"
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit, FormToastError)}
                // onKeyDown={(e) => {
                //     if (e.key === 'Enter') {
                //         e.preventDefault()
                //     }
                // }}
            >
                <div className="authorization__form-group">
                    <label
                        htmlFor="login-phone"
                        className="authorization__form-group-label"
                    >
                        Номер телефона
                    </label>
                    <Controller
                        name="phone"
                        control={control}
                        rules={{
                            required:
                                'Поле Номер телефона обязательно для заполнения',
                            validate: {
                                minLengthPhone: (v: string) =>
                                    v.replace(/[\s-\D]/g, '').length == 11
                                        ? true
                                        : 'Номер телефона введен некорректно',
                            },
                        }}
                        render={({ field }) => (
                            <PhoneFormat
                                {...field}
                                id="login-phone"
                                className="authorization__form-group-input form-control"
                                onChange={(e) => {
                                    const current = e.target.value.replace(
                                        /[\s-\D]/g,
                                        '',
                                    )
                                    setValue('phone', current)
                                    setValue('password', '')
                                    setPasswordFieldVisible(null)
                                }}
                            />
                        )}
                    />
                    {passwordFieldVisible && (
                        <>
                            <label
                                htmlFor="login-password"
                                className="authorization__form-group-label"
                            >
                                Пароль
                            </label>
                            <input
                                className="authorization__form-group-input form-control"
                                type="password"
                                id="login-password"
                                autoComplete="off"
                                {...register('password', {
                                    required:
                                        'Поле Пароль обязательно для заполнения',
                                    minLength: {
                                        value: 8,
                                        message:
                                            'Пароль должен иметь минимум 8 символов',
                                    },
                                })}
                            />
                        </>
                    )}
                </div>
                <button
                    type="submit"
                    className="btn btn-danger authorization__form-button"
                >
                    {!isButtonLoading ? (
                        'Вход'
                    ) : (
                        <LoadingSpinner
                            isLoading={isButtonLoading}
                            primaryColor="white"
                            secondaryColor="white"
                        />
                    )}
                </button>
                <Link
                    to="/register"
                    className="btn btn-danger authorization__form-button"
                >
                    Регистрация
                </Link>
                <Link to="/restore" className="authorization__form-forgot-link">
                    Забыли пароль?
                </Link>
            </form>

            <TelegramAuthModal
                show={modalVisible}
                onHide={() => handleCloseTelegramModal()}
            />
        </div>
    )
}
