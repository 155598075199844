import { useTransition } from 'react-spring'

export const useFadeTransition = (show: boolean) => {
    return useTransition(show, {
        exitBeforeEnter: true,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    })
}
