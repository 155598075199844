import { Link, useNavigate } from 'react-router-dom'
import { useRestoreAccountStore } from '@/store/RestoreAccountStore.ts'
import api from '@/api/api.ts'
import PasswordRequirements from '@/Pages/LoginPage/PasswordRequirements.tsx'
import { useState } from 'react'
import { LoadingSpinner } from '@/components/Forms/Loading.tsx'
import { customToast } from '@/lib/CustomToast.ts'
import { useAuthStatusStore } from '@/store/AuthStatusStore.ts'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { FormToastError } from '@/lib/FormToastError.ts'

interface IRestoreData {
    phone: string
    token: string
    password: string
    password_confirmation: string
}

export default function RestorePassword() {
    const { setRestoreAccountConfirmed } = useAuthStatusStore()
    const { phone, restoreCode } = useRestoreAccountStore()

    const { register, watch, handleSubmit } = useForm({
        defaultValues: {
            phone: phone,
            token: restoreCode,
            password: '',
            password_confirmation: '',
        },
    })

    const [isPasswordValidatorVisible, setIsPasswordValidatorVisible] =
        useState(false)
    const passwordValidatorVisible = isPasswordValidatorVisible
        ? 'visible'
        : 'hidden'

    const navigate = useNavigate()

    const saveRestoreData = useMutation<any, AxiosError<any>, IRestoreData>({
        mutationFn(data) {
            return api.post('/auth/restore/save', data)
        },
        onSuccess(res) {
            customToast({
                message: res.data.message,
                type: 'success',
            })
            setRestoreAccountConfirmed(true)
            navigate('/login')
        },
        onError(error) {
            customToast({
                message: error.response.data.message,
                type: 'error',
            })
        },
    })

    const handleRestoreAccount = async (data: IRestoreData) => {
        //!todo+ Убери then
        if (restoreCode.length === 6) {
            saveRestoreData.mutate({ ...data, token: restoreCode })
        } else {
            customToast({ message: 'Введите полученный код', type: 'error' })
        }
    }

    return (
        <form
            id="restore-password"
            autoComplete="off"
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                }
            }}
            onSubmit={handleSubmit(handleRestoreAccount, FormToastError)}
        >
            <div className="authorization__form-group">
                <label
                    htmlFor="password"
                    className="authorization__form-group-label"
                >
                    Введите пароль
                </label>
                <div className="position-relative">
                    <input
                        className="authorization__form-group-input form-control"
                        type="password"
                        id="password"
                        autoComplete="off"
                        onFocus={() => setIsPasswordValidatorVisible(true)}
                        {...register('password', {
                            onBlur: () => setIsPasswordValidatorVisible(false),
                            required: 'Поле пароль обязательно для заполнения',
                            validate: {
                                minLength: (v) =>
                                    v.length >= 8 ||
                                    'Пароль должен содержать минимум 8 символов',
                                hasUppercase: (v) =>
                                    /[A-Z]/.test(v) ||
                                    'Пароль должен содержать хотя бы одну заглавную букву',
                                hasLowercase: (v) =>
                                    /[a-z]/.test(v) ||
                                    'Пароль должен содержать хотя бы одну строчную букву',
                                hasDigit: (v) =>
                                    /[0-9]/.test(v) ||
                                    'Пароль должен содержать хотя бы одну цифру',
                                hasSpecialChar: (v) =>
                                    /[!@#$%^&*(),.?":{}|<>]/.test(v) ||
                                    'Пароль должен содержать хотя бы один специальный символ',
                            },
                        })}
                    />
                    <PasswordRequirements
                        password={watch('password')}
                        className={passwordValidatorVisible}
                    />
                </div>

                <label
                    htmlFor="password_confirmation"
                    className="authorization__form-group-label"
                >
                    Повторите пароль
                </label>
                <input
                    className="authorization__form-group-input form-control"
                    type="password"
                    id="password_confirmation"
                    autoComplete="off"
                    {...register('password_confirmation', {
                        required:
                            'Поле Повторите пароль обязательно для заполнения',
                        validate: (val: string) => {
                            if (watch('password') != val) {
                                return 'Пароли не совпадают'
                            }
                        },
                    })}
                />
            </div>

            <button
                type="submit"
                className="btn btn-danger authorization__form-button"
            >
                {saveRestoreData.isPending ? (
                    <LoadingSpinner
                        isLoading={saveRestoreData.isPending}
                        primaryColor="white"
                        secondaryColor="white"
                    />
                ) : (
                    'Подтвердить'
                )}
            </button>
            <Link
                to="/login"
                className="authorization__form-already-registered-link"
            >
                Назад
            </Link>
        </form>
    )
}
