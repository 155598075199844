import { create } from 'zustand'

interface AuthUserState {
    isAuthenticated: boolean
    setAuthenticated: (arg: boolean) => void
    registerConfirmed: boolean
    setRegisterConfirmed: (arg: boolean) => void
    restoreAccountConfirmed: boolean
    setRestoreAccountConfirmed: (arg: boolean) => void
}

export const useAuthStatusStore = create<AuthUserState>((set) => ({
    isAuthenticated: false,
    setAuthenticated: (arg: boolean) => set({ isAuthenticated: arg }),
    registerConfirmed: false,
    setRegisterConfirmed: (registerConfirmed) => set({ registerConfirmed }),
    restoreAccountConfirmed: false,
    setRestoreAccountConfirmed: (restoreAccountConfirmed) =>
        set({ restoreAccountConfirmed }),
}))
