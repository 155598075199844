import { useTicketsTypeStore } from '@/store/TicketsTypeStore.ts'
import { useSystemSettingsStore } from '@/store/SystemSettingsStore.ts'

export const changeToCDTicketsType = (
    setSidebarVisible: (isSidebarVisible: boolean) => void,
    isSidebarVisible: boolean,
) => {
    useSystemSettingsStore.getState().isWindowWide
        ? setSidebarVisible(true)
        : setSidebarVisible(!isSidebarVisible)
    useTicketsTypeStore.getState().setActiveTicketsType('CD')
    // useExamStore.getState().reset()
    // useTicketsQuestionsStore.getState().resetExamQuestions()
    // useMarathonStore.getState().reset()
    // useTicketsStatisticsStore.getState().reset()
}
export const changeToABMTicketsType = (
    setSidebarVisible: (isSidebarVisible: boolean) => void,
    isSidebarVisible: boolean,
) => {
    useSystemSettingsStore.getState().isWindowWide
        ? setSidebarVisible(true)
        : setSidebarVisible(!isSidebarVisible)
    useTicketsTypeStore.getState().setActiveTicketsType('ABM')
    // useExamStore.getState().reset()
    // useTicketsQuestionsStore.getState().resetExamQuestions()
    // useMarathonStore.getState().reset()
    // useTicketsStatisticsStore.getState().reset()
}

export const moveToAnotherPage = (
    setSidebarVisible: (isSidebarVisible: boolean) => void,
    isSidebarVisible: boolean,
) => {
    useSystemSettingsStore.getState().isWindowWide
        ? setSidebarVisible(true)
        : setSidebarVisible(!isSidebarVisible)
    // useTicketsTypeStore.getState().reset()
    // useExamStore.getState().reset()
    // useTicketsQuestionsStore.getState().resetExamQuestions()
    // useMarathonStore.getState().reset()
    // useTicketsStatisticsStore.getState().reset()
}
