import UserBalanceInfo from '@/Pages/PaymentsPage/UserBalanceInfo.tsx'
import { IBalance } from '@/types/IPayments.ts'

interface Props {
    userBalance: IBalance
}

const UserBalance = ({ userBalance }: Props) => {
    return (
        <>
            {userBalance && (
                <div className="user__balance">
                    <h2 className="common__title">
                        Баланс{' '}
                        <p className="fw-normal">
                            Кликай на категорию, чтобы узнать подробнее
                        </p>
                    </h2>
                    <div className="user__balance-card card">
                        <UserBalanceInfo userBalance={userBalance} />
                    </div>
                </div>
            )}
        </>
    )
}

export default UserBalance
