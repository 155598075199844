import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSystemSettingsStore } from '@/store/SystemSettingsStore.ts'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { IDrives, IPracticeData } from '@/types/IPracticeData.ts'
import { useQuery } from '@tanstack/react-query'
import api from '@/api/api.ts'
import DriveReviewModal from '@/Pages/PracticePage/DriveReviewModal.tsx'
import { LoadingSpinner } from '@/components/Forms/Loading.tsx'
import { useState } from 'react'

interface Props {
    practiceData: IPracticeData
}

const DriveCards = ({ practiceData }: Props) => {
    const { isWindowWide } = useSystemSettingsStore()

    const [currentDriveCard, setCurrentDriveCard] = useState<IDrives>(null)

    const { data: reviewItems, isLoading: isReviewItemsLoading } = useQuery({
        queryKey: ['practice', 'review', 'items'],
        queryFn: () => api.get(`/instructor/drive/review_items`),
    })

    return (
        <>
            {!practiceData?.drives.length && (
                <p className="text-body-tertiary mb-4">Занятий еще не было</p>
            )}

            {practiceData?.drives.length > 0 &&
                practiceData?.drives.map((drive, index) => (
                    <div key={index} className="practice__page-card card">
                        <div className="practice__page-card-body">
                            <div>
                                <p className="text-body-secondary">
                                    {drive.type} {' | '}
                                    {format(drive.date, 'Pp', {
                                        locale: ru,
                                    })}
                                </p>
                                <h4>{drive.instructor.name}</h4>
                            </div>
                            {drive?.rating ? (
                                <div>
                                    {Array.from({ length: 5 }, (_, index) => (
                                        <FontAwesomeIcon
                                            key={index}
                                            icon={['fas', 'star']}
                                            color={
                                                index < drive.rating
                                                    ? '#fed321'
                                                    : 'lightgray'
                                            }
                                        />
                                    ))}
                                </div>
                            ) : (
                                <button
                                    className="btn btn-outline-warning"
                                    onClick={() => setCurrentDriveCard(drive)}
                                >
                                    {isReviewItemsLoading ? (
                                        <LoadingSpinner isLoading />
                                    ) : (
                                        'Оценить'
                                    )}
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            {reviewItems?.data && (
                <DriveReviewModal
                    driveReviewItems={reviewItems.data}
                    currentDrive={currentDriveCard}
                    setCurrentDrive={setCurrentDriveCard}
                />
            )}
        </>
    )
}

export default DriveCards
