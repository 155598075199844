import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMarathonStore } from '@/store/MarathonStore.ts'

const MarathonEndModalBody = () => {
    const { marathonSingleStatistic } = useMarathonStore()
    return (
        <div className="marathon-end__modal-body">
            <h3 className="marathon-end__modal-body-title">
                Результат текущего марафона
            </h3>
            <div className="marathon-end__modal-body-points">
                <p className="marathon-end__modal-body-points-point">
                    <FontAwesomeIcon
                        icon={['fas', 'check']}
                        color="green"
                        width={15}
                    />
                    Правильных ответов:{' '}
                    <span className="marathon-end__modal-body-points-point-text">
                        {marathonSingleStatistic.correctAnswers}
                    </span>
                </p>
                <p className="marathon-end__modal-body-points-point">
                    <FontAwesomeIcon
                        icon={['fas', 'xmark']}
                        color="red"
                        width={15}
                    />
                    Неправильных ответов:{' '}
                    <span className="marathon-end__modal-body-points-point-text">
                        {marathonSingleStatistic.incorrectAnswers}
                    </span>
                </p>
                <p className="marathon-end__modal-body-points-point">
                    <FontAwesomeIcon
                        icon={['fas', 'question']}
                        color="orange"
                        width={15}
                    />
                    Нерешенных вопросов:{' '}
                    <span className="marathon-end__modal-body-points-point-text">
                        {marathonSingleStatistic.unansweredQuestions}
                    </span>
                </p>
                <p className="marathon-end__modal-body-points-point">
                    <FontAwesomeIcon
                        icon={['far', 'clock']}
                        color="black"
                        width={15}
                    />
                    Затраченное время:{' '}
                    <span className="marathon-end__modal-body-points-point-text">
                        {marathonSingleStatistic.time || '00:00'}
                    </span>
                </p>
            </div>
        </div>
    )
}

export default MarathonEndModalBody
