import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface AuthTokenState {
    token: string | null
    setToken: (token: string | null) => void
    resetToken: () => void
}

export const useAuthTokenStore = create<AuthTokenState>()(
    persist(
        (set) => ({
            token: null,
            setToken: (token: string | null) => set({ token }),
            resetToken: () => set({ token: null }),
        }),
        {
            name: 'token',
        },
    ),
)
