import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
    useLocation,
} from 'react-router-dom'
import App from '@/App'
import MainLayout from '@/components/MainLayout/MainLayout.tsx'
import { useAuthTokenStore } from '@/store/AuthTokenStore.ts'
import MainPage from '@/Pages/MainPage/MainPage'
import TicketsCardLayout from '@/Pages/Tickets/TicketsCardLayout.tsx'
import ExamLayout from '@/Pages/Tickets/Exam/ExamLayout.tsx'
import MarathonPreview from '@/Pages/Tickets/Marathon/MarathonPreview.tsx'
import VideoLessonsPage from '@/Pages/VideoLessons/VideoLessonsPage.tsx'
import InstructorsPage from '@/Pages/InstructorsPage/InstructorsPage.tsx'
import InstructorProfile from '@/Pages/InstructorsPage/InstructorProfile/InstructorProfile.tsx'
import SchoolExamsPage from '@/Pages/SchoolExamsPage/SchoolExamsPage.tsx'
import AuthorizationPageLayout from '@/Pages/LoginPage/AuthorizationPageLayout.tsx'
import LoginPage from '@/Pages/LoginPage/LoginPage.tsx'
import RegisterPage from '@/Pages/LoginPage/RegisterPage.tsx'
import NotFoundPage from '@/Pages/NotFoundPage/NotFoundPage.tsx'
import Statistics from '@/Pages/Tickets/Statistics/StatisticsLayout.tsx'
import PaymentsPage from '@/Pages/PaymentsPage/PaymentsPage.tsx'
import { FadeInOutAnimate } from '@/components/FadeInOutAnimate.tsx'
import PracticePage from '@/Pages/PracticePage/PracticePage.tsx'
import Marathon from '@/Pages/Tickets/Marathon/Marathon.tsx'
import MarathonStatistics from '@/Pages/Tickets/Marathon/MarathonStatistics.tsx'
import CurrentTicket from '@/Pages/Tickets/CurrentTicket.tsx'
import ThemesTickets from '@/Pages/Tickets/ThemesTickets/ThemesTickets.tsx'
import CurrentThemeTicket from '@/Pages/Tickets/ThemesTickets/CurrentThemeTicket.tsx'
import RestorePasswordPage from '@/Pages/LoginPage/RestorePasswordPage/RestorePasswordPage'

function RoutesComponent() {
    const token = useAuthTokenStore((state) => state.token)
    const location = useLocation()

    return (
        <Routes location={location}>
            <Route path="/" element={<App />}>
                <Route
                    index
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <MainPage />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/login" replace />
                        )
                    }
                />
                <Route
                    path="/tickets"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <TicketsCardLayout />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/tickets/:id"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    {/*<TicketCard timer={true} />*/}
                                    <CurrentTicket />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/tickets/themes"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <ThemesTickets />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/tickets/themes/:id"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <CurrentThemeTicket />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/tickets/exam"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <ExamLayout />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/tickets/marathon"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <MarathonPreview />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/tickets/marathon/start"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <Marathon />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/tickets/marathon/statistics"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <MarathonStatistics />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/tickets/statistics"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <Statistics />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/lessons"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <VideoLessonsPage />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/instructors"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <InstructorsPage />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/instructors/:id"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <InstructorProfile />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/practice"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <PracticePage />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/exams"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <SchoolExamsPage />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/payments"
                    element={
                        token !== null ? (
                            <MainLayout>
                                <FadeInOutAnimate>
                                    <PaymentsPage />
                                </FadeInOutAnimate>
                            </MainLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/login"
                    element={
                        token === null ? (
                            <AuthorizationPageLayout>
                                <FadeInOutAnimate>
                                    <LoginPage />
                                </FadeInOutAnimate>
                            </AuthorizationPageLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/register"
                    element={
                        token === null ? (
                            <AuthorizationPageLayout>
                                <FadeInOutAnimate>
                                    <RegisterPage />
                                </FadeInOutAnimate>
                            </AuthorizationPageLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/restore"
                    element={
                        token === null ? (
                            <AuthorizationPageLayout>
                                <FadeInOutAnimate>
                                    <RestorePasswordPage />
                                </FadeInOutAnimate>
                            </AuthorizationPageLayout>
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />
                <Route
                    path="/*"
                    element={
                        <FadeInOutAnimate>
                            <NotFoundPage />
                        </FadeInOutAnimate>
                    }
                />
            </Route>
        </Routes>
    )
}

export function MainRouter() {
    return (
        <BrowserRouter>
            <RoutesComponent />
        </BrowserRouter>
    )
}
