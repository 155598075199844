import { useMarathonStore } from '@/store/MarathonStore.ts'
import { Pagination } from 'react-bootstrap'
import { useState } from 'react'
import { formatTime } from '@/Pages/Tickets/Marathon/MarathonHelpers.tsx'
import MarathonEndModal from '@/components/Modals/DefaultModal/DefaultModal.tsx'
import MarathonEndModalBody from '@/Pages/Tickets/Marathon/MarathonEndModalBody.tsx'
import MarathonStatisticsSkeleton from '@/Pages/Tickets/Marathon/MarathonStatisticsSkeleton.tsx'
import { useQuery } from '@tanstack/react-query'
import api from '@/api/api.ts'
import { useTicketsTypeStore } from '@/store/TicketsTypeStore.ts'

function MarathonStatistics() {
    const { showMarathonEndModal, setShowMarathonEndModal } = useMarathonStore()
    const { activeTicketsType } = useTicketsTypeStore()
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 20

    const { data: statisticData } = useQuery({
        queryKey: ['marathon-statistic'],
        queryFn: () =>
            api.get(`tickets/marathon/${activeTicketsType}/statistic`),
        enabled: !!activeTicketsType,
    })

    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = statisticData?.data.slice(
        indexOfFirstItem,
        indexOfLastItem,
    )

    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    const pageNumbers = []
    for (
        let i = 1;
        i <= Math.ceil(statisticData?.data.length / itemsPerPage);
        i++
    ) {
        pageNumbers.push(i)
    }

    const renderPagination = () => (
        <Pagination className="justify-content-center">
            {pageNumbers?.map((number) => (
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => paginate(number)}
                >
                    {number}
                </Pagination.Item>
            ))}
        </Pagination>
    )

    return (
        <>
            {!statisticData?.data ? (
                <MarathonStatisticsSkeleton />
            ) : (
                <div className="page">
                    <h1 className="common__title m-0">
                        Статистика по марафонам
                    </h1>
                    <p className="mb-3">
                        Здесь отображаются все попытки решения марафона
                    </p>
                    <div className="card px-0">
                        <table className="table table-striped table-sm">
                            <thead className="thead-invert">
                                <tr className="text-center">
                                    <th>Дата</th>
                                    <th>Прав.</th>
                                    <th>Непр.</th>
                                    <th>Время решения</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems?.map((item, index) => (
                                    <tr key={index} className="text-center">
                                        <td>{item.date}</td>
                                        <td>{item.correctAnswers}</td>
                                        <td>{item.incorrectAnswers}</td>
                                        <td>{formatTime(item.time)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {renderPagination()}
                    </div>
                </div>
            )}
            <MarathonEndModal
                body={<MarathonEndModalBody />}
                show={showMarathonEndModal}
                closeButton={true}
                bodyClassName="m-0"
                handleClose={() => setShowMarathonEndModal(false)}
                centered={true}
            />
        </>
    )
}
export default MarathonStatistics
