import axios from 'axios'
import { useAuthTokenStore } from '@/store/AuthTokenStore'

const api = axios.create({
    baseURL: 'https://lider.dev.ceratex.ru/api',
})

api.interceptors.request.use(
    (config) => {
        const token = useAuthTokenStore.getState().token
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => Promise.reject(error),
)

api.interceptors.response.use(
    (response) => {
        if (response.headers && response.headers['authorization']) {
            const token = response.headers['authorization'].split('Bearer ')[1]
            useAuthTokenStore.getState().setToken(token)
        }

        return response
    },
    (error) => {
        if (!error) {
            return Promise.reject(error)
        }

        if (error.response.headers && error.response.headers['authorization']) {
            const token =
                error.response.headers['authorization'].split('Bearer ')[1]
            useAuthTokenStore.getState().setToken(token)
        }

        if (error.response.data.message) {
            // toast(error.response.data.message)
            // console.clear()
        }

        switch (error.response.status) {
            case 401:
                useAuthTokenStore.getState().setToken(null)
                break
            case 500:
                useAuthTokenStore.getState().setToken(null)
                break
            // case 404:
            //     useAuthTokenStore.getState().setToken(null)
            //     break
        }

        return Promise.reject(error)
    },
)

export default api
