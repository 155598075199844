import './Marathon.scss'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useEffect, useRef, useState } from 'react'
import { useMarathonStore } from '@/store/MarathonStore.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DefaultModal from '@/components/Modals/DefaultModal/DefaultModal.tsx'
import {
    automaticTransition,
    getMarathonQuestions,
    handleConfirmAnswer,
    handleMarathonEnd,
    handleNextQuestion,
    handleSelectAnswer,
} from '@/Pages/Tickets/Marathon/MarathonHelpers.tsx'
import {
    CircleDotChecked,
    CircleDotPrimary,
} from '@/components/Forms/TicketsInputIcons.tsx'
import { LoadingSpinner } from '@/components/Forms/Loading.tsx'
import { useNavigate } from 'react-router-dom'
import { customToast } from '@/lib/CustomToast.ts'
import { SettingsModalBody } from '@/Pages/Tickets/Marathon/SettingsModalBody.tsx'
import { ScrollContainer } from 'react-indiana-drag-scroll'
import { DefaultTimer } from '@/lib/DefaultTimer.tsx'
import { useQuery } from '@tanstack/react-query'
import MarathonTicketSkeleton from '@/Pages/Tickets/Marathon/MarathonTicketSkeleton.tsx'
import { useTimerStore } from '@/store/DefaultTimerStore.ts'
import { useUserQuery } from '@/lib/useUserQuery.ts'
import api from '@/api/api.ts'

const Marathon = () => {
    const {
        automaticTransitionMode,
        showMarathonSettings,
        currentTicketIndex,
        isAnswerConfirmed,
        answeredIndexes,
        isAnswerSelected,
        doubleClickMode,
        selectedAnswers,
        savedQuestions,
        currentTicket,
        tickets,
        setShowMarathonSettings,
        setQuestionsAndAnswers,
        setCurrentTicketIndex,
        setIsAnswerConfirmed,
        setIsAnswerSelected,
        setCurrentTicket,
    } = useMarathonStore()

    const [buttonLoading, setButtonLoading] = useState(false)
    const [isMarathonEnding, setIsMarathonEnding] = useState(false)
    const [isTimePaused, setIsTimePaused] = useState(false)
    //!todo clickCount == 2  можно заменить isAnswerSelected, это стейт убрать
    const [clickCount, setClickCount] = useState(1)
    const [lastAnswer, setLastAnswer] = useState('')

    const selectedAnswer = selectedAnswers[currentTicketIndex]
    const trueAnswer = currentTicket ? `ans_${currentTicket?.right}` : null
    const answersCount = answeredIndexes?.length
    const showComment = isAnswerConfirmed

    const savedAnswer = savedQuestions
        ? `ans_${savedQuestions[currentTicketIndex]?.userNumAnswer}`
        : null

    const answerKeys = currentTicket
        ? Object.keys(currentTicket).filter(
              (key) => key.startsWith('ans_') && currentTicket[key],
          )
        : null

    const navigate = useNavigate()

    const user = useUserQuery()
    const schoolInfo = useQuery({
        queryKey: ['school', 'info', user],
        queryFn: async () => await api.get('info'),
        staleTime: Infinity,
        enabled: !!user,
    })

    useQuery({
        queryKey: ['marathon', 'data'],
        queryFn: () => getMarathonQuestions(),
        enabled: !!schoolInfo.data,
    })

    const handleDoubleClickMode = async (answer: string) => {
        handleSelectAnswer(answer)
        setClickCount((prevCount) => prevCount + 1)
        setLastAnswer(answer)
        if (answer !== lastAnswer) {
            setClickCount(1)
        }
        if (clickCount === 2) {
            await handleConfirmAnswer({
                answersCount,
                navigate,
                setIsMarathonEnding,
            })
        }
    }

    const handleBothModes = async (answer: string) => {
        await handleDoubleClickMode(answer)
        automaticTransition(answer)
    }

    // !todo Слишком много эффектов
    // !todo Этот эффект можно обрабатывать при нажатии на ответ handleClick
    useEffect(() => {
        clickCount === 2 &&
            handleConfirmAnswer({
                answersCount,
                navigate,
                setIsMarathonEnding,
            })
    }, [clickCount])

    // !todo этот эффект при подтверждении ответа handleConfirmAnswer
    useEffect(() => {
        automaticTransitionMode &&
            isAnswerConfirmed &&
            selectedAnswer === trueAnswer &&
            handleNextQuestion()
    }, [isAnswerConfirmed])

    // !todo Этот эффект можно внутри getMarathonQuestions
    useEffect(() => {
        if (savedQuestions?.length && tickets) {
            const index = savedQuestions?.findIndex(
                (question) =>
                    question?.userNumAnswer === '' &&
                    question?.userBoolAnswer === '',
            )
            setCurrentTicketIndex(index)
            setCurrentTicket(tickets[index])
        }
    }, [savedQuestions])

    useEffect(() => {
        tickets &&
            setCurrentTicket(
                tickets[currentTicketIndex],
            ) /*!todo+ Неправильный тип?*/
        setIsAnswerSelected(false)
        setIsAnswerConfirmed(false)
        scrollToActiveButton(currentTicketIndex)
        setClickCount(0)
    }, [currentTicketIndex, tickets])

    // !todo при подтверждении ответа
    useEffect(() => {
        if (answersCount === 800) {
            /*!todo+ Неправильный тип?*/
            handleMarathonEnd(navigate, setIsMarathonEnding)
        }
    }, [answersCount])

    // !todo Этот эффект можно в handleSelectAnswer или в handleConfirmAnswer
    useEffect(() => {
        setQuestionsAndAnswers({
            /*!todo Поле названо странно, будто массив, лучше currentTicketAnswer */
            id: currentTicket?.id,
            numQuestion: currentTicket?.qn,
            numTicket: currentTicket?.bn,
            userBoolAnswer:
                selectedAnswers[currentTicketIndex] ===
                `ans_${currentTicket?.right}`
                    ? '1'
                    : '0',
            userNumAnswer: selectedAnswers[currentTicketIndex]?.substring(4),
        })
    }, [selectedAnswer])

    const buttonRefs = useRef([])
    const scrollToActiveButton = (index: number) => {
        if (buttonRefs.current[index]) {
            buttonRefs.current[index].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            })
        }
    }

    const handleClick = (answer: string) => {
        if (doubleClickMode && automaticTransitionMode) {
            handleBothModes(answer)
        } else if (doubleClickMode) {
            handleDoubleClickMode(answer)
        } else if (automaticTransitionMode) {
            automaticTransition(answer)
        }
    }

    const togglePause = useTimerStore((state) => state.setIsPaused)

    const handleTogglePause = () => {
        togglePause(!isTimePaused)
        setIsTimePaused(!isTimePaused)
    }
    return (
        <div className="page">
            {tickets ? (
                <div className="marathon__page-card card">
                    <div className="marathon__page-card-title">
                        <ScrollContainer className="marathon__page-card-title-nav btn-toolbar">
                            {tickets.map((tab, index) => (
                                <Button
                                    key={index}
                                    ref={(el) =>
                                        (buttonRefs.current[index] = el)
                                    }
                                    className="marathon__page-card-title-nav-button"
                                    variant={
                                        currentTicketIndex === index
                                            ? 'danger'
                                            : answeredIndexes.includes(index) ||
                                                (savedQuestions?.length &&
                                                    savedQuestions[index]
                                                        ?.userBoolAnswer !== '')
                                              ? 'warning'
                                              : 'outline-warning'
                                    }
                                    type="button"
                                    onClick={() => setCurrentTicketIndex(index)}
                                >
                                    {index + 1}
                                </Button>
                            ))}
                        </ScrollContainer>
                        <div className="marathon__page-card-title-header-group">
                            <h2 className="marathon__page-card-title-header-group-title">
                                {`Билет ${currentTicket?.bn} - Вопрос ${currentTicket?.qn}`}
                            </h2>
                            <div className="marathon__page-card-title-header-group-buttons">
                                <Button
                                    variant="outline-danger"
                                    onClick={() =>
                                        setShowMarathonSettings(true)
                                    }
                                >
                                    Настройки
                                </Button>
                                <Button
                                    variant="outline-danger"
                                    onClick={() => {
                                        confirm(
                                            'Вы уверены, что хотите завершить марафон?',
                                        ) &&
                                            handleMarathonEnd(
                                                navigate,
                                                setIsMarathonEnding,
                                            )
                                    }}
                                >
                                    {!isMarathonEnding && 'Завершить экзамен'}
                                </Button>
                            </div>
                            <div className="marathon__page-card-title-header-group-timer">
                                <FontAwesomeIcon
                                    icon={[
                                        'fal',
                                        isTimePaused
                                            ? 'circle-play'
                                            : 'circle-pause',
                                    ]}
                                    size="lg"
                                    color="#e41b21"
                                    onClick={() => handleTogglePause()}
                                    className="cursor-pointer"
                                />
                                <DefaultTimer />
                            </div>
                        </div>
                    </div>
                    {!isTimePaused ? (
                        <div className="question__card-content">
                            <div className="question__card-content-image">
                                <img
                                    className="img-fluid"
                                    src={currentTicket?.img}
                                    alt="Question-image"
                                />
                            </div>
                            <div className="question__card-content-question">
                                <h3 className="question__card-content-question-text">
                                    {currentTicket?.qst}
                                </h3>
                                <div className="question__card-content-question-answers">
                                    {answerKeys?.map((answer, idx) => (
                                        <div
                                            key={idx}
                                            className="question__card-content-question-answers-answer"
                                        >
                                            <input
                                                type="radio"
                                                className="btn-check"
                                                name={`question_${currentTicket?.id}`}
                                                id={`question_${currentTicketIndex}`}
                                                autoComplete="off"
                                                checked={
                                                    selectedAnswer === answer ||
                                                    savedAnswer === answer
                                                }
                                                readOnly={true}
                                                disabled={
                                                    isAnswerConfirmed ||
                                                    answeredIndexes.includes(
                                                        currentTicketIndex,
                                                    ) ||
                                                    savedQuestions[
                                                        currentTicketIndex
                                                    ]?.userNumAnswer
                                                }
                                            />
                                            <label
                                                className="btn btn-check-tickets p-2 w-100"
                                                htmlFor={`question_${currentTicketIndex}`}
                                                onClick={() =>
                                                    handleClick(answer)
                                                }
                                            >
                                                <div className="btn-check-tickets-items">
                                                    {selectedAnswer ===
                                                        answer ||
                                                    savedAnswer === answer ? (
                                                        <CircleDotChecked />
                                                    ) : (
                                                        <CircleDotPrimary />
                                                    )}
                                                    <p>
                                                        {currentTicket[answer]}
                                                    </p>
                                                </div>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                {savedQuestions &&
                                !savedQuestions[currentTicketIndex]
                                    ?.userNumAnswer &&
                                !answeredIndexes.includes(
                                    currentTicketIndex,
                                ) ? (
                                    <Button
                                        variant={
                                            isAnswerConfirmed
                                                ? 'outline-success'
                                                : 'outline-warning'
                                        }
                                        className="question__card-content-submit-btn"
                                        style={{
                                            pointerEvents: buttonLoading
                                                ? 'none'
                                                : 'auto',
                                        }}
                                        onClick={() => {
                                            !isAnswerSelected &&
                                                customToast({
                                                    message:
                                                        'Сначала выберите вариант ответа',
                                                    type: 'error',
                                                })
                                            isAnswerSelected &&
                                                !isAnswerConfirmed &&
                                                handleConfirmAnswer({
                                                    answersCount,
                                                    navigate,
                                                    setIsMarathonEnding,
                                                })
                                            isAnswerConfirmed &&
                                                handleNextQuestion()
                                        }}
                                        disabled={answeredIndexes.includes(
                                            currentTicketIndex,
                                        )}
                                    >
                                        {!isAnswerConfirmed &&
                                            !buttonLoading &&
                                            'Подтвердить ответ'}
                                        {isAnswerConfirmed &&
                                            !buttonLoading &&
                                            'Следующий вопрос'}
                                    </Button>
                                ) : (
                                    <div className="question__card-content-comment ">
                                        <p className="alert bg-success text-white p-1">
                                            Вы уже ответили на этот вопрос
                                        </p>
                                    </div>
                                )}
                                {(showComment ||
                                    savedQuestions[currentTicketIndex]
                                        ?.userNumAnswer ||
                                    answeredIndexes.includes(
                                        currentTicketIndex,
                                    )) && (
                                    <div className="question__card-content-comment">
                                        <p
                                            className="question__card-content-comment-text"
                                            style={{
                                                borderLeft:
                                                    selectedAnswer !==
                                                    trueAnswer
                                                        ? '5px solid red'
                                                        : '5px solid green',
                                            }}
                                        >
                                            {currentTicket?.comment}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <p className="d-flex flex-column justify-content-center align-items-center fw-bold text-nowrap fs-lg-18 fs-md-16 fs-sm-14 my-5">
                            Марафон приостановлен
                            <span className="d-flex flex-nowrap gap-1 flex-column justify-content-center align-items-center mt-4">
                                Продолжить
                                <FontAwesomeIcon
                                    icon={[
                                        'fal',
                                        isTimePaused
                                            ? 'circle-play'
                                            : 'circle-pause',
                                    ]}
                                    size="2xl"
                                    color="#e41b21"
                                    onClick={() => handleTogglePause()}
                                    className="cursor-pointer"
                                />
                            </span>
                        </p>
                    )}
                    <DefaultModal
                        show={showMarathonSettings}
                        body={<SettingsModalBody />}
                        closeButton={true}
                        handleClose={() => setShowMarathonSettings(false)}
                        title={<h1 className="common__title">Настройки</h1>}
                    />
                </div>
            ) : (
                <MarathonTicketSkeleton />
            )}
        </div>
    )
}

export default Marathon
