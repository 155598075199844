import { useState, useEffect } from 'react'
import SideBar from '@/components/Sidebar/Sidebar.tsx'
import BottomNav from '@/components/Sidebar/BottomNav.tsx'
import './MainLayout.scss'
import { useSpring, animated } from 'react-spring'
import { useSystemSettingsStore } from '@/store/SystemSettingsStore.ts'
import Header from '@/Pages/MainPage/Header/Header.tsx'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import api from '@/api/api.ts'
import { useUserQuery } from '@/lib/useUserQuery.ts'

const MainLayout = (props) => {
    const queryClient = useQueryClient()
    const { isWindowWide, setIsWindowWide } = useSystemSettingsStore()
    const [isSidebarVisible, setSidebarVisible] = useState(isWindowWide)
    //!todo+ Перенеси условие в стор `isWindowWide: window.innerWidth > 1200`, здесь поставь `isWindowWide`
    const handleWindowResize = () => {
        setIsWindowWide(window.innerWidth > 1200)
        setSidebarVisible(isWindowWide)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)
        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [isWindowWide])

    const animation = useSpring({
        transform: isSidebarVisible ? `translateX(0%)` : `translateX(-100%)`,
    })

    const user = useUserQuery()
    const schoolInfo = useQuery({
        queryKey: ['school', 'info', user],
        queryFn: () => api.get('info'),
        staleTime: Infinity,
        enabled: !!user,
    })

    useEffect(() => {
        if (schoolInfo) {
            queryClient.setQueryData(['school', 'info'], schoolInfo?.data?.data)
        }
    }, [schoolInfo])

    return (
        <>
            <Header />
            {/*!todo+ Тело условия дублируется*/}
            <animated.div style={animation} className="sidebar-container">
                <SideBar
                    setSidebarVisible={setSidebarVisible}
                    isSidebarVisible={isSidebarVisible}
                />
            </animated.div>
            {!isWindowWide && isSidebarVisible && (
                <div
                    className="sidebar-overlay"
                    onClick={() => setSidebarVisible(false)}
                />
            )}
            {!isWindowWide && (
                <>
                    <BottomNav
                        setVisible={setSidebarVisible}
                        isSidebarVisible={isSidebarVisible}
                    />
                </>
            )}

            <div className="main__layout layout">{props.children}</div>
        </>
    )
}

export default MainLayout
