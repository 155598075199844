import { create } from 'zustand'
import { INotifications, ISchoolExamResults } from '@/types/IUser.ts'
import { ICategory } from '@/types/ICategory.ts'

interface AuthUserState {
    phone: string | null
    setPhone: (arg: string) => void
    password: string | null
    setPassword: (arg: string) => void
    notifications: INotifications | null
    setNotifications: (arg: INotifications) => void
    category: ICategory | null
    setCategory: (arg: ICategory) => void
    schoolExamResults: ISchoolExamResults[] | null
    setSchoolExamResults: (arg: ISchoolExamResults[]) => void
    resetUserData: () => void
}

export const useAuthUserStore = create<AuthUserState>((set) => ({
    phone: '',
    setPhone: (phone) => set({ phone }),
    password: '',
    setPassword: (password) => set({ password }),
    notifications: null,
    setNotifications: (notifications) => set({ notifications }),
    category: null,
    setCategory: (category) => set({ category }),
    schoolExamResults: null,
    setSchoolExamResults: (schoolExamResults) => set({ schoolExamResults }),
    resetUserData: () =>
        set({
            phone: '',
            password: '',
            notifications: null,
            category: null,
        }),
}))
