import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSystemSettingsStore } from '@/store/SystemSettingsStore.ts'
import PositivesList from '@/Pages/PracticePage/PositivesList.tsx'
import NegativesList from '@/Pages/PracticePage/NegativesList.tsx'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { Dispatch, SetStateAction, useState } from 'react'
import { IDrives, IReviewItems } from '@/types/IPracticeData.ts'
import { useMutation } from '@tanstack/react-query'
import api from '@/api/api.ts'
import { customToast } from '@/lib/CustomToast.ts'
import { useForm } from 'react-hook-form'
import { LoadingSpinner } from '@/components/Forms/Loading.tsx'
import DefaultModal from '@/components/Modals/DefaultModal/DefaultModal.tsx'

interface Props {
    driveReviewItems: IReviewItems
    currentDrive: IDrives
    setCurrentDrive: Dispatch<SetStateAction<IDrives | null>>
}

const DriveReviewModal = ({
    driveReviewItems,
    currentDrive,
    setCurrentDrive,
}: Props) => {
    const { handleSubmit, watch, register } = useForm()
    const [selectedRating, setSelectedRating] = useState(5)
    const [selectedPositives, setSelectedPositives] = useState<string[]>([])
    const [selectedNegatives, setSelectedNegatives] = useState<string[]>([])

    const comment = watch('comment')

    const postDriveReview = useMutation({
        mutationFn() {
            return api.post(`/instructor/drive/${currentDrive.id}/review`, {
                vote: selectedRating,
                ...(comment.length && { comment: comment }),
                ...(selectedNegatives.length &&
                    selectedRating < 5 && {
                        negatives: selectedNegatives,
                    }),
                ...(selectedPositives.length && {
                    positives: selectedPositives,
                }),
            })
        },
        onSuccess() {
            customToast({ message: 'Спасибо за Ваш отзыв', type: 'success' })
        },
        onError() {
            customToast({ message: 'Что-то пошло не так...', type: 'error' })
        },
    })

    function submitReview() {
        postDriveReview.mutate()
    }

    return (
        <DefaultModal
            show={!!currentDrive}
            handleClose={() => setCurrentDrive(null)}
            closeButton={true}
            body={
                <>
                    <div className="practice__review-modal-instructor">
                        {!currentDrive?.instructor.image ? (
                            <FontAwesomeIcon
                                icon={['fas', 'user-circle']}
                                color="gray"
                                className="practice__review-modal-instructor-avatar-svg"
                            />
                        ) : (
                            <img
                                src={currentDrive.instructor.image.small}
                                alt="instructor-avatar"
                                className="practice__review-modal-instructor-avatar-img"
                            />
                        )}
                        <div className="practice__review-modal-instructor-info">
                            <h3>{currentDrive?.instructor.name}</h3>
                            <p>{currentDrive?.type}</p>
                            {currentDrive?.date && (
                                <p>
                                    {format(currentDrive.date, 'Pp', {
                                        locale: ru,
                                    })}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="practice__review-modal-rating">
                        <h3 className="practice__review-modal-rating-title">
                            Как вам занятие?
                        </h3>
                        <div className="practice__review-modal-rating-stars">
                            {Array.from({ length: 5 }, (_, index) => (
                                <FontAwesomeIcon
                                    key={index}
                                    icon={['fas', 'star']}
                                    color={
                                        selectedRating >= index + 1
                                            ? '#fed321'
                                            : '#cccccc'
                                    }
                                    size="lg"
                                    onClick={() => setSelectedRating(index + 1)}
                                    className="cursor-pointer"
                                />
                            ))}
                        </div>
                    </div>
                    {driveReviewItems && (
                        <>
                            <PositivesList
                                positiveItems={
                                    driveReviewItems.review_items.positive
                                }
                                selectedPositives={selectedPositives}
                                setSelectedPositives={setSelectedPositives}
                            />
                            <NegativesList
                                negativeItems={
                                    driveReviewItems.review_items.negative
                                }
                                selectedRating={selectedRating}
                                setSelectedNegatives={setSelectedNegatives}
                                selectedNegatives={selectedNegatives}
                            />
                        </>
                    )}
                    <form
                        onSubmit={handleSubmit(submitReview)}
                        className="practice__review-modal-form"
                    >
                        <textarea
                            {...register('comment')}
                            rows={3}
                            className="practice__review-modal-form-textarea"
                            placeholder="Расскажите более подробно..."
                        />
                        <button
                            type="submit"
                            className="practice__review-modal-form-submit-btn btn btn-outline-warning"
                        >
                            {postDriveReview.isPending ? (
                                <LoadingSpinner isLoading />
                            ) : (
                                'Отправить'
                            )}
                        </button>
                    </form>
                    <p className="practice__review-modal-footer-comment">
                        Инструктор не будет видеть вашей оценки, ее увидит
                        только руководитель отдела качества
                    </p>
                </>
            }
        />
    )
}

export default DriveReviewModal
