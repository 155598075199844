import { useVideoListStore } from '@/store/VideoListStore.ts'
import { useNavigate } from 'react-router-dom'
import { useTicketsTypeStore } from '@/store/TicketsTypeStore.ts'
import { Collapse } from 'react-bootstrap'
import { useEffect, useRef, useState } from 'react'

function VideoPlayerFooter() {
    const { currentVideo, setShowVideoModal } = useVideoListStore()
    const { setActiveTicketsType } = useTicketsTypeStore()
    const [showRules, setShowRules] = useState(false)

    const navigate = useNavigate()
    const ruleRef = useRef<HTMLDivElement>(null)

    const getABMTickets = async (id: number) => {
        setShowVideoModal(false)
        setActiveTicketsType('ABM')
        navigate(`/tickets/themes/${id}`)
    }

    const getCDTickets = async (id: number) => {
        setShowVideoModal(false)
        setActiveTicketsType('CD')
        navigate(`/tickets/themes/${id}`)
    }

    useEffect(() => {
        setTimeout(() => {
            ruleRef.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'end',
            })
        }, 300)
    }, [showRules])

    return (
        <>
            <div className="video__player-title">
                <h4>{currentVideo?.name}</h4>
                <p className="text-body-tertiary">{currentVideo?.views}</p>
            </div>
            <>
                {currentVideo?.cd && currentVideo?.abm && (
                    <div className="video__player-video-done">
                        <p className="video__player-video-done-title">
                            Посмотрели видеоурок? Закрепите знания билетами ПДД
                            по вашей категории:
                        </p>
                        <div className="video__player-video-done-buttons">
                            <button
                                className="video__player-video-done-buttons-btn btn btn-warning"
                                onClick={() => getABMTickets(currentVideo.abm)}
                            >
                                Билеты по теме A/B/M
                            </button>
                            <button
                                className="video__player-video-done-buttons-btn btn btn-warning"
                                onClick={() => getCDTickets(currentVideo.cd)}
                            >
                                Билеты по теме C/D
                            </button>
                        </div>
                    </div>
                )}
            </>
            <>
                <p
                    onClick={() => setShowRules(!showRules)}
                    className="video__player-video-done-rules"
                >
                    Правила просмотра видеоуроков
                </p>
                <Collapse in={showRules}>
                    <div className="container-fluid" ref={ruleRef}>
                        <div
                            className="alert alert-warning alert-dismissible fade show"
                            role="alert"
                        >
                            <ul>
                                <li>
                                    Урок считается пройденным, если вы
                                    посмотрели его от начала до конца
                                </li>
                                <li>
                                    После того как урок засчитался просмотренным
                                    и выделен зеленым цветом - перематывать
                                    видео можно без ограничений. На прогресс
                                    просмотра это не повлияет
                                </li>
                                <li>
                                    Если вы остановились на определенном моменте
                                    и решили досмотреть потом, при следующем
                                    запуске урока, он начнется с того же места,
                                    где вы остановились
                                </li>
                                <li>
                                    Просмотр информационных видео не влияет на
                                    общий прогресс изучения ПДД, но может быть
                                    очень полезен для общего развития или
                                    успешной сдачи экзаменов
                                </li>
                            </ul>
                        </div>
                    </div>
                </Collapse>
            </>
        </>
    )
}

export default VideoPlayerFooter
