import { Col, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const TicketCardSkeleton = () => {
    return (
        <>
            <div className="px-3 d-flex align-items-center justify-content-between">
                <div className="col-2">
                    <Skeleton />
                </div>
                <div className="col-1">
                    <Skeleton />
                </div>
            </div>
            <Row className="mt-3">
                <div className="text-center mb-4 px-3">
                    <Skeleton height={24} width="30%" />
                </div>
                <Col lg={12}>
                    <Col lg={6} className="px-3">
                        <Skeleton height="30vh" />
                        <Skeleton className="mt-3" />
                    </Col>
                </Col>
                <Col lg={6} className="p-3 mt-4">
                    <div>
                        <Skeleton count={3} height={28} className="mb-1" />
                    </div>
                    <Skeleton height={30} className="mt-3" />
                </Col>
            </Row>
        </>
    )
}

export default TicketCardSkeleton
