import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Statistic } from '@/types/IStatistics.ts'
import { useState } from 'react'

interface Props {
    statistic: Statistic[]
}

function ExamTicketsStatistics({ statistic }: Props) {
    const [examTicketsStatistics, setExamTicketsStatistics] =
        useState(statistic)

    return (
        <>
            <h4 className="common__title">
                Статистика по экзаменам
                <p className="fw-normal">
                    Здесь отображаются все попытки решения экзамена
                </p>
            </h4>
            <div className="tickets__statistic-card card">
                <table className="table table-sm table-striped table-bordered mb-0">
                    <thead className="thead-invert">
                        <tr className="text-center">
                            <th>Дата</th>
                            <th>Верно</th>
                            <th>Неверно</th>
                            <th>Комментарий</th>
                            <th>Результат</th>
                        </tr>
                    </thead>
                    <tbody>
                        {examTicketsStatistics?.map(
                            (
                                statistic,
                                index /*!todo+ Если тип выше правильно сделаешь, то здесь указывать типы не надо*/,
                            ) => (
                                <tr
                                    key={index}
                                    className="text-center fs-lg-14 fs-md-12 fs-sm-11"
                                >
                                    <td className="text-break">
                                        {statistic.date}{' '}
                                        {/*!todo+ statistic здесь всегда есть, ? не нужны во всём блоке*/}
                                    </td>
                                    <td className="text-break" valign="middle">
                                        {statistic.correct}
                                    </td>
                                    <td className="text-break" valign="middle">
                                        {statistic.incorrect}
                                    </td>
                                    <td className="text-break" valign="middle">
                                        {statistic.errors}
                                    </td>
                                    <td className="text-break" valign="middle">
                                        {statistic.status ? (
                                            <FontAwesomeIcon
                                                icon={['fal', 'circle-check']}
                                                color="green"
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={['fal', 'circle-xmark']}
                                                color="red"
                                            />
                                        )}
                                    </td>
                                </tr>
                            ),
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default ExamTicketsStatistics
