import { useQuery } from '@tanstack/react-query'
import api from '@/api/api.ts'
import { useTicketsTypeStore } from '@/store/TicketsTypeStore.ts'
import { useParams } from 'react-router-dom'
import TicketCardSkeleton from '@/Pages/Tickets/TicketCardSkeleton.tsx'
import Questions from '@/Pages/Tickets/Questions.tsx'
import { useUserQuery } from '@/lib/useUserQuery.ts'

const CurrentTicket = () => {
    const { activeTicketsType } = useTicketsTypeStore()
    const { id } = useParams()

    const ticketType = activeTicketsType === 'CD' ? 'CD' : 'ABM'

    const userData = useUserQuery()
    const schoolInfo = useQuery({
        queryKey: ['school', 'info'],
        staleTime: Infinity,
    })

    const currentTicket = useQuery({
        queryKey: ['ticket', ticketType, id],
        queryFn: () => api.get(`/tickets/ticket/${ticketType}/${id}`),
        enabled: !!userData && !!schoolInfo.data && !!id,
    })

    const ticketData = currentTicket?.data?.data

    if (!ticketData) {
        return (
            <div className="page">
                <div className="card">
                    <TicketCardSkeleton />
                </div>
            </div>
        )
    } else {
        return (
            <div className="page">
                <div className="card">
                    <Questions ticketsQuestions={ticketData} timer={true} />
                </div>
            </div>
        )
    }
}

export default CurrentTicket
