import { useEffect } from 'react'
import { differenceInSeconds } from 'date-fns'
import { useProfileCountdownStore } from '@/Pages/MainPage/Header/ProfileEmailResendCountdownStore.ts'

export const ProfileCountdownTimer = ({ onTimerEnd }) => {
    const { initialDate, seconds, setSeconds } = useProfileCountdownStore()

    useEffect(() => {
        if (initialDate) {
            const timer = setInterval(() => {
                const result = differenceInSeconds(new Date(), initialDate)
                if (result > 60) {
                    clearInterval(timer)
                    onTimerEnd()
                } else {
                    setSeconds(60 - result)
                }
            }, 1000)
            return () => {
                clearInterval(timer)
                setSeconds(60)
            }
        }
    }, [initialDate])

    return (
        <span className="m-0 fw-semibold">
            {`00:${seconds < 10 ? '0' : ''}${seconds}`}
        </span>
    )
}
