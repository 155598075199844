import { useState, useEffect } from 'react'

export const CountDownTimer = ({
    initialMinutes,
    onTimerEnd,
    initialSeconds = null,
    className,
}) => {
    const [minutes, setMinutes] = useState(initialMinutes)
    const [seconds, setSeconds] = useState(initialSeconds || 0)

    useEffect(() => {
        const timer = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1)
            } else {
                if (minutes === 0) {
                    clearInterval(timer)
                    onTimerEnd()
                } else {
                    setMinutes(minutes - 1)
                    setSeconds(59)
                }
            }
        }, 1000)

        return () => clearInterval(timer)
    }, [minutes, seconds, onTimerEnd])

    return (
        <p className={className}>{`${minutes}:${
            seconds < 10 ? '0' : ''
        }${seconds}`}</p>
    )
}
