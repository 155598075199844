import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

const LightBox = ({ showLightbox, setShowLightbox, slides }) => {
    const [preload, setPreload] = useState(1)
    const [index, setIndex] = useState(0)

    return (
        <Lightbox
            open={showLightbox}
            close={() => setShowLightbox(false)}
            slides={slides}
            carousel={{ preload }}
            animation={{
                fade: 500,
                navigation: 500,
            }}
            index={index}
            on={{
                view: ({ index: currentIndex }) => setIndex(currentIndex),
            }}
            render={{
                controls: () => (
                    <div className="slide_nav">
                        {slides.map((slide, slideIndex) => (
                            <FontAwesomeIcon
                                key={slideIndex}
                                className="bottom_nav_slider"
                                icon={[
                                    index === slideIndex ? 'fas' : 'fal',
                                    'circle',
                                ]}
                                size="lg"
                                color="white"
                                onClick={() => setIndex(slideIndex)}
                            />
                        ))}
                    </div>
                ),
                iconLoading: () => (
                    <FontAwesomeIcon
                        icon={['fal', 'circle']}
                        size="5x"
                        color="white"
                        beat
                    />
                ),
            }}
        />
    )
}

export default LightBox
