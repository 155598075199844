import './Statistics.scss'
import TicketsStatistics from '@/Pages/Tickets/Statistics/TicketsStatistics.tsx'
import api from '@/api/api.ts'
import { useTicketsTypeStore } from '@/store/TicketsTypeStore.ts'
import ThemeTicketsStatistics from '@/Pages/Tickets/Statistics/ThemeTicketsStatistic.tsx'
import ExamTicketsStatistics from '@/Pages/Tickets/Statistics/ExamTicketsStatistic.tsx'
import StatisticsHeader from '@/Pages/Tickets/Statistics/StatisticsHeader.tsx'
import StatisticsSkeleton from '@/Pages/Tickets/Statistics/StatisticsSkeleton.tsx'
import MarathonStatistic from '@/Pages/Tickets/Statistics/MarathonStatistic.tsx'
import { useQuery } from '@tanstack/react-query'
import { useUserQuery } from '@/lib/useUserQuery'

function StatisticsLayout() {
    const activeTicketsType = useTicketsTypeStore(
        (state) => state.activeTicketsType,
    )
    const user = useUserQuery()
    const schoolInfo = useQuery({
        queryKey: ['school', 'info', user],
        queryFn: async () => await api.get('info'),
        staleTime: Infinity,
        enabled: !!user,
    })

    const { data: ticketsStatistic, isFetching: isTicketsStatisticsLoading } =
        useQuery({
            queryKey: ['tickets', 'statistics', activeTicketsType],
            queryFn: async () =>
                await api.get(`tickets/statistics/${activeTicketsType}`),
            enabled: !!user && !!schoolInfo.data,
            staleTime: 0,
        })

    const { data: marathonStatistic, isFetching: isMarathonStatisticsLoading } =
        useQuery({
            queryKey: ['tickets', 'statistics', 'marathon', activeTicketsType],
            queryFn: async () =>
                await api.get(
                    `tickets/marathon/${activeTicketsType}/statistic`,
                ),
            enabled: !!ticketsStatistic,
            staleTime: 0,
        })

    return (
        <>
            <StatisticsHeader />
            {isTicketsStatisticsLoading || isMarathonStatisticsLoading ? (
                <StatisticsSkeleton />
            ) : (
                <div className="page">
                    <TicketsStatistics
                        statistic={ticketsStatistic?.data.tickets}
                    />
                    <ThemeTicketsStatistics
                        statistic={ticketsStatistic?.data.themes}
                    />
                    <ExamTicketsStatistics
                        statistic={ticketsStatistic?.data.exams.statistics}
                    />
                    <MarathonStatistic statistic={marathonStatistic?.data} />
                </div>
            )}
        </>
    )
}
export default StatisticsLayout
