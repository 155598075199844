import { create } from 'zustand'
import { IVideoList } from '@/types/IPlayer.ts'

interface VideoListStore {
    videoList: IVideoList[] | null
    setVideoList: (arg: IVideoList[]) => void
    currentListIndex: number
    setCurrentListIndex: (arg: number) => void
    currentVideo: IVideoList | null
    setCurrentVideo: (arg: IVideoList) => void
    showVideoModal: boolean
    setShowVideoModal: (arg: boolean) => void
    reset: () => void
}

export const useVideoListStore = create<VideoListStore>()((set) => ({
    videoList: null,
    setVideoList: (videoList) => set({ videoList }),
    currentListIndex: -1,
    setCurrentListIndex: (currentListIndex: number) =>
        set({ currentListIndex }),
    currentVideo: null,
    setCurrentVideo: (currentVideo) => set({ currentVideo }),
    showVideoModal: false,
    setShowVideoModal: (showVideoModal: boolean) => set({ showVideoModal }),
    reset: () => {
        set({
            videoList: null,
        })
    },
}))
