import { useTicketsQuestionsStore } from '@/store/TicketsQuestionsStore.ts'
import { CountDownTimer } from '@/lib/CountDownTimer.tsx'
import { Fragment, useEffect, useRef, useState } from 'react'
import ExamQuestionCard from '@/Pages/Tickets/Exam/ExamQuestionCard.tsx'
import { useExamStore } from '@/store/ExamStore.ts'
import { useLocation } from 'react-router-dom'
import { IQuestion } from '@/types/ITickets.ts'

function ExamQuestionsPreview() {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const { ExamTicketsQuestions, setTicketsQuestions, TicketsQuestions } =
        useTicketsQuestionsStore()
    const { answeredIndexes, setTimerEndModalShow } = useExamStore()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'instant' })
    }, [])

    const location = useLocation()
    useEffect(() => {
        if (location.pathname !== '/tickets/exam') {
            setTicketsQuestions(null)
        }
    }, [location.pathname])

    const questionRefs = useRef([])
    const handleKeyboardInput = (e: KeyboardEvent) => {
        const code = e.key
        const indexChange = (value: number) => {
            const newIndex = currentQuestionIndex + value
            if (newIndex >= 0 && newIndex < ExamTicketsQuestions?.length) {
                setCurrentQuestionIndex(newIndex)

                if (code === 'ArrowUp' || code === 'ArrowDown') {
                    questionRefs.current[newIndex].scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            }
        }

        switch (code) {
            case 'ArrowRight':
                e.preventDefault()
                if (!TicketsQuestions) {
                    indexChange(1)
                }
                break
            case 'ArrowLeft':
                e.preventDefault()
                if (!TicketsQuestions) {
                    indexChange(-1)
                }
                break
            case 'ArrowUp':
                e.preventDefault()
                if (!TicketsQuestions) {
                    indexChange(-4)
                }
                break
            case 'ArrowDown':
                e.preventDefault()
                if (!TicketsQuestions) {
                    indexChange(4)
                }
                break
            case ' ':
                e.preventDefault()
                setTicketsQuestions(ExamTicketsQuestions[currentQuestionIndex])
                break
            default:
                break
        }
    }

    const selectQuestion = (question: IQuestion, index: number) => {
        setTicketsQuestions(question)
        setCurrentQuestionIndex(index)
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyboardInput)
        return () => {
            window.removeEventListener('keydown', handleKeyboardInput)
        }
    }, [currentQuestionIndex, TicketsQuestions])

    const handleTimerEnd = () => {
        setTimerEndModalShow(true)
    }

    return (
        <>
            <h2 className="exam__preview-header">
                Осталось&nbsp;
                <CountDownTimer
                    onTimerEnd={handleTimerEnd}
                    initialMinutes={20}
                    className="question__card-header-timer"
                />
            </h2>
            <div className="exam__preview-page page">
                {TicketsQuestions === null ? (
                    <div className="exam__preview-page-cards">
                        {ExamTicketsQuestions?.map(
                            (question: IQuestion, index: number) => (
                                <Fragment key={question.id}>
                                    <div
                                        ref={(ref) =>
                                            (questionRefs.current[index] = ref)
                                        }
                                        className="exam__preview-page-cards-card"
                                        style={{
                                            boxShadow:
                                                currentQuestionIndex === index
                                                    ? '0px 0px 0px 2px rgba(254,211,33,1)'
                                                    : '0 0 5px 0 rgba(0,0,0, .2)',
                                            transform:
                                                currentQuestionIndex === index
                                                    ? 'scale(1.04)'
                                                    : 'none',
                                            transition:
                                                'transform 0.4s ease-in-out',
                                            backgroundColor:
                                                answeredIndexes?.includes(
                                                    index.toString(),
                                                )
                                                    ? 'lightgray'
                                                    : 'none',
                                        }}
                                        onClick={() =>
                                            selectQuestion(question, index)
                                        }
                                    >
                                        <div className="exam__preview-page-cards-card-image">
                                            <img
                                                src={question.img}
                                                alt="question-image"
                                                style={{
                                                    opacity:
                                                        answeredIndexes?.includes(
                                                            index.toString(),
                                                        )
                                                            ? '0.5'
                                                            : '1',
                                                }}
                                            />
                                            <span className="exam__preview-page-cards-card-image-pill">
                                                {index + 1}
                                            </span>
                                        </div>
                                        <p className="exam__preview-page-cards-card-question">
                                            {question.qst}
                                        </p>
                                    </div>
                                </Fragment>
                            ),
                        )}
                    </div>
                ) : (
                    <ExamQuestionCard
                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                        currentQuestionIndex={currentQuestionIndex}
                    />
                )}
            </div>
        </>
    )
}
export default ExamQuestionsPreview
