import { toast } from 'react-toastify'

type ToastType = 'error' | 'success' | 'info' | 'warning' | 'default'

interface ICustomToastProps {
    message: string
    type: ToastType
}

export const customToast = ({ message, type }: ICustomToastProps) => {
    toast(message, {
        type: type,
        hideProgressBar: true,
        pauseOnFocusLoss: false,
        autoClose: 5000,
        position: 'bottom-right',
        draggable: true,
    })
}
