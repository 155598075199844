import { create } from 'zustand'

interface RestoreAccountStore {
    phone: string | null
    setPhone: (arg: string) => void
    password: string | null
    setPassword: (arg: string) => void
    repeatPassword: string
    setRepeatPassword: (arg: string) => void
    restoreCode: string
    setRestoreCode: (arg: string) => void
    restorePhoneChecked: boolean
    setRestorePhoneChecked: (arg: boolean) => void
    resetRestoreData: () => void
}

export const useRestoreAccountStore = create<RestoreAccountStore>()((set) => ({
    phone: '',
    setPhone: (phone) => set({ phone }),
    password: '',
    setPassword: (password) => set({ password }),
    repeatPassword: '',
    setRepeatPassword: (repeatPassword) => set({ repeatPassword }),
    restoreCode: '',
    setRestoreCode: (restoreCode) => set({ restoreCode }),
    restorePhoneChecked: false,
    setRestorePhoneChecked: (restorePhoneChecked) =>
        set({ restorePhoneChecked }),
    resetRestoreData: () => {
        set({
            phone: '',
            password: '',
            repeatPassword: '',
            restoreCode: '',
            restorePhoneChecked: false,
        })
    },
}))
