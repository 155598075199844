import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import {
    CircleDotChecked,
    CircleDotPrimary,
    SquareCheck,
} from '@/components/Forms/TicketsInputIcons.tsx'
import { customToast } from '@/lib/CustomToast.ts'
import { FormToastError } from '@/lib/FormToastError.ts'
import { useMutation } from '@tanstack/react-query'
import api from '@/api/api.ts'
import { useParams } from 'react-router-dom'
import { LoadingSpinner } from '@/components/Forms/Loading.tsx'

function ReviewForm() {
    const {
        handleSubmit,
        formState: { errors },
        reset,
        register,
    } = useForm()

    const [isAnonymous, setIsAnonymous] = useState(false)
    const [reviewText, setReviewText] = useState('')
    const [selectedRating, setSelectedRating] = useState(5)

    const { id } = useParams<{ id: string }>()

    const postReview = useMutation({
        mutationFn() {
            return api.post(
                `/instructor/${id}/reviews`,
                {
                    review: reviewText,
                    mark: selectedRating,
                    anon: isAnonymous,
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                },
            )
        },
        onSuccess() {
            customToast({ message: 'Отзыв отправлен', type: 'success' })
            resetForm()
        },
        onError() {
            customToast({
                message: 'Ошибка при отправке отзыва',
                type: 'error',
            })
        },
    })

    const submitForm = () => {
        postReview.mutate()
    }
    const resetForm = () => {
        setReviewText('')
        setSelectedRating(5)
        setIsAnonymous(false)
        reset()
    }

    return (
        <div className="instructor__profile-review-form">
            <h4 className="common__title">
                <FontAwesomeIcon icon={['fat', 'file-text']} className="me-1" />
                Оставьте отзыв об инструкторе
                <p className="fw-normal">
                    Постарайтесь дать объективную оценку инструктору, скорее
                    всего это кому-то поможет правильно выбрать инструктора
                </p>
            </h4>
            <div className="instructor__profile-review-form-card card">
                <form
                    onSubmit={handleSubmit(submitForm, FormToastError)}
                    className="instructor__profile-review-form-card-form"
                >
                    <div className="instructor__profile-review-form-card-form-anonimous-check">
                        <SquareCheck
                            id="is_anon_check"
                            checked={isAnonymous}
                            onClick={() => setIsAnonymous(!isAnonymous)}
                        />
                        <label htmlFor="is_anon_check">Анонимный отзыв</label>
                    </div>
                    <textarea
                        {...register('reviewText', {
                            required: {
                                value: true,
                                message:
                                    'Поле отзыва обязательно для заполнения',
                            },
                            onChange: (e) => setReviewText(e.target.value),
                        })}
                        placeholder="Введите отзыв..."
                        className="instructor__profile-review-form-card-form-textarea"
                    />
                    <div className="instructor__profile-review-form-card-form-rating">
                        <div className="instructor__profile-review-form-card-form-rating-select">
                            <p>Поставьте оценку:</p>
                            {[1, 2, 3, 4, 5].map((rating) => (
                                <span
                                    key={rating}
                                    onClick={() => setSelectedRating(rating)}
                                    className="instructor__profile-review-form-card-form-rating-select-stars"
                                >
                                    {selectedRating === rating ? (
                                        <CircleDotChecked />
                                    ) : (
                                        <CircleDotPrimary />
                                    )}
                                    {Array.from(
                                        { length: rating },
                                        (_, index) => (
                                            <FontAwesomeIcon
                                                key={index}
                                                icon={['fas', 'star']}
                                                color="orange"
                                            />
                                        ),
                                    )}
                                </span>
                            ))}
                        </div>
                        <button
                            type="submit"
                            className="btn btn-outline-warning"
                        >
                            {postReview.isPending ? (
                                <LoadingSpinner isLoading />
                            ) : (
                                'Отправить'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ReviewForm
