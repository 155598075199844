import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Nav } from 'react-bootstrap'
import { useActiveTabStore } from '@/store/ActiveTabStore'
import { useEffect, useRef } from 'react'
import { ScrollContainer } from 'react-indiana-drag-scroll'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { useUserQuery } from '@/lib/useUserQuery.ts'

export const headerIcons: IconName[] = [
    'car',
    'motorcycle',
    'truck-monster',
    'motorcycle',
    'truck',
    'truck',
    'trailer',
    'trailer',
    'bus',
    'bus',
    'motorcycle',
]

export default function MainPageHeader() {
    const { activeTab, setActiveTab } = useActiveTabStore()
    const tabRefs = useRef([])

    //!todo+ Не используется
    // const navbarRef = useRef(null)

    //!todo+ useUserQuery, к самому queryClient стоит редко обращаться
    const user = useUserQuery()
    const tabs = user?.tabs

    const centerActiveTab = () => {
        const activeTabIndex = tabs?.findIndex((tab) => tab.id === activeTab)
        const activeTabRef = tabRefs.current[activeTabIndex]

        if (activeTabRef && activeTabRef.scrollIntoView) {
            activeTabRef.scrollIntoView({
                block: 'nearest',
                inline: 'center',
                behavior: 'smooth',
            })
        }
    }

    useEffect(() => {
        centerActiveTab()
    }, [activeTab])

    return (
        <Nav className="navbar navbar-light bg-white scrollable">
            <ScrollContainer
                // ref={navbarRef}
                className="w-100 d-flex justify-content-between flex-nowrap"
            >
                {tabs &&
                    tabs.map((tab, index) => (
                        <Nav.Item
                            key={tab.id}
                            onClick={() => setActiveTab(tab.id)}
                            ref={(el) => (tabRefs.current[index] = el)}
                        >
                            <Nav.Link
                                active={activeTab === tab.id}
                                className="d-flex gap-1 align-items-center"
                            >
                                <FontAwesomeIcon
                                    icon={['fas', headerIcons[index]]}
                                />
                                {tab.name}
                            </Nav.Link>
                        </Nav.Item>
                    ))}
            </ScrollContainer>
        </Nav>
    )
}
