import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FadeInOutAnimate } from '@/components/FadeInOutAnimate.tsx'
import { IBalance } from '@/types/IPayments.ts'

interface Props {
    userBalance: IBalance
}

const UserBalanceInfo = ({ userBalance }: Props) => {
    const [showBalanceInfo, setShowBalanceInfo] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(null)

    const categories = Object.values(userBalance)?.map(
        (balance) => balance.CATEGORY,
    )

    const theory = Object.values(userBalance)?.find(
        (balance) => balance.CATEGORY === selectedCategory,
    )?.THEORY

    const drive = Object.values(userBalance)?.find(
        (balance) => balance.CATEGORY === selectedCategory,
    )?.DRIVE

    const trainer = Object.values(userBalance)?.find(
        (balance) => balance.CATEGORY === selectedCategory,
    )?.TRAINER

    const handleCategoryClick = (category) => {
        setSelectedCategory(category)
        setShowBalanceInfo(true)
    }

    return (
        <>
            <div className="user__balance-card-categories">
                {categories.map((category, index) => (
                    <button
                        key={index}
                        className={`user__balance-card-categories-btn btn btn-outline-warning ${
                            selectedCategory === category && 'active'
                        }`}
                        onClick={() => handleCategoryClick(category)}
                    >
                        <p>{category.slice(10)}</p>
                    </button>
                ))}
            </div>
            {showBalanceInfo && selectedCategory && (
                <FadeInOutAnimate>
                    <div className="user__balance-card-info">
                        <p className="fw-bold">
                            <FontAwesomeIcon icon={['fas', 'file-text']} />
                            &nbsp;Теория:&nbsp;
                            <span className="fw-normal">
                                {theory.REFILL} из {theory.COST}
                            </span>{' '}
                            <FontAwesomeIcon icon={['fas', 'rub']} size="sm" />
                        </p>
                        {theory.COST > theory.REFILL && (
                            <p>
                                Осталось заплатить:&nbsp;
                                <span className="fw-bold">
                                    {theory.COST - theory.REFILL}&nbsp;
                                    <FontAwesomeIcon
                                        icon={['fas', 'rub']}
                                        size="sm"
                                    />
                                </span>
                            </p>
                        )}
                    </div>
                    <div className="user__balance-card-info">
                        <p className="fw-bold">
                            {/*!todo Для категории B надо поштучное отображение
                                    В DRIVE добавил поля
                                    daytime: 19
                                    evening: 0
                                    fixed: true
                             */}
                            <FontAwesomeIcon icon={['fas', 'car']} />
                            <span> Практика: </span>
                            <span className="fw-normal">
                                {drive.REFILL} из {drive.COST}
                            </span>{' '}
                            <FontAwesomeIcon icon={['fas', 'rub']} size="sm" />
                        </p>
                        {drive.COST > drive.REFILL && (
                            <p>
                                <span> Осталось заплатить: </span>
                                <span className="fw-bold">
                                    {drive.COST - drive.REFILL}
                                </span>{' '}
                                <FontAwesomeIcon
                                    icon={['fas', 'rub']}
                                    size="sm"
                                />
                            </p>
                        )}
                    </div>
                    {trainer.COST > 0 && (
                        <div className="user__balance-card-info">
                            <p className="fw-bold">
                                <FontAwesomeIcon icon={['fas', 'desktop']} />
                                <span> Автотренажер: </span>
                                <span className="fw-normal">
                                    {trainer.REFILL} из {trainer.COST}
                                </span>{' '}
                                <FontAwesomeIcon
                                    icon={['fas', 'ruble-sign']}
                                    size="sm"
                                />
                            </p>
                            {trainer.COST > trainer.REFILL && (
                                <p>
                                    <span> Осталось заплатить: </span>
                                    <span className="fw-bold">
                                        {trainer.COST - trainer.REFILL}
                                    </span>{' '}
                                    <FontAwesomeIcon
                                        icon={['fas', 'ruble-sign']}
                                        size="sm"
                                    />
                                </p>
                            )}
                        </div>
                    )}
                </FadeInOutAnimate>
            )}
        </>
    )
}

export default UserBalanceInfo
