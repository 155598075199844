import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import DefaultModal from '@/components/Modals/DefaultModal/DefaultModal.tsx'
import { ForwardedRef, forwardRef, Fragment, useState } from 'react'
import { ChecklistValue, ICategory } from '@/types/ICategory.ts'
import SchoolExamResultsModalBody from '@/Pages/MainPage/MainPageCards/SchoolExamResultsModalBody.tsx'
import SchoolExamCityDrivesResultsModalBody from '@/Pages/MainPage/MainPageCards/SchoolExamCityDrivesResultsModalBody.tsx'

interface ISchoolExamsCardProps {
    category: ICategory
}

export const SchoolExamsCard = forwardRef(
    (
        { category }: ISchoolExamsCardProps,
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const [openTheoryExamResultsModal, setOpenTheoryExamResultsModal] =
            useState(false)
        const [openDriveExamResultsModal, setOpenDriveExamResultsModal] =
            useState(false)

        const examsDrive = category.exams.exam_drive
        const examsTheory = category.exams.exams_theory
        const examsTheoryArray = Array.isArray(examsTheory)
            ? examsTheory
            : Object.values<ChecklistValue>(examsTheory)
        const examsDriveArray = examsDrive ? Object.values(examsDrive) : []

        /*!todo+ вынеси компонент из компонента (объявлять компоненты внутри другого компонента очень нежелательно)*/

        return (
            <>
                <div className="learning__step-container" ref={ref}>
                    <div className="learning__step-icon">3</div>
                    <h1 className="learning__step-title">
                        Внутренние зачеты и экзамены
                    </h1>
                </div>
                <div className="school-exams__card card">
                    <div className="school-exams__card-group">
                        <h2 className="school-exams__card-group-title">
                            Зачеты по теории
                        </h2>
                        {examsTheoryArray?.map((item, index) => (
                            <Fragment key={index}>
                                {typeof item === 'string' && (
                                    <p className="school-exams__card-group-theory-goal-check">
                                        <FontAwesomeIcon
                                            icon={['fal', 'square']}
                                            color="red"
                                        />{' '}
                                        {item}
                                    </p>
                                )}
                                {category.name !== 'Категория AI' && (
                                    <div className="school-exams__card-group-theory-goal">
                                        {item && item.name && (
                                            <p className="school-exams__card-group-theory-goal-check">
                                                {item.status ? (
                                                    <FontAwesomeIcon
                                                        icon={[
                                                            'fal',
                                                            'check-square',
                                                        ]}
                                                        color="green"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={['fal', 'square']}
                                                        color="red"
                                                    />
                                                )}
                                                {item.name}
                                                <OverlayTrigger
                                                    key={index}
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item?.description,
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={[
                                                            'fas',
                                                            'info-circle',
                                                        ]}
                                                        color="orange"
                                                    />
                                                </OverlayTrigger>
                                            </p>
                                        )}
                                    </div>
                                )}
                            </Fragment>
                        ))}
                        <button
                            className="school-exams__card-button-theory btn btn-outline-warning"
                            onClick={() => setOpenTheoryExamResultsModal(true)}
                        >
                            {/* !todo+ Тут модалка открывается сразу, спиннер думаю не нужен*/}
                            Результаты внутренних экзаменов и зачетов по Теории
                        </button>
                    </div>
                    <div className="school-exams__card-group">
                        <h2 className="school-exams__card-group-title">
                            Экзамены по практике
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Все экзамены будут назначены
                                        инструктором после полного прохождения
                                        практики
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'info-circle']}
                                    color="orange"
                                    className="ms-1"
                                />
                            </OverlayTrigger>
                        </h2>
                        <div className="school-exams__card-group-exams">
                            {examsDriveArray?.map((item, index) => (
                                <Fragment key={index}>
                                    {item && item.name && (
                                        <p className="school-exams__card-group-exams-goal">
                                            {item.status ? (
                                                <FontAwesomeIcon
                                                    icon={[
                                                        'fal',
                                                        'check-square',
                                                    ]}
                                                    color="green"
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={['fal', 'square']}
                                                    color="red"
                                                />
                                            )}
                                            {/*!todo+ Модалка с результатом сдачи*/}
                                            <OptionalDescription item={item}>
                                                <span
                                                    className={
                                                        item.name ===
                                                        'Экзамен Город'
                                                            ? 'school-exams__card-group-exams-goal-item-active'
                                                            : 'school-exams__card-group-exams-goal-item'
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            item.name ===
                                                            'Экзамен Город'
                                                        ) {
                                                            setOpenDriveExamResultsModal(
                                                                true,
                                                            )
                                                        }
                                                    }}
                                                    /*!todo+ Добавить tooltip с датой сдачи экзамена или информацией, что экзамен еще не сдан*/
                                                >
                                                    {item.name}
                                                </span>
                                            </OptionalDescription>
                                        </p>
                                    )}
                                </Fragment>
                            ))}
                        </div>
                        <Link
                            to="/exams"
                            className="school-exams__card-button-exams btn btn-outline-warning"
                        >
                            Все экзамены
                        </Link>
                    </div>
                </div>
                <DefaultModal
                    className="p-3 m-0"
                    show={openTheoryExamResultsModal}
                    body={<SchoolExamResultsModalBody />}
                    closeButton
                    handleClose={() => setOpenTheoryExamResultsModal(false)}
                    title={false}
                    size="lg"
                />
                <DefaultModal
                    className="p-0 m-0"
                    bodyClassName="p-0 m-0"
                    show={openDriveExamResultsModal}
                    body={<SchoolExamCityDrivesResultsModalBody />}
                    closeButton={false}
                    handleClose={() => setOpenDriveExamResultsModal(false)}
                    title={false}
                    size="lg"
                    centered
                />
            </>
        )
    },
)

function OptionalDescription({ children, item }) {
    if ('description' in item) {
        return (
            <OverlayTrigger overlay={<Tooltip>{item.description}</Tooltip>}>
                {children}
            </OverlayTrigger>
        )
    } else {
        return children
    }
}
