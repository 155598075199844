import { useState } from 'react'
import {
    HistoryItemsCollapse,
    MultiHistoryItem,
    SingleHistoryItem,
} from '@/Pages/PaymentsPage/HistoryItems.tsx'
import { useFadeTransition } from '@/components/FadeTransition.tsx'
import { animated } from 'react-spring'
import { IHistoryData } from '@/types/IPayments.ts'

interface Props {
    paymentHistory: IHistoryData
}

const PaymentHistory = ({ paymentHistory }: Props) => {
    const [collapsedHistoryItems, setCollapsedHistoryItems] = useState([])

    const balanceTransition = useFadeTransition(paymentHistory !== null)

    const showPaymentHistoryItems = (historyIndex: number) => {
        const isOpen = collapsedHistoryItems.includes(historyIndex)
        if (isOpen) {
            setCollapsedHistoryItems(
                collapsedHistoryItems.filter((item) => item !== historyIndex),
            )
        } else {
            setCollapsedHistoryItems([...collapsedHistoryItems, historyIndex])
        }
    }

    return (
        <>
            {balanceTransition(
                (style, item) =>
                    item && (
                        <animated.div style={style}>
                            <h2 className="common__title">История платежей</h2>
                            {Object.values(paymentHistory).map(
                                (historyItem, historyIndex) => (
                                    <div
                                        key={historyIndex}
                                        className="payments__history"
                                    >
                                        {historyItem.length > 1 ? (
                                            <MultiHistoryItem
                                                historyItem={historyItem}
                                                showPaymentHistoryItems={
                                                    showPaymentHistoryItems
                                                }
                                                historyIndex={historyIndex}
                                                collapsedHistoryItems={
                                                    collapsedHistoryItems
                                                }
                                            />
                                        ) : (
                                            <SingleHistoryItem
                                                historyItem={historyItem}
                                            />
                                        )}
                                        {historyItem.map((item, itemIndex) => (
                                            <div key={itemIndex}>
                                                {historyItem.length > 1 && (
                                                    <HistoryItemsCollapse
                                                        collapsedHistoryItems={
                                                            collapsedHistoryItems
                                                        }
                                                        historyIndex={
                                                            historyIndex
                                                        }
                                                        showPaymentHistoryItems={
                                                            showPaymentHistoryItems
                                                        }
                                                        item={item}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ),
                            )}
                        </animated.div>
                    ),
            )}
        </>
    )
}

export default PaymentHistory
