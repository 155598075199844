import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useSystemSettingsStore } from '@/store/SystemSettingsStore.ts'

export default function NotFoundPage() {
    const isWindowWide = useSystemSettingsStore((state) => state.isWindowWide)
    return (
        <div className="d-flex align-items-center justify-content-center min-vh-100 bg-warning bg-body-tertiary position-relative">
            <div className="text-center">
                <FontAwesomeIcon
                    icon={['fas', 'do-not-enter']}
                    color="red"
                    size={isWindowWide ? '10x' : '5x'}
                    className="p-1 border-1 border-black border-top border-start border-bottom border-end rounded-circle"
                />
                <h1 className="mt-3 fs-lg-21 fs-md-19 fs-sm-17">
                    Такой страницы не существует
                </h1>
                <Link
                    to="/"
                    className="btn btn-outline-danger mt-5 fs-lg-16 fs-md-14 fs-sm-12"
                >
                    Перейти на главную страницу
                </Link>
            </div>
            <p className="position-absolute bottom-0 start-0 fs-lg-12 fs-md-10 fs-sm-9">
                Знак 3.1 «Въезд запрещен» — дорожный знак в виде красного круга
                с белым прямоугольником. Он запрещает проезд всех транспортных
                средств в определенном направлении. Знак устанавливают перед
                въездом: на дорогу с односторонним движением или полосу
                встречного направления.
            </p>
        </div>
    ) /*!todo+ Надо бы пофиксить дизайн*/
}
