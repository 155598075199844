import { WelcomeCard } from '@/Pages/MainPage/MainPageCards/WelcomeCard.tsx'
import { SchoolExamsCard } from '@/Pages/MainPage/MainPageCards/SchoolExamsCard.tsx'
import { TheoryCard } from '@/Pages/MainPage/MainPageCards/TheoryCard.tsx'
import { DrivesCard } from '@/Pages/MainPage/MainPageCards/DrivesCard.tsx'
import { ExamsCard } from '@/Pages/MainPage/MainPageCards/ExamsCard.tsx'
import FooterCard from '@/Pages/MainPage/MainPageCards/FooterCard.tsx'
import { useRef } from 'react'
import { useQuery } from '@tanstack/react-query'
import MainPageSkeleton from '@/Pages/MainPage/MainPageSkeleton.tsx'
import { useActiveTabStore } from '@/store/ActiveTabStore.ts'
import api from '@/api/api.ts'

export default function MainPageLayout() {
    const activeTab = useActiveTabStore((state) => state.activeTab)
    const refs = useRef([])
    const scrollToRef = (index: number) => {
        if (refs.current[index]) {
            const elementPosition =
                refs.current[index].getBoundingClientRect().top
            const offsetPosition = elementPosition - 70

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            })
        }
    }
    //!todo+ useUserQuery (удалил)
    const category = useQuery({
        queryKey: ['user', 'category', activeTab],
        queryFn: () =>
            api.get(`/user/category/${activeTab}`).then((res) => res.data),
        enabled: !!activeTab,
        staleTime: 60000,
    })

    if (category.isPending) return <MainPageSkeleton />

    return (
        <div className="page">
            <WelcomeCard category={category.data} scrollToRef={scrollToRef} />
            <TheoryCard
                category={category.data}
                ref={(el) => (refs.current[0] = el)}
            />
            <DrivesCard
                category={category.data}
                ref={(el) => (refs.current[1] = el)}
            />
            <SchoolExamsCard
                category={category.data}
                ref={(el) => (refs.current[2] = el)}
            />
            <ExamsCard
                category={category.data}
                ref={(el) => (refs.current[3] = el)}
            />
            <FooterCard />
        </div>
    )
}
