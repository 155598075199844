import { Col, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const MarathonTicketSkeleton = () => {
    return (
        <div className="card">
            <div className="card-title px-3">
                <div className="mb-4 d-flex flex-nowrap overflow-hidden">
                    <Skeleton
                        count={31}
                        height={35}
                        width={50}
                        style={{
                            borderRadius: 0,
                            flex: '1',
                            marginRight: '4px',
                        }}
                        containerClassName="d-flex flex-nowrap"
                    />
                </div>
                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center gap-2">
                    <Skeleton
                        height={30}
                        style={{ flex: '1' }}
                        containerClassName="col-4 col-sm-3 col-md-2 col-lg-1"
                    />
                    <div className="justify-content-center align-content-center d-flex flex-column flex-md-row gap-1 col-lg-5 col-6">
                        <Skeleton
                            height={30}
                            width="100%"
                            style={{ flex: '1' }}
                            containerClassName="col-12 col-sm-6 col-md-6 mx-auto"
                        />
                        <Skeleton
                            height={30}
                            width="100%"
                            style={{ flex: '1' }}
                            containerClassName="col-12 col-sm-6 col-md-6 mx-auto"
                        />
                    </div>
                    <Skeleton height={30} width={100} style={{ flex: '1' }} />
                </div>
            </div>
            <div className="card-body">
                <Row>
                    <Col lg={6} className="text-center">
                        <Skeleton className="mb-3" height={200} />
                        <Skeleton className="mb-4" height={24} width="60%" />
                        <>
                            <div className="mb-3">
                                <Skeleton
                                    count={3}
                                    height={35}
                                    className="my-1"
                                />
                            </div>
                            <Skeleton height={35} className="my-1" />
                        </>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default MarathonTicketSkeleton
