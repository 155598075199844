import { useTicketsQuestionsStore } from '@/store/TicketsQuestionsStore.ts'
import { useExamStore } from '@/store/ExamStore.ts'

function ExamResults() {
    const { ExamTicketsQuestions } = useTicketsQuestionsStore()
    const { answers, answeredIndexes } = useExamStore()

    return (
        <div className="page">
            {ExamTicketsQuestions?.map((question, index) => (
                <div key={question.id} className="exam__result-card card">
                    <div className="exam__result-card-body">
                        {/*!todo "Вопрос <Номер>"*/}
                        <img
                            src={question.img}
                            alt="exam-question-image"
                            className="img-fluid"
                        />
                        <h3 className="exam__result-card-body-question">
                            {question.qst}
                        </h3>
                        {Object.keys(question)
                            .filter(
                                (key) =>
                                    key.startsWith('ans_') && question[key],
                            )
                            .map((answer, idx) => (
                                <p
                                    key={idx}
                                    className={
                                        answer === 'ans_' + question.right
                                            ? 'exam__result-card-body-answer-success'
                                            : answers[
                                                    answeredIndexes[index]
                                                ] === answer
                                              ? 'exam__result-card-body-answer-wrong'
                                              : 'exam__result-card-body-answer'
                                    }
                                >
                                    {question[answer]}
                                </p>
                            ))}
                        <p
                            className="exam__result-card-body-comment"
                            style={{
                                borderLeft:
                                    answers[answeredIndexes[index]] ===
                                    'ans_' + question.right
                                        ? '3px solid green'
                                        : '3px solid red',
                            }}
                        >
                            {question.comment}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default ExamResults
