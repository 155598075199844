import { Col, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const QuestionsPreviewSkeleton = () => {
    return (
        <>
            <div className="page" style={{ paddingTop: '110px' }}>
                <div
                    className="py-3 px-4 position-fixed top-0 start-0 w-100 z-2 bg-white fs-lg-18 fs-md-16 fs-sm-14"
                    style={{
                        boxShadow: '0 0 5px 0 rgba(0,0,0, .2)',
                        marginTop: '70px',
                    }}
                >
                    <div className="text-end">
                        <Skeleton width={200} />
                    </div>
                </div>
                <Row className="px-2">
                    <Col lg={3} className="px-3 py-2">
                        <div className="card py-0 px-0">
                            <div className="px-3 py-2">
                                <Skeleton height={200} />
                            </div>
                            <div className="mb-4 px-3">
                                <Skeleton />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} className="px-3 py-2">
                        <div className="card py-0 px-0">
                            <div className="px-3 py-2">
                                <Skeleton height={200} />
                            </div>
                            <div className="mb-4 px-3">
                                <Skeleton />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} className="px-3 py-2">
                        <div className="card py-0 px-0">
                            <div className="px-3 py-2">
                                <Skeleton height={200} />
                            </div>
                            <div className="mb-4 px-3">
                                <Skeleton />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} className="px-3 py-2">
                        <div className="card py-0 px-0">
                            <div className="px-3 py-2">
                                <Skeleton height={200} />
                            </div>
                            <div className="mb-4 px-3">
                                <Skeleton />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} className="px-3 py-2">
                        <div className="card py-0 px-0">
                            <div className="px-3 py-2">
                                <Skeleton height={200} />
                            </div>
                            <div className="mb-4 px-3">
                                <Skeleton />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} className="px-3 py-2">
                        <div className="card py-0 px-0">
                            <div className="px-3 py-2">
                                <Skeleton height={200} />
                            </div>
                            <div className="mb-4 px-3">
                                <Skeleton />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} className="px-3 py-2">
                        <div className="card py-0 px-0">
                            <div className="px-3 py-2">
                                <Skeleton height={200} />
                            </div>
                            <div className="mb-4 px-3">
                                <Skeleton />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} className="px-3 py-2">
                        <div className="card py-0 px-0">
                            <div className="px-3 py-2">
                                <Skeleton height={200} />
                            </div>
                            <div className="mb-4 px-3">
                                <Skeleton />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} className="px-3 py-2">
                        <div className="card py-0 px-0">
                            <div className="px-3 py-2">
                                <Skeleton height={200} />
                            </div>
                            <div className="mb-4 px-3">
                                <Skeleton />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} className="px-3 py-2">
                        <div className="card py-0 px-0">
                            <div className="px-3 py-2">
                                <Skeleton height={200} />
                            </div>
                            <div className="mb-4 px-3">
                                <Skeleton />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} className="px-3 py-2">
                        <div className="card py-0 px-0">
                            <div className="px-3 py-2">
                                <Skeleton height={200} />
                            </div>
                            <div className="mb-4 px-3">
                                <Skeleton />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} className="px-3 py-2">
                        <div className="card py-0 px-0">
                            <div className="px-3 py-2">
                                <Skeleton height={200} />
                            </div>
                            <div className="mb-4 px-3">
                                <Skeleton />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default QuestionsPreviewSkeleton
