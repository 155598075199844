import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './BottomNav.scss'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const BottomNav = ({ setVisible, isSidebarVisible }) => {
    const [selected, setSelected] = useState('')

    useEffect(() => {
        if (!isSidebarVisible) {
            setSelected('')
        }
    }, [isSidebarVisible])

    return (
        <div className="bottom-nav__panel">
            <Link
                to="/"
                className={`bottom-nav__panel-link ${
                    selected === 'Обучение' ? 'text-danger' : 'text-black'
                }`}
                onClick={() => setSelected('Обучение')}
            >
                <FontAwesomeIcon icon={['fal', 'graduation-cap']} size="lg" />
                Обучение
            </Link>
            <Link
                to="/tickets"
                className={`bottom-nav__panel-link ${
                    selected === 'Билеты' ? 'text-danger' : 'text-black'
                }`}
                onClick={() => setSelected('Билеты')}
            >
                <FontAwesomeIcon icon={['fal', 'file-lines']} size="lg" />
                Билеты
            </Link>
            <Link
                to="/lessons"
                className={`bottom-nav__panel-link ${
                    selected === 'Уроки' ? 'text-danger' : 'text-black'
                }`}
                onClick={() => setSelected('Уроки')}
            >
                <FontAwesomeIcon icon={['fal', 'video']} size="lg" />
                Уроки
            </Link>
            <Link
                to="/payments"
                className={`bottom-nav__panel-link ${
                    selected === 'Оплата' ? 'text-danger' : 'text-black'
                }`}
                onClick={() => setSelected('Оплата')}
            >
                <FontAwesomeIcon icon={['fal', 'ruble-sign']} size="lg" />
                Оплата
            </Link>
            <p
                className={`bottom-nav__panel-link ${
                    selected === 'Меню' ? 'text-danger' : 'text-black'
                }`}
                onClick={() => {
                    setVisible(!isSidebarVisible)
                    setSelected((prev) => (prev === 'Меню' ? '' : 'Меню'))
                }}
            >
                <FontAwesomeIcon icon={['fal', 'bars']} size="lg" />
                Меню
            </p>
        </div>
    )
}

export default BottomNav
