import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MainTicketsStatistics, Statistic } from '@/types/IStatistics.ts'

interface Props {
    statistic: MainTicketsStatistics[]
}

/*!todo Почему-то если зайти на эту страницу по адресу, скелет "мигает" и загружается несколько раз*/
function TicketsStatistics({ statistic }: Props) {
    //!todo+ Хранить выбранный билет в сторе лишнее, лучше в стейте
    const [ticketsStatistics, setTicketsStatistics] = useState(statistic)
    const [currentTicket, setCurrentTicket] = useState(null)

    return (
        <>
            <h2 className="common__title">
                Статистика по всем билетам
                <p className="fw-normal">
                    Выберите номер билета, чтобы посмотреть статистику
                </p>
            </h2>
            <div className="tickets__statistic-card card">
                <div className="tickets__statistic-card-buttons">
                    {ticketsStatistics?.map((ticket, index) => (
                        <Button
                            variant="outline-warning"
                            key={index}
                            onClick={() => setCurrentTicket(ticket)}
                            className={`tickets__statistic-card-buttons-btn ${
                                currentTicket === ticket ? 'active' : ''
                            }`}
                        >
                            {ticket.bilet}
                        </Button>
                    ))}
                </div>
                {/*!todo+ currentTicket && */}
                {currentTicket?.statistics && (
                    <div className="tickets__statistic-card-body">
                        <table className="table table-bordered table-striped table-sm m-0">
                            <thead className="thead-invert">
                                <tr className="text-center">
                                    <th>Дата</th>
                                    <th>Верно</th>
                                    <th>Неверно</th>
                                    <th>Ошибки</th>
                                    <th>Результат</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/*!todo+ currentTicket всегда есть, ? не нужен, statistics тоже*/}
                                {currentTicket.statistics.map(
                                    (statistic: Statistic, index: number) => (
                                        <tr key={index} className="text-center">
                                            <td className="text-break">
                                                {statistic.date}
                                            </td>
                                            <td className="text-break">
                                                {statistic.correct}
                                            </td>
                                            <td className="text-break">
                                                {statistic.incorrect}
                                            </td>
                                            <td className="text-break">
                                                {statistic.errors}
                                            </td>
                                            <td className="text-break">
                                                {statistic.status ? (
                                                    <FontAwesomeIcon
                                                        icon={[
                                                            'fal',
                                                            'circle-check',
                                                        ]}
                                                        color="green"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={[
                                                            'fal',
                                                            'circle-xmark',
                                                        ]}
                                                        color="red"
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    ),
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </>
    )
}
export default TicketsStatistics
