import { useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'

export const FadeInOutAnimate = ({ children }) => {
    const [displayChildren, setDisplayChildren] = useState(children)
    const [animation, setAnimation] = useState('in')

    const animations = useSpring({
        from: { opacity: 0 },
        to: { opacity: animation === 'in' ? 1 : 0 },
        reverse: animation === 'out',
        config: { duration: 350 },
        onRest: () => {
            if (animation === 'out') {
                setAnimation('in')
                setDisplayChildren(children)
            }
        },
    })

    useEffect(() => {
        if (displayChildren !== children) {
            setAnimation('out')
        }
    }, [children])

    //!todo+- Тут наверно можно без useEffect
    //https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
    //https://react.dev/reference/react/useState#storing-information-from-previous-renders

    //&& nextChildren === null
    // useEffect(() => {
    // console.log(children, displayChildren, nextChildren)
    // if (displayChildren === children) {
    //     setAnimation('in')
    //     setDisplayChildren(children)
    // }
    // }, [children, displayChildren, nextChildren])

    return <animated.div style={animations}>{displayChildren}</animated.div>
}
