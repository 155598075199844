import { useTicketsQuestionsStore } from '@/store/TicketsQuestionsStore.ts'
import Questions from '@/Pages/Tickets/Questions.tsx'

export default function TicketCard({ timer }) {
    const { TicketsQuestions } = useTicketsQuestionsStore()

    return (
        <div className="question__card card">
            <Questions ticketsQuestions={TicketsQuestions} timer={timer} />
        </div>
    )
}
