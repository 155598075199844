import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ForwardedRef, forwardRef } from 'react'
import { ICategory } from '@/types/ICategory.ts'

interface IDrivesCardProps {
    category: ICategory
}

export const DrivesCard = forwardRef(
    ({ category }: IDrivesCardProps, ref: ForwardedRef<HTMLDivElement>) => {
        const driveCheckList = category.drive_checklist
        const instructor = category.instructor
        const drives = category.drives

        function getColorByStatus(payment, status) {
            //!todo+ не учитывается "Не оплачен" ($payment === false)
            if (payment) {
                if (status === false || payment === false) return 'red'
                if (status === true && payment === true) return 'green'
                if (status === null && payment === true) return 'orange'
            } else if (payment === null && status === null) {
                return 'grey'
            }
        }

        return (
            <>
                <div className="learning__step-container" ref={ref}>
                    <div className="learning__step-icon">2</div>
                    <h1>Сцепление, скорость.. Поехали!</h1>
                </div>
                <div className="card" style={{ marginBottom: '40px' }}>
                    <div className="drives__card">
                        <div className="drives__card-drives-group">
                            <h2 className="drives__card-drives-group-title">
                                Чтобы приступить к вождению необходимо:
                            </h2>
                            <ul className="drives__card-drives-group-goal">
                                <li className="drives__card-drives-group-goal-check">
                                    {driveCheckList?.medical && (
                                        <>
                                            {driveCheckList.medical?.status ? (
                                                <FontAwesomeIcon
                                                    icon={[
                                                        'fal',
                                                        'check-square',
                                                    ]}
                                                    color="green"
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={['fal', 'square']}
                                                    color="red"
                                                />
                                            )}
                                            <span>
                                                {driveCheckList.medical?.name}
                                            </span>
                                        </>
                                    )}
                                </li>
                                <li className="drives__card-drives-group-goal-check">
                                    {driveCheckList?.trainer && (
                                        <>
                                            {!driveCheckList.trainer?.status ? (
                                                <FontAwesomeIcon
                                                    icon={['fal', 'square']}
                                                    color="red"
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={[
                                                        'fal',
                                                        'check-square',
                                                    ]}
                                                    color="green"
                                                />
                                            )}
                                            <span>
                                                {driveCheckList.trainer?.name}
                                            </span>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        Если вы обучаетесь на
                                                        АКПП, то занятия на
                                                        автотренажере проходить
                                                        не нужно
                                                    </Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={[
                                                        'fas',
                                                        'info-circle',
                                                    ]}
                                                    color="orange"
                                                />
                                            </OverlayTrigger>
                                        </>
                                    )}
                                </li>

                                <li className="drives__card-drives-group-goal-check">
                                    {driveCheckList?.vehicle && (
                                        <>
                                            {!driveCheckList.vehicle?.status ? (
                                                <FontAwesomeIcon
                                                    icon={['fal', 'square']}
                                                    className="red"
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={[
                                                        'fal',
                                                        'check-square',
                                                    ]}
                                                    color="green"
                                                />
                                            )}
                                            <span>
                                                {driveCheckList.vehicle?.name}
                                            </span>
                                        </>
                                    )}
                                </li>
                            </ul>
                        </div>
                        <div className="drives__card-drives-group">
                            {instructor !== null ? (
                                <div className="drives__card-drives-group-instructor">
                                    <p className="drives__card-drives-group-instructor-info">
                                        Ваш инструктор: <b>{instructor.name}</b>
                                        <a
                                            href={`tel:${instructor.phone}`}
                                            className="welcome__card-manager-phone btn btn-outline-warning"
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'phone-alt']}
                                                color="black"
                                                size="sm"
                                            />
                                        </a>
                                    </p>
                                    {instructor.car && (
                                        <>
                                            <p className="drives__card-drives-group-instructor-info">
                                                Учебное ТС:
                                                <b>{instructor.car.name}</b>
                                            </p>
                                            <p className="drives__card-drives-group-instructor-info">
                                                Госномер:
                                                <b>{instructor.car.number}</b>
                                            </p>
                                        </>
                                    )}
                                    <p className="drives__card-drives-group-instructor-info mb-3">
                                        Рейтинг:
                                        {[...Array(5)].map((_, index) => (
                                            <FontAwesomeIcon
                                                key={index}
                                                icon={['fas', 'star']}
                                                color={
                                                    index <
                                                    category.instructor.rating
                                                        ? 'orange'
                                                        : 'grey'
                                                }
                                            />
                                        ))}
                                    </p>

                                    <Link
                                        to="/instructors"
                                        className="drives__card-button btn btn-outline-warning"
                                    >
                                        Все инструкторы
                                    </Link>
                                </div>
                            ) : (
                                <p>
                                    Ваш инструктор: <b>Еще не назначен</b>
                                </p>
                            )}
                        </div>
                    </div>
                    <div>
                        <h2 className="drives__card-drives-group-title mb-2">
                            Занятия с инструктором
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Практическое занятие отмечается
                                        пройденным, если оно оплачено и
                                        инструктор подтвердил ваше присутствие
                                        на нем
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'info-circle']}
                                    color="orange"
                                    className="ms-1"
                                />
                            </OverlayTrigger>
                        </h2>
                        <div className="drives__card-drives-group-icons">
                            {/*!todo+ if drives == null "Сначала необходимо познакомиться с инструктором"*/}
                            {drives === null ? (
                                <p>
                                    Сначала необходимо познакомиться с
                                    инструктором
                                </p>
                            ) : (
                                drives.map((item, index) => {
                                    const color = getColorByStatus(
                                        item.payment,
                                        item.status,
                                    )

                                    return (
                                        <OverlayTrigger
                                            key={index}
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    {item.date}
                                                    {item.date !== null &&
                                                        ' - '}
                                                    {item.description}
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', item.icon]}
                                                color={color}
                                                size="1x"
                                            />
                                        </OverlayTrigger>
                                    )
                                })
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    },
)
