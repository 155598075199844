import VideoLessonsList from '@/Pages/VideoLessons/VideoLessonsList.tsx'
import './VideoLessons.scss'
import VideoLessonsSkeleton from '@/Pages/VideoLessons/VideoLessonsSkeleton.tsx'
import { useQuery } from '@tanstack/react-query'
import api from '@/api/api.ts'
import { useVideoListStore } from '@/store/VideoListStore.ts'
import { useEffect } from 'react'
import { useUserQuery } from '@/lib/useUserQuery.ts'

function VideoLessonsPage() {
    const { setVideoList, videoList } = useVideoListStore()

    const user = useUserQuery()
    const schoolInfo = useQuery({
        queryKey: ['school', 'info', user],
        queryFn: async () => await api.get('info'),
        staleTime: Infinity,
        enabled: !!user,
    })

    const { data: videoListData, isSuccess: isVideoListSuccess } = useQuery({
        queryKey: ['video', 'list'],
        queryFn: async () => await api.get('/video/list'),
        enabled: !!schoolInfo.data,
    })

    useEffect(() => {
        isVideoListSuccess && setVideoList(videoListData.data)
    }, [isVideoListSuccess])

    return (
        <>
            {videoList ? (
                <div className="video__page page">
                    <VideoLessonsList />
                </div>
            ) : (
                <VideoLessonsSkeleton />
            )}
        </>
    )
}
export default VideoLessonsPage
