import './Practice.scss'
import { useActiveTabStore } from '@/store/ActiveTabStore.ts'
import DriveCards from '@/Pages/PracticePage/DriveCards.tsx'
import ExamCards from '@/Pages/PracticePage/ExamCards.tsx'
import PracticePageSkeleton from '@/Pages/PracticePage/PracticePageSkeleton.tsx'
import MainPageHeader from '@/Pages/MainPage/MainPageHeader.tsx'
import { useQuery } from '@tanstack/react-query'
import api from '@/api/api.ts'
import { useUserQuery } from '@/lib/useUserQuery.ts'

const PracticePage = () => {
    const { activeTab } = useActiveTabStore()

    const user = useUserQuery()
    const schoolInfo = useQuery({
        queryKey: ['school', 'info', user],
        queryFn: async () => await api.get('info'),
        staleTime: Infinity,
        enabled: !!user,
    })

    const { data: practiceData, isLoading: isPracticeDataLoading } = useQuery({
        queryKey: ['drivesList', activeTab],
        queryFn: () => api.get(`/instructor/drive/category/${activeTab}`),
        enabled: !!activeTab && !!schoolInfo.data,
    })

    return (
        <>
            <MainPageHeader />
            {!isPracticeDataLoading && practiceData?.data ? (
                <div className="practice__page page">
                    <h2 className="common__title practice__page-title">
                        Практика с инструктором
                        <span className="practice__page-title-badge badge">
                            {practiceData.data.drives.length}
                        </span>
                    </h2>
                    <DriveCards practiceData={practiceData.data} />

                    <h2 className="common__title practice__page-title">
                        Экзамены в ГИБДД
                        <span className="practice__page-title-badge badge">
                            {practiceData.data.exams.length}
                        </span>
                    </h2>

                    <ExamCards practiceData={practiceData.data} />
                </div>
            ) : (
                <PracticePageSkeleton />
            )}
        </>
    )
}

export default PracticePage
