import Skeleton from 'react-loading-skeleton'

const UserProfileModalSkeleton = () => {
    return (
        <>
            <div>
                <div className="text-center mb-4">
                    <Skeleton
                        circle
                        width={100}
                        height={100}
                        enableAnimation={false}
                    />
                </div>
                <div className="text-center mb-1">
                    <Skeleton width={160} height={21} />
                </div>
                <div className="text-center mb-1">
                    <Skeleton width={150} height={21} />
                </div>
                <div className="text-center">
                    <Skeleton width={190} height={21} />
                </div>
                <div className="text-center mt-5 pt-1">
                    <Skeleton height={37} />
                </div>
            </div>
        </>
    )
}

export default UserProfileModalSkeleton
