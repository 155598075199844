import { useState } from 'react'
import InstructorsCards from '@/Pages/InstructorsPage/InstructorsCards.tsx'
import ReactMultiSelect from '@/Pages/InstructorsPage/SelectComponent.tsx'
import './Instructors.scss'
import {
    InstructorPageSkeleton,
    SelectSkeleton,
} from '@/Pages/InstructorsPage/InstructorPageSkeleton.tsx'
import { useQuery } from '@tanstack/react-query'
import api from '@/api/api.ts'
import { useUserQuery } from '@/lib/useUserQuery.ts'

// !todo Тоже мигает если зайти напрямую. Скорее всего потому-что у тебя userInfo и schoolInfo обновляются в MainLayout, а он вне FadeOutInAnimate, из-за чего полный ререндер страницы
const InstructorsPage = () => {
    const [selectedCarMarks, setSelectedCarMarks] = useState([])
    const [selectedCarModels, setSelectedCarModels] = useState([])
    const [selectedGender, setSelectedGender] = useState([])
    const [selectedTransmission, setSelectedTransmission] = useState([])
    const [selectedFilters, setSelectedFilters] = useState({
        car_marks: [],
        car_models: [],
        kpp: [],
        sex: [],
    })

    const user = useUserQuery()
    const schoolInfo = useQuery({
        queryKey: ['school', 'info', user],
        queryFn: async () => await api.get('info'),
        staleTime: Infinity,
        enabled: !!user,
    })

    const { data: filtersData, isLoading: isFiltersDataLoading } = useQuery({
        queryKey: ['instructors', 'list', selectedFilters],
        queryFn: () =>
            api.get('/instructor/all', {
                params: {
                    filters: JSON.stringify(selectedFilters),
                },
            }),
        enabled: !!schoolInfo.data,
    })

    return (
        <div className="page">
            {!filtersData?.data ? (
                <SelectSkeleton />
            ) : (
                <ReactMultiSelect
                    selectedMarks={selectedCarMarks}
                    setSelectedMarks={setSelectedCarMarks}
                    selectedModels={selectedCarModels}
                    setSelectedModels={setSelectedCarModels}
                    selectedGender={selectedGender}
                    setSelectedGender={setSelectedGender}
                    selectedTransmission={selectedTransmission}
                    setSelectedTransmission={setSelectedTransmission}
                    allFilters={filtersData.data.filters}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                />
            )}
            {/*!todo+ Сделай условие через ?: */}
            {isFiltersDataLoading || !filtersData?.data ? (
                <InstructorPageSkeleton />
            ) : (
                <>
                    <InstructorsCards
                        instructors={filtersData.data.instructors}
                    />
                </>
            )}
        </div>
    )
}

export default InstructorsPage
