import { useEffect, useState } from 'react'
import { useTicketsQuestionsStore } from '@/store/TicketsQuestionsStore.ts'
import { Button, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useExamStore } from '@/store/ExamStore.ts'
import { toast } from 'react-toastify'
import {
    CircleDotChecked,
    CircleDotPrimary,
} from '@/components/Forms/TicketsInputIcons.tsx'

function ExamQuestionCard({ setCurrentQuestionIndex, currentQuestionIndex }) {
    const { setTicketsQuestions, TicketsQuestions } = useTicketsQuestionsStore()
    const { ExamTicketsQuestions } = useTicketsQuestionsStore()
    const {
        answers,
        setAnswers,
        answeredIndexes,
        setAnsweredIndexes,
        correctAnswersCount,
        setCorrectAnswersCount,
        errorAnswersCount,
        setErrorAnswersCount,
        errorsList,
        setErrorsList,
        setShowResults,
        setTicketsEndModalShow,
    } = useExamStore()
    const [selectedAnswers, setSelectedAnswers] = useState({})
    const [currentAnswerIndex, setCurrentAnswerIndex] = useState(1)
    const selectedAnswer = selectedAnswers[currentQuestionIndex]
    const trueAnswer =
        'ans_' + ExamTicketsQuestions[currentQuestionIndex]?.right
    const question = ExamTicketsQuestions[currentQuestionIndex]
    const answerKeys = Object.keys(TicketsQuestions).filter(
        (key) => key.startsWith('ans_') && TicketsQuestions[key],
    )

    const handleKeyboardInput = (e) => {
        const code = e.keyCode || e.which
        e.preventDefault()
        switch (code) {
            case 27:
                setTicketsQuestions(null)
                break
            case 38:
                if (
                    !answeredIndexes?.includes(currentQuestionIndex) &&
                    currentAnswerIndex > 1
                ) {
                    setCurrentAnswerIndex(currentAnswerIndex - 1)
                    setSelectedAnswers({
                        ...selectedAnswers,
                        [currentQuestionIndex]: `ans_${currentAnswerIndex - 1}`,
                    })
                }
                break
            case 40:
                if (
                    !answeredIndexes?.includes(currentQuestionIndex) &&
                    currentAnswerIndex < answerKeys.length &&
                    currentAnswerIndex >= 0
                ) {
                    setCurrentAnswerIndex(currentAnswerIndex + 1)
                    setSelectedAnswers({
                        ...selectedAnswers,
                        [currentQuestionIndex]: `ans_${
                            currentAnswerIndex + 1
                        }` /*!todo Почему-то после ответа можно пролистать один раз стрелками*/,
                    })
                }
                break
            case 39:
                handleNextQuestion()
                break
            case 37:
                handlePreviousQuestion()
                break
            case 32:
                handleConfirmAnswer()
                break
            default:
                break
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyboardInput)
        return () => {
            window.removeEventListener('keydown', handleKeyboardInput)
        }
    }, [selectedAnswers, currentQuestionIndex, answerKeys.length])

    const handleConfirmAnswer = () => {
        if (!selectedAnswer) {
            toast('Выберите вариант ответа')
            return
        } else {
            setAnswers([...answers, selectedAnswer])
            setAnsweredIndexes([...answeredIndexes, currentQuestionIndex])
            if (selectedAnswer === trueAnswer) {
                setCorrectAnswersCount(correctAnswersCount + 1)
            } else {
                setErrorAnswersCount(errorAnswersCount + 1)
                setErrorsList([...errorsList, [question.bn, question.qn]])
            }
        }
    }

    useEffect(() => {
        if (
            answers.length < 20 &&
            currentQuestionIndex <= 19 &&
            currentQuestionIndex >= 0
        ) {
            setTicketsQuestions(ExamTicketsQuestions[currentQuestionIndex])
        }
    }, [currentQuestionIndex])

    const handleSelectAnswer = (answer) => {
        setSelectedAnswers({
            ...selectedAnswers,
            [currentQuestionIndex]: answer,
        })
        setCurrentAnswerIndex(0)
    }

    useEffect(() => {
        if (answeredIndexes?.length > 19) {
            setTicketsEndModalShow(true)
            setShowResults(true)
        }
    }, [answeredIndexes])

    const handlePreviousQuestion = () => {
        currentQuestionIndex !== 0
            ? setCurrentQuestionIndex(currentQuestionIndex - 1)
            : null
        setCurrentAnswerIndex(0)
    }

    const handleNextQuestion = () => {
        currentQuestionIndex !== 19
            ? setCurrentQuestionIndex(currentQuestionIndex + 1)
            : null
        setCurrentAnswerIndex(0)
    }

    return (
        //!todo Не хватает заголовка "Вопрос <номер>"
        <div className="question__card-content card">
            <div className="question__card-content-image">
                <img
                    src={TicketsQuestions?.img}
                    alt="exam-question-image"
                    className="w-100"
                />
            </div>
            <div className="question__card-content-question">
                <p className="question__card-content-question-text">
                    {TicketsQuestions?.qst}
                </p>
                {answeredIndexes?.includes(currentQuestionIndex) && (
                    <div className="question__card-content-question-alert alert alert-success">
                        <FontAwesomeIcon
                            icon={['fal', 'exclamation-circle']}
                            size="lg"
                        />
                        <p>Вы ответили на этот вопрос</p>
                    </div>
                )}
                <div className="question__card-content-question-answers">
                    {answerKeys &&
                        answerKeys?.map((ansKey, idx) => (
                            <div
                                key={idx}
                                className="question__card-content-question-answers-answer"
                                style={{
                                    pointerEvents: answeredIndexes?.includes(
                                        currentQuestionIndex,
                                    )
                                        ? 'none'
                                        : 'auto',
                                }}
                            >
                                <input
                                    type="radio"
                                    key={idx}
                                    className="btn-check"
                                    name={`question_${TicketsQuestions?.id}`}
                                    id={`question_${idx}`}
                                    autoComplete="off"
                                    checked={
                                        selectedAnswers[
                                            currentQuestionIndex
                                        ] === ansKey
                                    }
                                    onChange={() =>
                                        setSelectedAnswers({
                                            ...selectedAnswers,
                                            [currentQuestionIndex]: ansKey,
                                        })
                                    }
                                    disabled={answeredIndexes?.includes(
                                        currentQuestionIndex,
                                    )}
                                />
                                <label
                                    className="btn btn-check-tickets p-2 w-100"
                                    htmlFor={`question_${idx}`}
                                    onClick={() => handleSelectAnswer(ansKey)}
                                >
                                    <div className="btn-check-tickets-items">
                                        {selectedAnswer === ansKey ||
                                        `ans_${currentAnswerIndex}` ===
                                            ansKey ? (
                                            <CircleDotChecked />
                                        ) : (
                                            <CircleDotPrimary />
                                        )}
                                        <p>{TicketsQuestions[ansKey]}</p>
                                    </div>
                                </label>
                            </div>
                        ))}
                </div>
            </div>
            {!answeredIndexes?.includes(currentQuestionIndex) && (
                <Col lg={3} className="mb-3">
                    <Button
                        variant="outline-warning"
                        className="w-100"
                        onClick={() => handleConfirmAnswer()}
                    >
                        Подтвердить <span className="opacity-50">(ПРОБЕЛ)</span>
                    </Button>
                </Col>
            )}
            <div className="question__card-buttons-group">
                <Button
                    variant="outline-warning"
                    onClick={() => handlePreviousQuestion()}
                    className="w-100"
                    disabled={currentQuestionIndex === 0}
                >
                    К предыдущему вопросу
                </Button>
                <Button
                    variant="outline-warning"
                    onClick={() =>
                        setTicketsQuestions(null)
                    } /*!todo При выходе к перечню должно скроллится к выбранному вопросу*/
                    className="w-100"
                >
                    К перечню <span className="opacity-50">(ESC)</span>
                </Button>
                <Button
                    variant="outline-warning"
                    onClick={() => handleNextQuestion()}
                    className="w-100"
                    disabled={currentQuestionIndex === 19}
                >
                    К следующему вопросу
                </Button>
            </div>
        </div>
    )
}
export default ExamQuestionCard
