import { Col, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const TicketsCardLayoutSkeleton = () => {
    return (
        <>
            <div className="col-2 mb-2">
                <Skeleton height={20} />
            </div>
            <div className="card">
                <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xxl-5 mb-4 px-2">
                    <Skeleton height={30} />
                </div>
                <Row>
                    <div className="px-2 col-lg-3 col-md-3 col-sm-3 col-6">
                        <Skeleton count={10} height={38} className="mb-3" />
                    </div>
                    <Col className="px-2 col-lg-3 col-md-3 col-sm-3 col-6">
                        <Skeleton count={10} height={38} className="mb-3" />
                    </Col>
                    <Col className="px-2 col-lg-3 col-md-3 col-sm-3 col-6">
                        <Skeleton count={10} height={38} className="mb-3" />
                    </Col>
                    <Col className="px-2 col-lg-3 col-md-3 col-sm-3 col-6">
                        <Skeleton count={10} height={38} className="mb-3" />
                    </Col>
                </Row>
                <Row>
                    <div className="col-lg-4 text-center">
                        <Skeleton className="w-75" height={38} />
                    </div>
                    <div className="col-lg-4 text-center">
                        <Skeleton className="w-75" height={38} />
                    </div>
                    <div className="col-lg-4 text-center">
                        <Skeleton className="w-75" height={38} />
                    </div>
                </Row>
            </div>
        </>
    )
}

export default TicketsCardLayoutSkeleton
