import { Link } from 'react-router-dom'
import liderLogo from '@/assets/img/logo.svg'
import googlePlayBadge from '@/assets/img/google-play-badge.svg'
import appleStoreBadge from '@/assets/img/app-store-badge.svg'
import ceratexLogo from '@/assets/img/logo_black.svg'
import { Image } from 'react-bootstrap'
import './AuthorizationPage.scss'

const AuthorizationPageLayout = ({ children }) => {
    return (
        <div className="authorization__page">
            <div className="authorization__page-container col-12 col-lg-4">
                <Link to="/login" className="w-100 text-center">
                    <Image
                        src={liderLogo}
                        alt="lider-logo"
                        className="authorization__page-logo"
                    />
                </Link>
                <div className="authorization__page-form">{children}</div>
                <div className="authorization__page-footer">
                    <p className="authorization__page-footer-text">
                        Для стабильной работы приложения может потребоваться
                        установить <br />
                        <Link to="https://www.gosuslugi.ru/crt" target="_blank">
                            {' '}
                            сертификаты НУЦ Минцифры России{' '}
                        </Link>
                        или использовать
                        <Link to="https://browser.yandex.ru/" target="_blank">
                            {' '}
                            Яндекс.Браузер
                        </Link>
                    </p>

                    <div className="authorization__page-footer-apps">
                        <Link to="/login">
                            <img src={googlePlayBadge} alt="googlePlayBadge" />
                        </Link>
                        <Link to="/login" className="mb-4">
                            <img src={appleStoreBadge} alt="appleStoreBadge" />
                        </Link>
                        <Link to="https://ceratex.ru" target="_blank">
                            <img
                                src={ceratexLogo}
                                alt="ceratexLogo"
                                style={{ width: '130px' }}
                            />
                        </Link>
                    </div>
                </div>
            </div>

            <div className="col-8 d-none d-lg-block">
                <div className="authorization__page-background-image" />
            </div>
        </div>
    )
}

export default AuthorizationPageLayout
