import Skeleton from 'react-loading-skeleton'

const SchoolExamsSkeleton = () => {
    return (
        <>
            <div className="page">
                <>
                    <div className="mb-3">
                        <Skeleton width="30%" />
                    </div>
                    <div className="card d-flex px-3 py-2 mb-3">
                        <div>
                            <Skeleton width="10%" />
                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-1">
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="20%" />
                                    <Skeleton width="50%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="40%" />
                                    <Skeleton width="50%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="20%" />
                                    <Skeleton width="10%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="35%" />
                                    <Skeleton width="10%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton height={28} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card d-flex px-3 py-2 mb-3">
                        <div>
                            <Skeleton width="10%" />
                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-1">
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="20%" />
                                    <Skeleton width="50%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="40%" />
                                    <Skeleton width="50%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="20%" />
                                    <Skeleton width="10%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="35%" />
                                    <Skeleton width="10%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton height={28} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card d-flex px-3 py-2 mb-3">
                        <div>
                            <Skeleton width="10%" />
                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-1">
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="20%" />
                                    <Skeleton width="50%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="40%" />
                                    <Skeleton width="50%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="20%" />
                                    <Skeleton width="10%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="35%" />
                                    <Skeleton width="10%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton height={28} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card d-flex px-3 py-2 mb-3">
                        <div>
                            <Skeleton width="10%" />
                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-1">
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="20%" />
                                    <Skeleton width="50%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="40%" />
                                    <Skeleton width="50%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="20%" />
                                    <Skeleton width="10%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="35%" />
                                    <Skeleton width="10%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton height={28} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card d-flex px-3 py-2 mb-3">
                        <div>
                            <Skeleton width="10%" />
                        </div>
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-1">
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="20%" />
                                    <Skeleton width="50%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="40%" />
                                    <Skeleton width="50%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="20%" />
                                    <Skeleton width="10%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton width="35%" />
                                    <Skeleton width="10%" />
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div className="flex-grow-1">
                                    <Skeleton height={28} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </>
    )
}

export default SchoolExamsSkeleton
