import api from '@/api/api.ts'
import { useVideoListStore } from '@/store/VideoListStore.ts'
import { toast } from 'react-toastify'

export const postCompleteVideo = async () => {
    const { currentVideo } = useVideoListStore.getState()
    try {
        const postResponse = await api.post('/video/complete', {
            video_id: currentVideo?.id,
        })
        toast(postResponse.data.message, { type: 'success' })
    } catch (e) {
        return null
    }
}

export const updateTime = async (currentTime: number, duration: number) => {
    const { currentVideo } = useVideoListStore.getState()
    try {
        const postResponse = await api.post('/video/duration', {
            video_id: currentVideo?.id,
            time: Math.round(currentTime * 1000),
            duration: Math.round(duration * 1000),
        })
    } catch (e) {
        return null
    }
}
