import { create } from 'zustand'

interface ITimerStore {
    // time: string
    // setTime: (arg: string) => void
    minutes: number
    setMinutes: (arg: number) => void
    seconds: number
    setSeconds: (arg: number) => void
    isPaused: boolean
    setIsPaused: (arg: boolean) => void
}

export const useTimerStore = create<ITimerStore>()((set) => ({
    // time: '00:00',
    // setTime: (time) => set({ time }),
    minutes: 0,
    setMinutes: (minutes) => set({ minutes }),
    seconds: 0,
    setSeconds: (seconds) => set({ seconds }),
    isPaused: false,
    setIsPaused: (isPaused) => set({ isPaused }),
}))
