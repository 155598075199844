import { create } from 'zustand'
import { ITickets, ITheme } from '@/types/ITickets.ts'

interface TicketsTypeStore {
    Tickets: ITickets | null
    setTickets: (arg: ITickets) => void
    activeTicketsType: string
    setActiveTicketsType: (arg: string) => void
    ThemeTickets: ITheme | null
    setThemeTickets: (arg: ITheme) => void
    reset: () => void
}

export const useTicketsTypeStore = create<TicketsTypeStore>()((set) => ({
    Tickets: null,
    setTickets: (Tickets) => set({ Tickets }),
    activeTicketsType: 'ABM',
    setActiveTicketsType: (activeTicketsType) => set({ activeTicketsType }),
    ThemeTickets: null,
    setThemeTickets: (ThemeTickets) => set({ ThemeTickets }),
    reset: () => {
        set({
            activeTicketsType: 'ABM',
        })
    },
}))
