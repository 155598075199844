import api from '@/api/api.ts'
import { useActiveTabStore } from '@/store/ActiveTabStore.ts'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { ICategory } from '@/types/ICategory.ts'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

interface ISchoolExamResultsData {
    exams: [
        {
            date: string
            errors: Array<{ id: number; name: string; points: number }>
            instructor: { name: string }
            points: number
            route: number
            status: boolean
        },
    ]
}

const SchoolExamCityDrivesResultsModalBody = () => {
    const { activeTab } = useActiveTabStore()
    const [openExams, setOpenExams] = useState([])

    const {
        data: examResults,
        isLoading,
    }: UseQueryResult<ISchoolExamResultsData> = useQuery({
        queryKey: ['schoolExamResults', 'sliced', activeTab],
        queryFn: async () => {
            return api
                .get(`/school_exam/category/${activeTab}`)
                .then((res) => res.data)
        },
        enabled: !!activeTab,
    })

    const { data: category }: UseQueryResult<ICategory> = useQuery({
        queryKey: ['user', 'category', activeTab],
        enabled: !!examResults && !!activeTab,
    })

    const toggleErrorsTable = (exam: object) => {
        const isOpen = openExams.includes(exam)
        if (isOpen) {
            setOpenExams(openExams.filter((item) => item !== exam))
        } else {
            setOpenExams([...openExams, exam])
        }
    }

    if (isLoading) {
        return (
            <div>
                <div className="p-3">
                    <Skeleton width={200} />
                </div>
                <div className="border-top border-bottom border-1 p-3 mb-3 col-12">
                    <Skeleton width={80} className="mb-2" />
                    <div className="d-md-flex flex-column d-block flex-md-row align-items-md-center ">
                        <div className="flex-grow-1 col-6 col-md-2 pe-2">
                            <Skeleton width="25%" />
                            <Skeleton width="70%" />
                        </div>
                        <div className="flex-grow-1 col-12 col-md-2 pe-2">
                            <Skeleton width="60%" />
                            <Skeleton width="75%" />
                        </div>
                        <div className="flex-grow-1 col-12 col-md-2 pe-2">
                            <Skeleton width="40%" />
                            <Skeleton width="20%" />
                        </div>
                        <div className="flex-grow-1 col-12 col-md-2 pe-2">
                            <Skeleton width="50%" />
                            <Skeleton width="20%" />
                        </div>
                        <div className="flex-grow-1 col-12 col-md-2">
                            <Skeleton width="100%" height={22} />
                        </div>
                    </div>
                </div>
                <div className="w-100 p-3 pt-0">
                    <Skeleton height={24} containerClassName="w-100" />
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="d-flex align-items-center gap-1 mx-3 my-3">
                <p className="fs-lg-16 fs-md-14 fs-sm-12 fw-bold ">
                    Внутренние экзамены. {category?.name}.
                </p>
            </div>
            {!examResults?.exams?.length ? (
                <div className="pb-3 px-3 fs-lg-14 fs-md-12 fs-sm-11">
                    Вы не сдавали внутренний экзамен для данной категории
                </div>
            ) : (
                examResults?.exams.slice(0, 3).map((examResult, index) => (
                    <div
                        className="fs-lg-12 fs-md-10 fs-sm-9 p-3 border-1 border-top"
                        key={index}
                    >
                        <p className="fw-bold mb-2">
                            Маршрут № {examResult?.route}
                        </p>
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div>Дата</div>
                                <div className="fw-bold">
                                    {format(examResult?.date, 'Pp', {
                                        locale: ru,
                                    })}
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div>Экзаменатор</div>
                                <div className="fw-bold">
                                    {examResult?.instructor.name}
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div>Баллы</div>
                                <div className="fw-bold">
                                    {examResult?.points}
                                </div>
                            </div>
                            <div className="d-flex gap-1 d-md-block w-100">
                                <div>Результат</div>
                                <div>
                                    {examResult?.points >= 5 ? (
                                        <FontAwesomeIcon
                                            icon={['fas', 'xmark']}
                                            color="red"
                                            size="lg"
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={['fas', 'check']}
                                            color="green"
                                            size="lg"
                                        />
                                    )}
                                </div>
                            </div>
                            <button
                                className={`btn w-100 fs-lg-14 fs-md-12 fs-sm-11 py-1 px-0 collapsed ${
                                    !examResult.errors.length
                                        ? 'btn-outline-success'
                                        : 'btn-outline-warning'
                                }`}
                                onClick={() => toggleErrorsTable(examResult)}
                                aria-controls={`test-${index}`}
                                aria-expanded={openExams?.includes(examResult)}
                                style={{
                                    pointerEvents: !examResult.errors.length
                                        ? 'none'
                                        : 'auto',
                                }}
                            >
                                {!examResult.errors.length
                                    ? 'Нет ошибок'
                                    : 'Ошибки'}
                            </button>
                        </div>
                        <Collapse in={openExams?.includes(examResult)}>
                            <div id={`test-${index}`}>
                                <table
                                    className="table table-sm table-striped table-bordered mt-2"
                                    style={{ fontSize: '11px' }}
                                >
                                    <thead>
                                        <tr>
                                            <th>
                                                Ошибки и нарушения, допущенные в
                                                процессе экзамена
                                            </th>
                                            <th>Баллы</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {examResult?.errors.map(
                                            (error, errorIndex) => (
                                                <tr key={errorIndex}>
                                                    <td>{error?.name}</td>
                                                    <td>{error?.points}</td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Collapse>
                    </div>
                ))
            )}
            <div className="p-3">
                <Link
                    to="/exams"
                    className="btn btn-outline-warning w-100 fs-lg-14 fs-md-12 fs-sm-11 py-2"
                >
                    Все экзамены
                </Link>
            </div>
        </>
    )
}

export default SchoolExamCityDrivesResultsModalBody
