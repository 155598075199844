import Skeleton from 'react-loading-skeleton'

const VideoLessonsSkeleton = () => {
    return (
        <div className="page">
            <>
                <div className="fw-bold col-5 col-sm-3 col-md-2 col-xl-2">
                    <Skeleton height={20} />
                </div>

                <div className="bg-white px-0 py-3 mt-3 d-flex align-content-center shadow-sm w-100">
                    <Skeleton
                        width={24}
                        height={24}
                        className="mx-3"
                        circle
                        enableAnimation={false}
                        containerClassName="d-flex align-items-center"
                    />
                    <div style={{ flex: '1', paddingRight: '16px' }}>
                        <div className="col-10 col-sm-5 col-md-6 col-lg-5 col-xl-4">
                            <Skeleton />
                        </div>
                        <div className="col-7 col-sm-4 col-md-5 col-lg-4 col-xl-3">
                            <Skeleton />
                        </div>
                    </div>
                </div>
                <div className="bg-white px-0 py-3 mt-3 d-flex align-content-center shadow-sm w-100">
                    <Skeleton
                        width={24}
                        height={24}
                        className="mx-3"
                        circle
                        enableAnimation={false}
                        containerClassName="d-flex align-items-center"
                    />
                    <div style={{ flex: '1', paddingRight: '16px' }}>
                        <div className="col-10 col-sm-5 col-md-6 col-lg-5 col-xl-4">
                            <Skeleton />
                        </div>
                        <div className="col-7 col-sm-4 col-md-5 col-lg-4 col-xl-3">
                            <Skeleton />
                        </div>
                    </div>
                </div>
                <div className="bg-white px-0 py-3 mt-3 d-flex align-content-center shadow-sm w-100">
                    <Skeleton
                        width={24}
                        height={24}
                        className="mx-3"
                        circle
                        enableAnimation={false}
                        containerClassName="d-flex align-items-center"
                    />
                    <div style={{ flex: '1', paddingRight: '16px' }}>
                        <div className="col-10 col-sm-5 col-md-6 col-lg-5 col-xl-4">
                            <Skeleton />
                        </div>
                        <div className="col-7 col-sm-4 col-md-5 col-lg-4 col-xl-3">
                            <Skeleton />
                        </div>
                    </div>
                </div>
                <div className="bg-white px-0 py-3 mt-3 d-flex align-content-center shadow-sm w-100">
                    <Skeleton
                        width={24}
                        height={24}
                        className="mx-3"
                        circle
                        enableAnimation={false}
                        containerClassName="d-flex align-items-center"
                    />
                    <div style={{ flex: '1', paddingRight: '16px' }}>
                        <div className="col-10 col-sm-5 col-md-6 col-lg-5 col-xl-4">
                            <Skeleton />
                        </div>
                        <div className="col-7 col-sm-4 col-md-5 col-lg-4 col-xl-3">
                            <Skeleton />
                        </div>
                    </div>
                </div>
                <div className="bg-white px-0 py-3 mt-3 d-flex align-content-center shadow-sm w-100">
                    <Skeleton
                        width={24}
                        height={24}
                        className="mx-3"
                        circle
                        enableAnimation={false}
                        containerClassName="d-flex align-items-center"
                    />
                    <div style={{ flex: '1', paddingRight: '16px' }}>
                        <div className="col-10 col-sm-5 col-md-6 col-lg-5 col-xl-4">
                            <Skeleton />
                        </div>
                        <div className="col-7 col-sm-4 col-md-5 col-lg-4 col-xl-3">
                            <Skeleton />
                        </div>
                    </div>
                </div>
                <div className="bg-white px-0 py-3 mt-3 d-flex align-content-center shadow-sm w-100">
                    <Skeleton
                        width={24}
                        height={24}
                        className="mx-3"
                        circle
                        enableAnimation={false}
                        containerClassName="d-flex align-items-center"
                    />
                    <div style={{ flex: '1', paddingRight: '16px' }}>
                        <div className="col-10 col-sm-5 col-md-6 col-lg-5 col-xl-4">
                            <Skeleton />
                        </div>
                        <div className="col-7 col-sm-4 col-md-5 col-lg-4 col-xl-3">
                            <Skeleton />
                        </div>
                    </div>
                </div>
                <div className="bg-white px-0 py-3 mt-3 d-flex align-content-center shadow-sm w-100">
                    <Skeleton
                        width={24}
                        height={24}
                        className="mx-3"
                        circle
                        enableAnimation={false}
                        containerClassName="d-flex align-items-center"
                    />
                    <div style={{ flex: '1', paddingRight: '16px' }}>
                        <div className="col-10 col-sm-5 col-md-6 col-lg-5 col-xl-4">
                            <Skeleton />
                        </div>
                        <div className="col-7 col-sm-4 col-md-5 col-lg-4 col-xl-3">
                            <Skeleton />
                        </div>
                    </div>
                </div>
                <div className="bg-white px-0 py-3 mt-3 d-flex align-content-center shadow-sm w-100">
                    <Skeleton
                        width={24}
                        height={24}
                        className="mx-3"
                        circle
                        enableAnimation={false}
                        containerClassName="d-flex align-items-center"
                    />
                    <div style={{ flex: '1', paddingRight: '16px' }}>
                        <div className="col-10 col-sm-5 col-md-6 col-lg-5 col-xl-4">
                            <Skeleton />
                        </div>
                        <div className="col-7 col-sm-4 col-md-5 col-lg-4 col-xl-3">
                            <Skeleton />
                        </div>
                    </div>
                </div>
                <div className="bg-white px-0 py-3 mt-3 d-flex align-content-center shadow-sm w-100">
                    <Skeleton
                        width={24}
                        height={24}
                        className="mx-3"
                        circle
                        enableAnimation={false}
                        containerClassName="d-flex align-items-center"
                    />
                    <div style={{ flex: '1', paddingRight: '16px' }}>
                        <div className="col-10 col-sm-5 col-md-6 col-lg-5 col-xl-4">
                            <Skeleton />
                        </div>
                        <div className="col-7 col-sm-4 col-md-5 col-lg-4 col-xl-3">
                            <Skeleton />
                        </div>
                    </div>
                </div>
                <div className="bg-white px-0 py-3 mt-3 d-flex align-content-center shadow-sm w-100">
                    <Skeleton
                        width={24}
                        height={24}
                        className="mx-3"
                        circle
                        enableAnimation={false}
                        containerClassName="d-flex align-items-center"
                    />
                    <div style={{ flex: '1', paddingRight: '16px' }}>
                        <div className="col-10 col-sm-5 col-md-6 col-lg-5 col-xl-4">
                            <Skeleton />
                        </div>
                        <div className="col-7 col-sm-4 col-md-5 col-lg-4 col-xl-3">
                            <Skeleton />
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

export default VideoLessonsSkeleton
