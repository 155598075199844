import { create } from 'zustand'
import { IUser } from '@/types/IUser.ts'
import { persist } from 'zustand/middleware'

interface UserDataStore {
    user: IUser | null
    setUser: (arg: IUser) => void
}

export const useUserDataStore = create<UserDataStore>()(
    persist(
        (set) => ({
            user: null,
            setUser: (user) => set({ user }),
        }),
        {
            name: 'user',
        },
    ),
)
