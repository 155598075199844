import { useRestoreAccountStore } from '@/store/RestoreAccountStore.ts'

export default function RestoreCode() {
    const { setRestoreCode, restoreCode } = useRestoreAccountStore()

    return (
        <form
            id="restore-code"
            autoComplete="off"
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                }
            }}
        >
            <label
                htmlFor="user-code"
                className="authorization__form-group-label"
            >
                Введите полученный код
            </label>
            <input
                className="authorization__form-group-input form-control text-uppercase"
                type="text"
                id="user-code"
                autoComplete="off"
                maxLength={6}
                defaultValue={restoreCode}
                placeholder="XXXXXX"
                inputMode="numeric"
                //!todo+ Просто onChange={handleChange}, либо просто inline
                onChange={(event) => {
                    setRestoreCode(event.target.value.replace(/\D/g, ''))
                }}
            />
        </form>
    )
}
