import Skeleton from 'react-loading-skeleton'

const SchoolExamsModalSkeleton = () => {
    return (
        <>
            <Skeleton width={220} className="mb-4" />
            <div className="mb-3">
                <Skeleton width={230} />
                <div className="lh-sm">
                    <Skeleton width={190} />
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="mb-3">
                <Skeleton width={230} />
                <div className="lh-sm">
                    <Skeleton width={190} />
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="mb-3">
                <Skeleton width={230} />
                <div className="lh-sm">
                    <Skeleton width={190} />
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="mb-3">
                <Skeleton width={230} />
                <div className="lh-sm">
                    <Skeleton width={190} />
                    <Skeleton count={3} />
                </div>
            </div>
        </>
    )
}

export default SchoolExamsModalSkeleton
