import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LightBox from '@/Pages/InstructorsPage/InstructorProfile/Lightbox.tsx'
import { ICurrentInstructorCar } from '@/types/IInstructors.ts'

interface Props {
    car: ICurrentInstructorCar
}

function InstructorPhotoSlider({ car }: Props) {
    const [showLightbox, setShowLightbox] = useState(false)
    const slides = car.images.map((image) => ({ src: image.full }))

    return (
        <>
            <div className="instructor__profile-cars-card card">
                <img
                    src={
                        car.images[0].small ||
                        'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg'
                    }
                    alt="instructor-avatar"
                    className="card-img-top instructor__profile-cars-card-header"
                    onClick={() => setShowLightbox(true)}
                />
                <div className="instructor__profile-cars-card-footer card-footer">
                    <div className="instructor__profile-cars-card-footer-car">
                        <FontAwesomeIcon icon={['fas', 'car']} />
                        <p className="instructor__profile-cars-card-footer-car-mark">
                            {car.mark} {car.model}
                        </p>
                        <span className="instructor__profile-cars-card-footer-car-number">
                            {car.number}
                        </span>
                    </div>
                    <p className="instructor__profile-cars-card-footer-age">
                        <span className="fw-medium">Год выпуска: </span>
                        {car.year}
                    </p>
                    <p className="instructor__profile-cars-card-footer-transmission">
                        <span className="fw-medium">КПП: </span>
                        {car.kpp_type}
                    </p>
                </div>
            </div>
            <LightBox
                setShowLightbox={setShowLightbox}
                showLightbox={showLightbox}
                slides={slides}
            />
        </>
    )
}

export default InstructorPhotoSlider
