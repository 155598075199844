import { create } from 'zustand'
import { IQuestion, ISavedQuestions, ITickets } from '@/types/ITickets.ts'
import { IMarathonStatistics } from '@/types/IStatistics.ts'

interface MarathonStore {
    tickets: IQuestion[]
    setTickets: (arg: IQuestion[]) => void
    currentTicketIndex: number
    setCurrentTicketIndex: (arg: number) => void
    currentTicket: IQuestion
    setCurrentTicket: (arg: IQuestion) => void
    selectedAnswers: string[]
    setSelectedAnswers: (arg: string[]) => void
    answeredIndexes: number[]
    setAnsweredIndexes: (arg: number[]) => void
    isAnswerConfirmed: boolean
    setIsAnswerConfirmed: (arg: boolean) => void
    isAnswerSelected: boolean
    setIsAnswerSelected: (arg: boolean) => void
    showComment: boolean
    setShowComment: (arg: boolean) => void
    savedQuestions: ISavedQuestions[]
    setSavedQuestions: (arg: ISavedQuestions[]) => void
    showMarathonEndModal: boolean
    setShowMarathonEndModal: (arg: boolean) => void
    questionsAndAnswers: object
    setQuestionsAndAnswers: (arg: object) => void
    // time: string
    // setTime: (state: MarathonStore | ((state: MarathonStore) => void)) => void
    // setTime: (arg: string) => void
    // answersCount: number
    // setAnswersCount: (arg: number) => void
    marathonStatistic: IMarathonStatistics[]
    setMarathonStatistic: (arg: IMarathonStatistics[]) => void
    marathonSingleStatistic: IMarathonStatistics
    setMarathonSingleStatistic: (arg: IMarathonStatistics) => void
    showMarathonStatistic: boolean
    setShowMarathonStatistic: (arg: boolean) => void
    showMarathonSettings: boolean
    setShowMarathonSettings: (arg: boolean) => void
    doubleClickMode: boolean
    setDoubleClickMode: (arg: boolean) => void
    automaticTransitionMode: boolean
    setAutomaticTransitionMode: (arg: boolean) => void
    savedTime: string
    setSavedTime: (arg: string) => void
    reset: () => void
}

export const useMarathonStore = create<MarathonStore>((set) => ({
    tickets: null,
    setTickets: (tickets) => set({ tickets }),
    currentTicketIndex: 0,
    setCurrentTicketIndex: (currentTicketIndex) => set({ currentTicketIndex }),
    currentTicket: null,
    setCurrentTicket: (currentTicket) => set({ currentTicket }),
    selectedAnswers: [],
    setSelectedAnswers: (selectedAnswers) => set({ selectedAnswers }),
    answeredIndexes: [],
    setAnsweredIndexes: (answeredIndexes) => set({ answeredIndexes }),
    isAnswerConfirmed: false,
    setIsAnswerConfirmed: (isAnswerConfirmed) => set({ isAnswerConfirmed }),
    isAnswerSelected: false,
    setIsAnswerSelected: (isAnswerSelected) => set({ isAnswerSelected }),
    showComment: false,
    setShowComment: (showComment) => set({ showComment }),
    savedQuestions: null,
    setSavedQuestions: (savedQuestions) => set({ savedQuestions }),
    showMarathonEndModal: false,
    setShowMarathonEndModal: (showMarathonEndModal) =>
        set({ showMarathonEndModal }),
    questionsAndAnswers: {
        id: null,
        numQuestion: null,
        numTicket: null,
        userBoolAnswer: '',
        userNumAnswer: '',
    },
    setQuestionsAndAnswers: (updates) =>
        set((state) => ({
            questionsAndAnswers: { ...state.questionsAndAnswers, ...updates },
        })),
    // time: '00:00',
    // setTime: (time) => set(time instanceof Function ? time : { time }),
    // setTime: (time) => set({ time }),
    // answersCount: null,
    // setAnswersCount: (answersCount) => set({ answersCount }),
    marathonStatistic: null,
    setMarathonStatistic: (marathonStatistic) => set({ marathonStatistic }),
    marathonSingleStatistic: null,
    setMarathonSingleStatistic: (marathonSingleStatistic) =>
        set({ marathonSingleStatistic }),
    showMarathonStatistic: false,
    setShowMarathonStatistic: (showMarathonStatistic) =>
        set({ showMarathonStatistic }),
    showMarathonSettings: false,
    setShowMarathonSettings: (showMarathonSettings) =>
        set({ showMarathonSettings }),
    doubleClickMode: false,
    setDoubleClickMode: (doubleClickMode) => set({ doubleClickMode }),
    automaticTransitionMode: false,
    setAutomaticTransitionMode: (automaticTransitionMode) =>
        set({ automaticTransitionMode }),
    savedTime: '',
    setSavedTime: (savedTime) => set({ savedTime }),
    reset: () => {
        set({
            tickets: null,
            currentTicketIndex: 0,
            currentTicket: null,
            selectedAnswers: [],
            answeredIndexes: [],
            isAnswerConfirmed: false,
            isAnswerSelected: false,
            showComment: false,
            savedQuestions: null,
            questionsAndAnswers: {
                id: null,
                numTicket: null,
                numQuestion: null,
                userNumAnswer: null,
                userBoolAnswer: null,
            },
            // time: '',
            // answersCount: null,
            showMarathonStatistic: false,
            marathonStatistic: null,
            savedTime: '',
        })
    },
}))
