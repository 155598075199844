import { useProfileCountdownStore } from '@/Pages/MainPage/Header/ProfileEmailResendCountdownStore.ts'
import { useAuthUserStore } from '@/store/AuthUserStore.ts'
import { useAuthTokenStore } from '@/store/AuthTokenStore.ts'
import { useUserDataStore } from '@/store/UserDataStore.ts'
import { useEffect, useState } from 'react'
import { customToast } from '@/lib/CustomToast.ts'
import { Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProfileCountdownTimer } from '@/lib/ProfileCountDownTimer.tsx'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import UserProfileModalSkeleton from '@/Pages/MainPage/Header/UserProfileModalSkeleton.tsx'
import api from '@/api/api.ts'
import { AxiosError } from 'axios'
import {
    Controller,
    FieldErrors,
    SubmitHandler,
    useForm,
} from 'react-hook-form'
import { useUserQuery } from '@/lib/useUserQuery.ts'

export const UserInformation = () => {
    const { resetUserData } = useAuthUserStore()
    const { setUser } = useUserDataStore()
    const { resetToken } = useAuthTokenStore()
    const { setInitialDate } = useProfileCountdownStore()
    const [isEmailEditable, setIsEmailEditable] = useState(false)
    const [isCodeSent, setIsCodeSent] = useState(false)
    const [showResend, setShowResend] = useState(false)
    const user = useUserQuery({ staleTime: 0 })

    const { getValues, handleSubmit, control, setValue, register } = useForm({
        defaultValues: {
            email: user?.email || '',
            code: '',
        },
    })

    const queryClient = useQueryClient()

    const logout = () => {
        resetToken()
        resetUserData()
    }

    const handleValidationError = (errors: FieldErrors<any>) => {
        for (const [field, error] of Object.entries(errors)) {
            customToast({
                message: (error.message ?? error) as string,
                type: 'error',
            })
        }
    }

    const handleChangeUserEmail = useMutation<void, AxiosError<any>>({
        mutationFn() {
            const email = getValues('email')
            return api.post('/user/email', { email })
        },
        onSuccess() {
            const email = getValues('email')
            setUser({
                ...user,
                email,
            })
            setIsEmailEditable(false)
        },
        onError(error) {
            customToast({
                message: error.response.data.message,
                type: 'error',
            })
        },
    })

    const handleChangeEmail = async () => {
        const emailValue = getValues('email')

        if (user.email === emailValue) {
            setIsEmailEditable(false)
        } else {
            handleChangeUserEmail.mutate()
        }
    }
    const emailIsSending = handleChangeUserEmail.isPending

    const handleConfirmCode = useMutation<void, AxiosError<any>>({
        mutationFn() {
            const code = getValues('code')
            return api.post('/user/email/confirm', { code })
        },
        onSuccess() {
            const email = getValues('email')
            setUser({
                ...user,
                email,
                email_confirm: true,
            })
            customToast({
                message: 'Вы успешно подтвердили почту',
                type: 'success',
            })
            queryClient.invalidateQueries({
                queryKey: ['user'],
            })
        },
        onError(error) {
            customToast({
                message: error.response.data.message,
                type: 'error',
            })
        },
    })

    const resendEmailChangeCode = useMutation<void, AxiosError<any>>({
        mutationFn() {
            setIsCodeSent(true)
            setShowResend(false)
            return api.post('/user/email/resend')
        },
        onSuccess() {
            setInitialDate(new Date())
        },
        onError(error) {
            customToast({
                message: error.response.data.message,
                type: 'error',
            })
        },
    })

    const stopTimer = () => {
        setIsCodeSent(false)
        setShowResend(true)
    }

    useEffect(() => {
        if (!user?.email_confirm) {
            setShowResend(true)
        }
        if (!user?.email) {
            setIsEmailEditable(true)
        }
    }, [user])

    return (
        <>
            {!user && <UserProfileModalSkeleton />}
            {user && (
                <div className="profile__modal">
                    <Image src={user?.photo} className="profile__modal-image" />
                    <h1 className="mb-3">{user?.name}</h1>
                    <p className="profile__modal-field">
                        <FontAwesomeIcon
                            icon={['fal', 'phone-alt']}
                            color="#000000"
                        />
                        &nbsp;{user?.phone}&nbsp;
                        <FontAwesomeIcon
                            icon={['fas', 'check']}
                            color="#009402"
                        />
                    </p>
                    {user.email && user.email_confirm && (
                        <p className="profile__modal-field mb-5">
                            <FontAwesomeIcon
                                icon={['fal', 'envelope']}
                                color="#000000"
                            />
                            &nbsp;{user?.email}&nbsp;
                            <FontAwesomeIcon
                                icon={['fas', 'check']}
                                color="#009402"
                            />
                        </p>
                    )}
                    {(!user.email || !user.email_confirm) && (
                        <>
                            <form
                                id="change-email-form"
                                autoComplete="off"
                                className="w-100"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault()
                                    }
                                }}
                            >
                                <div className="form-group position-relative mb-0">
                                    <label
                                        htmlFor="login-phone"
                                        className="mb-1 fw-semibold text-body-secondary"
                                    >
                                        E-mail
                                    </label>
                                    <input
                                        {...register('email', {
                                            required: true,
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message:
                                                    'Введите корректный адрес электронной почты',
                                            },
                                        })}
                                        className="user-email-input w-100 position-relative"
                                        type="email"
                                        placeholder="Введите адрес почты"
                                        disabled={!isEmailEditable}
                                    />
                                    {!isEmailEditable && !emailIsSending && (
                                        <FontAwesomeIcon
                                            icon={['fas', 'xmark']}
                                            color="red"
                                            className="absolute-icon cursor-pointer"
                                            onClick={() =>
                                                setIsEmailEditable(true)
                                            }
                                        />
                                    )}
                                    {isEmailEditable && !emailIsSending && (
                                        <FontAwesomeIcon
                                            icon={['fal', 'check-circle']}
                                            className="absolute-icon cursor-pointer"
                                            color="green"
                                            onClick={handleSubmit(
                                                handleChangeEmail,
                                                handleValidationError,
                                            )}
                                        />
                                    )}
                                    {emailIsSending && (
                                        <FontAwesomeIcon
                                            icon={['fas', 'spinner-third']}
                                            className="absolute-icon mt-2"
                                            color="black"
                                            spin
                                        />
                                    )}
                                </div>
                                {(isCodeSent ||
                                    (user.email && !user.email_confirm)) && (
                                    <p className="fs-lg-12 fs-md-10 mt-1">
                                        На вашу почту был отправлен код. Введите
                                        его ниже для подтверждения
                                    </p>
                                )}
                            </form>
                            {(isCodeSent ||
                                (user.email && !user.email_confirm)) && (
                                <form
                                    autoComplete="off"
                                    className="w-100"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault()
                                        }
                                    }}
                                >
                                    <div className="form-group position-relative mb-1">
                                        <label
                                            htmlFor="login-code"
                                            className="mb-1 fw-semibold fs-lg-12 fs-md-10 text-body-secondary"
                                        >
                                            Код
                                        </label>
                                        <Controller
                                            name="code"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    className="user-email-input w-100 position-relative"
                                                    type="text"
                                                    inputMode="numeric"
                                                    placeholder="Введите код"
                                                    maxLength={6}
                                                    onChange={(e) => {
                                                        field.onChange(
                                                            e.target.value.replace(
                                                                /[^0-9]/g,
                                                                '',
                                                            ),
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                        {!handleConfirmCode.isPending && (
                                            <FontAwesomeIcon
                                                icon={['fal', 'check-circle']}
                                                className="absolute-icon cursor-pointer"
                                                color="green"
                                                onClick={handleSubmit(
                                                    handleConfirmCode.mutate as SubmitHandler<any>,
                                                    handleValidationError,
                                                )}
                                            />
                                        )}
                                        {handleConfirmCode.isPending && (
                                            <FontAwesomeIcon
                                                icon={['fas', 'spinner-third']}
                                                className="absolute-icon mt-2"
                                                color="black"
                                                spin
                                            />
                                        )}
                                    </div>
                                    <div className="fs-lg-12 fs-md-10 text-primary">
                                        {!showResend || isCodeSent ? (
                                            <p className="d-flex flex-wrap flex-row">
                                                <span>
                                                    Запросить новый код можно
                                                    через&nbsp;
                                                </span>

                                                <ProfileCountdownTimer
                                                    onTimerEnd={stopTimer}
                                                />
                                            </p>
                                        ) : (
                                            <span
                                                className="cursor-pointer"
                                                onClick={() =>
                                                    resendEmailChangeCode.mutate()
                                                }
                                            >
                                                Отправить код снова
                                            </span>
                                        )}
                                    </div>
                                </form>
                            )}
                        </>
                    )}
                    <button
                        className="profile__modal-exit-button btn btn-danger"
                        onClick={() => {
                            logout() /*!todo Если выйти пока запрос /user ещё идёт, при завершении запроса залогинит снова. Думаю можно решить если в logout() вызвать что-то типа queryClient.abortQuery(['user'])*/
                        }}
                    >
                        Выйти из профиля
                    </button>
                </div>
            )}
        </>
    )
}
