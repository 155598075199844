import { ProgressBar, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { ForwardedRef, forwardRef } from 'react'
import { ICategory } from '@/types/ICategory.ts'

interface TheoryCardProps {
    category: ICategory
}

function TheoryBody({ category }: TheoryCardProps) {
    if (typeof category.theory === 'string') {
        return (
            <div className="theory__card card">
                <p>{category.theory}</p>
            </div>
        )
    }
    const theoryPercents = category.theory.tickets
    const videosPercents = category.theory.videos

    const getPercentColor = (value: number) => {
        return value <= 30
            ? 'danger'
            : value > 30 && value < 85
              ? 'warning'
              : 'success'
    }

    return (
        <div className="theory__card card">
            <div className="theory__card-buttons-group">
                <Link
                    to="/lessons"
                    className="theory__card-button btn btn-outline-warning"
                >
                    Видеоуроки
                </Link>
                <Link
                    to="/tickets"
                    className="theory__card-button btn btn-outline-warning"
                >
                    Билеты ПДД
                </Link>
            </div>
            <div className="theory__card-progress-group">
                <h2 className="theory__card-progress-group-title">
                    Знание ПДД
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {/*!todo+ Текст по центру, лучше слева*/}
                                Среднее значение всех ваших попыток решения
                                билетов ПДД <br /> менее 30% - плохо <br />{' '}
                                30-70% - средне <br /> более 85% - отлично
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon
                            icon={['fas', 'info-circle']}
                            color="orange"
                            className="ms-1"
                        />
                    </OverlayTrigger>
                </h2>
                <i>{theoryPercents.description}</i>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{theoryPercents.percent}%</Tooltip>}
                >
                    <ProgressBar
                        now={theoryPercents.percent}
                        variant={getPercentColor(theoryPercents.percent)}
                        className="theory__card-progress-group-bar"
                    />
                </OverlayTrigger>
                <h2 className="theory__card-progress-group-title">
                    Просмотр видеоуроков
                </h2>
                <i>{videosPercents.description}</i>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{videosPercents.percent}%</Tooltip>}
                >
                    <ProgressBar
                        now={videosPercents.percent}
                        variant={getPercentColor(videosPercents.percent)}
                        className="theory__card-progress-group-bar mb-4"
                    />
                </OverlayTrigger>
                {category.theory.lessons && (
                    <h2 className="theory__card-progress-group-title">
                        Лекции
                    </h2>
                )}
                <div className="d-flex flex-wrap align-items-center gap-1">
                    {typeof category.theory !== 'string' &&
                        category.theory.lessons.map((lesson, index) => (
                            <div key={index}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: lesson.message,
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'book']}
                                        size="1x"
                                        color={lesson.color}
                                    />
                                </OverlayTrigger>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export const TheoryCard = forwardRef(
    ({ category }: TheoryCardProps, ref: ForwardedRef<HTMLDivElement>) => {
        return (
            <>
                <div className="learning__step-container" ref={ref}>
                    <div className="learning__step-icon">1</div>
                    <h1>Начнем с теории</h1>
                </div>
                <TheoryBody category={category} />
            </>
        )
    },
)
