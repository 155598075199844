import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { IReviews } from '@/types/IInstructors.ts'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

interface Props {
    reviews: IReviews[]
}

function Reviews({ reviews }: Props) {
    return (
        <>
            <h4
                className="common__title instructor__reviews-title"
                id="reviews"
            >
                <FontAwesomeIcon icon={['fal', 'comment']} />
                Отзывы
                <span className="instructor__reviews-title-badge badge">
                    {reviews.length}
                </span>
            </h4>
            {reviews.map((review, index) => (
                <div className="instructor__reviews-review" key={index}>
                    <blockquote className="instructor__reviews-review-blockquote">
                        <p className="instructor__reviews-review-blockquote-stars">
                            {Array.from({ length: review.mark }, (_, index) => (
                                <FontAwesomeIcon
                                    key={index}
                                    icon={['fas', 'star']}
                                    color="orange"
                                />
                            ))}
                        </p>
                        <p className="instructor__reviews-review-blockquote-text">
                            {review.review}
                        </p>
                        <p className="instructor__reviews-review-blockquote-author">
                            {review.name}
                            {review.link && (
                                <span className="instructor__reviews-review-blockquote-author-link">
                                    <span> - </span>
                                    <Link
                                        className="text-body-danger"
                                        to={review.link}
                                    >
                                        {review.link}
                                    </Link>
                                </span>
                            )}
                        </p>
                        <p className="instructor__reviews-review-blockquote-date">
                            {format(review.date_vote, 'Pp', {
                                locale: ru,
                            })}
                        </p>
                    </blockquote>
                </div>
            ))}
        </>
    )
}

export default Reviews
