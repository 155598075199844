import Skeleton from 'react-loading-skeleton'

const StatisticsSkeleton = () => {
    return (
        <div className="page">
            <div className="mb-3">
                <Skeleton width="25%" />
                <Skeleton width="35%" />
            </div>
            <div className="card mb-5 p-3">
                <Skeleton
                    height={28}
                    width={40}
                    inline={true}
                    count={10}
                    className="mx-1"
                />
            </div>
            <div className="mb-3">
                <Skeleton width="25%" />
                <Skeleton width="35%" />
            </div>
            <div className="card mb-5 p-3 d-flex flex-row flex-wrap">
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)} // Случайная ширина от 100px до 225px /*!todo При ререндере скачут позиции, лучше статичный массив с рандомными значениями (widths = [132, 321, 533, ...]) */
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
                <Skeleton
                    height={30}
                    width={Math.floor(Math.random() * 126 + 100)}
                    className="m-1"
                />
            </div>
            <>
                <div className="mb-1">
                    <Skeleton width={200} />
                </div>
                <div className="mb-3">
                    <Skeleton width={400} />
                </div>
                <div className="card fs-lg-14 fs-md-12 fs-sm-11 p-3">
                    <div className="card-body p-0">
                        <table className="table table-sm table-striped table-bordered mb-0">
                            <colgroup>
                                <col width="7%" />
                                <col width="5%" />
                                <col width="5%" />
                                <col width="78%" />
                                <col width="5%" />
                            </colgroup>
                            <thead className="thead-invert">
                                <tr className="text-center">
                                    <th>
                                        <Skeleton height={30} />
                                    </th>
                                    <th>
                                        <Skeleton height={30} />
                                    </th>
                                    <th>
                                        <Skeleton height={30} />
                                    </th>
                                    <th>
                                        <Skeleton height={30} />
                                    </th>
                                    <th>
                                        <Skeleton height={30} />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="text-center">
                                    <td>
                                        <Skeleton count={10} height={50} />
                                    </td>
                                    <td>
                                        <Skeleton count={10} height={50} />
                                    </td>
                                    <td>
                                        <Skeleton count={10} height={50} />
                                    </td>
                                    <td>
                                        <Skeleton count={10} height={50} />
                                    </td>
                                    <td>
                                        <Skeleton count={10} height={50} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        </div>
    )
}

export default StatisticsSkeleton
