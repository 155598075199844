import './MainPage.scss'
import MainPageHeader from '@/Pages/MainPage/MainPageHeader.tsx'
import MainPageLayout from '@/Pages/MainPage/MainPageLayout.tsx'

export default function MainPage() {
    return (
        <>
            <MainPageHeader />
            <MainPageLayout />
        </>
    )
}
