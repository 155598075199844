import ProfileModal from '@/components/Modals/DefaultModal/DefaultModal.tsx'
import NotificationsModal from '@/components/Modals/DefaultModal/DefaultModal.tsx'
import liderLogo from '@/assets/img/logo.svg'
import NotificationsModalSkeleton from '@/Pages/MainPage/Header/NotificationsModalSkeleton.tsx'
import { UserInformation } from '@/Pages/MainPage/Header/UserProfile.tsx'
import { useAuthUserStore } from '@/store/AuthUserStore.ts'
import { useLoadingStateStore } from '@/store/LoadingStateStore.ts'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import api from '@/api/api.ts'
import { customToast } from '@/lib/CustomToast.ts'
import { Badge, Pagination, Image } from 'react-bootstrap'
import { INotificationsData } from '@/types/IUser.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUserQuery } from '@/lib/useUserQuery.ts'
import './Header.scss'

export default function Header() {
    const { setModalLoading: setIsModalLoading } = useLoadingStateStore()
    const [notificationsModalShow, setNotificationsModalShow] = useState(false)
    const [profileModalShow, setProfileModalShow] = useState(false)
    const [paginationData, setPaginationData] = useState(null)

    const navigate = useNavigate()

    const user = useUserQuery()

    const fetchNotifications = async (page) => {
        const { setNotifications } = useAuthUserStore.getState()
        setNotificationsModalShow(true)
        setIsModalLoading(true)
        await api
            .get('/user/notifications', {
                params: { page: page },
            })
            .then((response) => {
                setNotifications(response.data)
                setPaginationData(response.data.pagination)
            })
            .catch((error) =>
                customToast({
                    message: error.response.data.message,
                    type: 'error',
                }),
            )
            .finally(() => setIsModalLoading(false))
    }

    const items = []
    for (let number = 1; number <= paginationData?.total_pages; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === paginationData?.current_page}
                onClick={() => fetchNotifications(number)}
            >
                {number}
            </Pagination.Item>,
        )
    }

    const Notifications = () => {
        const { notifications } = useAuthUserStore.getState()

        const lastIndex = notifications?.data?.length - 1

        const notificationsList = notifications?.data?.map(
            (notification: INotificationsData, index: number) => {
                const notificationMessage = notification?.message
                const notificationDate = notification?.date
                const isLastElement = index === lastIndex
                const notificationStatus = notification?.is_viewed

                const statusIcon = notificationStatus ? (
                    <></>
                ) : (
                    <FontAwesomeIcon
                        icon={['fas', 'circle']}
                        color="red"
                        height={7}
                    />
                )

                return (
                    <div
                        key={notification.id}
                        className="notifications__body"
                        style={{
                            borderBottom: isLastElement
                                ? 'none'
                                : '1px solid rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        <div className="notifications__body-image">
                            {statusIcon}
                            <Image src={user?.photo} width={45} />
                        </div>
                        <div className="notifications__body-text">
                            <p className="notifications__body-text-message">
                                {notificationMessage}
                            </p>
                            <p className="notifications__body-text-date">
                                {notificationDate}
                            </p>
                        </div>
                    </div>
                )
            },
        )

        return (
            <>
                {notificationsList.length > 0 ? (
                    notificationsList
                ) : (
                    <div className="mt-3 text-center">
                        У вас нет новых уведомлений
                    </div>
                )}
                {notificationsList.length > 0 && (
                    <div className="notifications__pagination">
                        <Pagination className="cursor-pointer m-0">
                            {items}
                        </Pagination>
                    </div>
                )}
            </>
        )
    }

    const { modalLoading } = useLoadingStateStore()
    const { notifications } = useAuthUserStore()
    const notificationTitle = () => {
        return (
            <div className="notification__title">
                <h1 className="notification__title-text">Уведомления</h1>
                <Badge className="notification__title-badge" pill bg="danger">
                    {notifications?.data?.length}
                </Badge>
            </div>
        )
    }
    //NOTIFICATIONS END

    //CONTENT
    return (
        <header className="header">
            <div className="header__brand">
                <FontAwesomeIcon
                    icon={['fal', 'angle-left']}
                    size="2xl"
                    onClick={() => navigate(-1)}
                    className="cursor-pointer"
                />
                <Link to="/">
                    <img
                        alt="lider-logo"
                        src={liderLogo}
                        height="50"
                        className="d-inline-block w-100"
                    />
                </Link>
            </div>

            <div className="header__icons">
                <FontAwesomeIcon
                    icon={['fal', 'bell']}
                    size="2xl"
                    color="black"
                    onClick={() => fetchNotifications(1)}
                    className="cursor-pointer"
                />
                <div className="header__icons-user-image">
                    <Image
                        src="https://онлайн.лидерпро.рф/img/empty-profile.png"
                        alt="avatar"
                        width={37}
                        onClick={() => {
                            setProfileModalShow(true)
                        }}
                    />
                    {!user?.email_confirm && (
                        <span className="header__icons-user-image-notification" />
                    )}
                </div>
            </div>
            <NotificationsModal
                show={notificationsModalShow}
                handleClose={() => setNotificationsModalShow(false)}
                body={
                    modalLoading ? (
                        <NotificationsModalSkeleton />
                    ) : (
                        <Notifications />
                    )
                }
                title={notificationTitle()}
                closeButton={true}
                bodyClassName="m-0"
            />
            <ProfileModal
                show={profileModalShow}
                title={false}
                handleClose={() => setProfileModalShow(false)}
                body={<UserInformation />}
                closeButton={true}
            />
        </header>
    )
}
