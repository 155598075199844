export const customStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        paddingTop: '0px',
        paddingLeft: '5px',
        height: state.isDisabled ? '25px' : '25px',
    }),
    control: (provided, state) => ({
        ...provided,
        border: state.isDisabled ? '1px solid lightgray' : `1px solid #fed321`,
        borderRadius: '5px',
        minHeight: '30px',
        height: '30px',
        maxHeight: '30px',
        borderColor: '#fed321',
        boxShadow: null,
        outline: 'none',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#fed321',
        },
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '30px',
    }),
    indicatorSeparator: () => null,
    clearIndicator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: 'black',
        '&:hover': {
            color: 'black',
        },
    }),
    input: (provided) => ({
        ...provided,
        cursor: 'pointer',
        margin: '1px',
        paddingTop: '0px',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'black',
    }),
    multiValue: (provided) => ({
        ...provided,
        color: 'black',
        background: 'none',
        margin: '0px',
        padding: '0px',
        height: '20px',
        justifyContent: 'space-between',
        alignItems: 'center',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        padding: '0px',
    }),
    multiValueRemove: () => ({
        display: 'none',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        height: '25px',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 10px',
        backgroundColor: state.isFocused ? 'white' : null,
        '&:hover': {
            backgroundColor: '#fed321',
        },
        ...(state.isSelected && {
            position: 'relative',
            backgroundColor: 'white',
            color: 'black',
            '&::before': {
                content: '"\u2714"',
                position: 'absolute',
                top: '50%',
                right: '15px',
                transform: 'translateY(-50%)',
                fontSize: '20px',
                fontWeight: 'bold',
                color: 'black',
            },
        }),
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '5px',
        borderRadius: '0px',
    }),
}
