import './TicketModals.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import DefaultModal from '@/components/Modals/DefaultModal/DefaultModal.tsx'

interface TicketModalEndProps {
    correctAnswersCount: number
    errorAnswersCount: number
    errorsList: (number | string)[]
}

export const TicketsEndModalBody: FC<TicketModalEndProps> = ({
    correctAnswersCount,
    errorAnswersCount,
    errorsList,
}) => {
    const location = useLocation()
    const isThemesTickets = location.pathname.includes('themes')
    const isExamTickets = location.pathname.includes('exam')

    return (
        <>
            {errorAnswersCount === 0 ? (
                <h2 className="tickets-end__modal-title">
                    <FontAwesomeIcon
                        icon={['fal', 'party-horn']}
                        size="lg"
                        color="green"
                    />
                    Молодец, так держать!
                </h2>
            ) : (
                <h2 className="tickets-end__modal-title">
                    <FontAwesomeIcon
                        icon={['fal', 'car-burst']}
                        size="1x"
                        color="red"
                    />
                    Нужно еще немного подготовиться
                </h2>
            )}
            {errorsList?.length > 0 && correctAnswersCount === 0 ? (
                <p className="tickets-end__modal-answers">
                    <FontAwesomeIcon
                        icon={['fal', 'circle-xmark']}
                        size="lg"
                        color="red"
                    />
                    Вы не дали правильных ответов
                </p>
            ) : (
                <p className="tickets-end__modal-answers">
                    <FontAwesomeIcon
                        icon={['fal', 'circle-check']}
                        size="lg"
                        color="green"
                    />
                    Правильных ответов - {correctAnswersCount}
                </p>
            )}
            <>
                {errorsList?.length > 0 ? (
                    <p className="tickets-end__modal-answers">
                        <FontAwesomeIcon
                            icon={['fal', 'circle-xmark']}
                            size="lg"
                            color="red"
                        />
                        Допущено ошибок - {errorAnswersCount}.
                    </p>
                ) : (
                    <p className="tickets-end__modal-answers">
                        <FontAwesomeIcon
                            icon={['fal', 'circle-check']}
                            size="lg"
                            color="green"
                        />
                        Все решено без ошибок
                    </p>
                )}
            </>
            <>
                {!isThemesTickets && !isExamTickets && (
                    <p>
                        Ошибки допущены в вопросах №&nbsp;
                        {errorsList
                            .filter((_, index) => index % 2 === 1)
                            .join(', ')
                            .toString()}
                    </p>
                )}
                {isThemesTickets && (
                    <table className="table m-0">
                        <thead className="border-bottom">
                            <tr>
                                <th>Список ошибок:</th>
                            </tr>
                        </thead>
                        <tbody>
                            {errorsList.map((error, index) => {
                                if (index % 2 === 0) {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center w-50">{`Билет ${error}`}</td>
                                            <td className="text-center w-50">
                                                {`Вопрос ${
                                                    errorsList[index + 1]
                                                }`}
                                            </td>
                                        </tr>
                                    )
                                }
                                return null
                            })}
                        </tbody>
                    </table>
                )}
                {isExamTickets && (
                    <table className="table m-0">
                        <thead className="border-bottom">
                            <tr>
                                <th>Список ошибок:</th>
                            </tr>
                        </thead>
                        <tbody>
                            {errorsList.map((error, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="text-center w-50">{`Билет ${error[0]}`}</td>
                                        <td className="text-center w-50">
                                            {`Вопрос ${error[1]}`}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )}
            </>
        </>
    )
}

export const TimerEndModal = ({ timerEndModalShow, handleClose }) => {
    return (
        <DefaultModal
            show={timerEndModalShow}
            handleClose={handleClose}
            closeButton={true}
            title={
                <div className="timer-end__modal-title">
                    <FontAwesomeIcon
                        icon={['fal', 'hourglass-end']}
                        size="1x"
                        color="red"
                    />
                    <h2 className="text-center">Упс, время вышло...</h2>
                </div>
            }
            body={
                <div className="timer-end__modal-body">
                    <FontAwesomeIcon
                        icon={['fal', 'circle-xmark']}
                        size="lg"
                        color="red"
                    />
                    <p>
                        К сожалению, время вышло, но у вас все получится, если
                        немного подготовитесь.
                    </p>
                </div>
            }
        />
    )
}
