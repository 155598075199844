import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import './Loading.scss'
import { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'

export const LoadingSpinner = ({ isLoading, primaryColor, secondaryColor }) => {
    if (!isLoading) {
        return null
    }

    return (
        <FontAwesomeIcon
            icon={['fad', 'spinner-third']}
            spin
            size="lg"
            style={{
                ['--fa-primary-color' as string]: primaryColor,
                ['--fa-secondary-color' as string]: secondaryColor,
            }}
        />
    )
}

LoadingSpinner.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
}

LoadingSpinner.defaultProps = {
    primaryColor: '#000000',
    secondaryColor: '#ffffff',
}

export const LoadingPageSpinner = ({
    isLoading,
    primaryColor,
    className,
    ...rest
}) => {
    const [displaySpinner, setDisplaySpinner] = useState(isLoading)

    const spinnerStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: isLoading ? 1 : 0 },
        config: { duration: 200 },
        onRest: () => setDisplaySpinner(isLoading),
    })

    const backgroundSpinnerStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: isLoading ? 1 : 0 },
        config: { duration: 100 },
        onRest: () => setDisplaySpinner(isLoading),
    })

    useEffect(() => {
        if (isLoading) {
            setDisplaySpinner(true)
        }
    }, [isLoading])

    if (!displaySpinner) {
        return null
    }

    return (
        <animated.div style={backgroundSpinnerStyles} className={className}>
            <FontAwesomeIcon
                icon={['fal', 'tire']}
                spin
                style={{
                    ['--fa-primary-color' as string]: primaryColor,
                    height: '100px',
                    spinnerStyles,
                }}
            />
        </animated.div>
    )
}

LoadingPageSpinner.propTypes = {
    isLoading: PropTypes.bool,
    primaryColor: PropTypes.string,
}

LoadingPageSpinner.defaultProps = {
    primaryColor: '#000000',
}

export const LoadingModalSpinner = ({ isLoading, primaryColor, ...rest }) => {
    const [modalSpinner, setModalSpinner] = useState(isLoading)

    const spinnerStyles = useSpring({
        from: { opacity: 1 },
        to: { opacity: isLoading ? 1 : 0 },
        config: { duration: 500 },
        onRest: () => setModalSpinner(isLoading),
    })

    const backgroundModalSpinnerStyles = useSpring({
        from: { opacity: 1 },
        to: { opacity: isLoading ? 1 : 0 },
        config: { duration: 200 },
        onRest: () => setModalSpinner(isLoading),
    })

    useEffect(() => {
        if (isLoading) {
            setModalSpinner(true)
        }
    }, [isLoading])

    if (!modalSpinner) {
        return null
    }

    return (
        <animated.div
            style={backgroundModalSpinnerStyles}
            className="modal-spinner"
        >
            <FontAwesomeIcon
                icon={['fal', 'tire']}
                spin
                style={{
                    ['--fa-primary-color' as string]: primaryColor,
                    height: '100px',
                    spinnerStyles,
                }}
            />
        </animated.div>
    )
}

LoadingModalSpinner.propTypes = {
    isLoading: PropTypes.bool,
    primaryColor: PropTypes.string,
}

LoadingModalSpinner.defaultProps = {
    primaryColor: '#000000',
}
