import Skeleton from 'react-loading-skeleton'

export const InstructorPageSkeleton = () => {
    return (
        <>
            <div className="bg-white p-3 my-3 shadow-sm d-flex align-items-center gap-2">
                <Skeleton width={50} height={50} />
                <div className="w-100">
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="bg-white p-3 my-3 shadow-sm d-flex align-items-center gap-2">
                <Skeleton width={50} height={50} />
                <div className="w-100">
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="bg-white p-3 my-3 shadow-sm d-flex align-items-center gap-2">
                <Skeleton width={50} height={50} />
                <div className="w-100">
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="bg-white p-3 my-3 shadow-sm d-flex align-items-center gap-2">
                <Skeleton width={50} height={50} />
                <div className="w-100">
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="bg-white p-3 my-3 shadow-sm d-flex align-items-center gap-2">
                <Skeleton width={50} height={50} />
                <div className="w-100">
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="bg-white p-3 my-3 shadow-sm d-flex align-items-center gap-2">
                <Skeleton width={50} height={50} />
                <div className="w-100">
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="bg-white p-3 my-3 shadow-sm d-flex align-items-center gap-2">
                <Skeleton width={50} height={50} />
                <div className="w-100">
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="bg-white p-3 my-3 shadow-sm d-flex align-items-center gap-2">
                <Skeleton width={50} height={50} />
                <div className="w-100">
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="bg-white p-3 my-3 shadow-sm d-flex align-items-center gap-2">
                <Skeleton width={50} height={50} />
                <div className="w-100">
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="bg-white p-3 my-3 shadow-sm d-flex align-items-center gap-2">
                <Skeleton width={50} height={50} />
                <div className="w-100">
                    <Skeleton count={3} />
                </div>
            </div>
        </>
    )
}

export const SelectSkeleton = () => {
    return (
        <div className="d-flex flex-column flex-lg-row align-items-center gap-1 mb-5">
            <div className="w-100 lh-sm pt-2 pt-lg-4">
                <Skeleton width={100} />
                <div className="w-100">
                    <Skeleton height={30} />
                </div>
            </div>
            <div className="w-100 lh-sm pt-2 pt-lg-4">
                <Skeleton width={100} />
                <div className="w-100">
                    <Skeleton height={30} />
                </div>
            </div>
            <div className="w-100 lh-sm pt-2 pt-lg-4">
                <Skeleton width={100} />
                <div className="w-100">
                    <Skeleton height={30} />
                </div>
            </div>
            <div className="w-100 lh-sm pt-2 pt-lg-4">
                <Skeleton width={100} />
                <div className="w-100">
                    <Skeleton height={30} />
                </div>
            </div>
        </div>
    )
}
