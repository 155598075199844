import { useState } from 'react'
import { Button, Row, Col, Form, Image, Badge } from 'react-bootstrap'
import { CountDownTimer } from '@/lib/CountDownTimer.tsx'
import api from '@/api/api.ts'
import { useTicketsTypeStore } from '@/store/TicketsTypeStore.ts'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { customToast } from '@/lib/CustomToast.ts'
import TicketsEndModal from '@/components/Modals/DefaultModal/DefaultModal.tsx'
import {
    TicketsEndModalBody,
    TimerEndModal,
} from '@/components/Modals/TicketModals.tsx'
import {
    CircleDotChecked,
    CircleDotPrimary,
} from '@/components/Forms/TicketsInputIcons.tsx'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

const Questions = ({ ticketsQuestions, timer }) => {
    const questions = ticketsQuestions || []

    // !todo Кажется много стейтов. Кол-во ошибок, успехов, ответы, список ответов, список билетов думаю можно объединить в один стейт и фильтровать при необходимости
    // !todo showComment = isAnswerConfirmed
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [selectedAnswers, setSelectedAnswers] = useState({})
    const [isAnswerConfirmed, setIsAnswerConfirmed] = useState(false)
    const [showComment, setShowComment] = useState(false)
    const [correctAnswersCount, setCorrectAnswersCount] = useState(0)
    const [errorAnswersCount, setErrorAnswersCount] = useState(0)
    const [errorsList, setErrorsList] = useState([])
    const [isAnswerSelected, setIsAnswerSelected] = useState(null)
    const [answerIndicators, setAnswerIndicators] = useState({})
    const [ticketsEndModalShow, setTicketsEndModalShow] = useState(false)
    const [timerEndModalShow, setTimerEndModalShow] = useState(false)
    const [errorsData, setErrorsData] = useState([])

    const selectedAnswer = selectedAnswers[currentQuestionIndex]
    const trueAnswer = 'ans_' + questions[currentQuestionIndex]?.right
    const question = questions[currentQuestionIndex]

    const location = useLocation()
    const isThemesTickets = location.pathname.includes('themes')
    const navigate = useNavigate()
    const { id } = useParams()

    const { activeTicketsType } = useTicketsTypeStore()

    const handleSelectAnswer = (answer) => {
        setSelectedAnswers({
            ...selectedAnswers,
            [currentQuestionIndex]: answer,
        })
        setShowComment(false)
    }

    const handleConfirmAnswer = () => {
        if (selectedAnswer) {
            setIsAnswerConfirmed(true)
            setIsAnswerSelected(true)
            setShowComment(true)
            setAnswerIndicators({
                ...answerIndicators,
                [currentQuestionIndex]:
                    selectedAnswer === trueAnswer ? 'correct' : 'incorrect',
            })

            if (selectedAnswer !== trueAnswer) {
                setErrorAnswersCount(errorAnswersCount + 1)
                setErrorsList([...errorsList, question.bn, question.qn])

                if (!isThemesTickets) {
                    setErrorsData([...errorsData, question.qn])
                } else {
                    const errorIndex = questions.findIndex(
                        (item) => item.id === question.id,
                    )
                    setErrorsData([...errorsData, errorIndex + 1])
                }
            } else {
                setCorrectAnswersCount(correctAnswersCount + 1)
            }
        } else {
            toast('Пожалуйста, сначала выберите ваш ответ.')
        }
    }

    const handleNextQuestion = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1)
            setIsAnswerConfirmed(false)
            setShowComment(false)
            setSelectedAnswers({
                ...selectedAnswers,
                [currentQuestionIndex]: undefined,
            })
        } else {
            handleSubmitResults.mutate()
        }
    }

    const handleSubmitResults = useMutation<void, AxiosError<any>>({
        mutationFn() {
            return api.post('/tickets/save', {
                module: isThemesTickets ? 'themes' : 'tickets',
                type: activeTicketsType,
                ticket_id: +id,
                success: correctAnswersCount,
                errors: errorAnswersCount,
                errors_list: errorsData.join(' '),
            })
        },
        onError(error) {
            customToast({
                message: error.response.data.message,
                type: 'error',
            })
        },
        onSuccess() {
            setTicketsEndModalShow(true)
            customToast({
                message: 'Результат решения отправлен',
                type: 'success',
            })
        },
    })

    const handleCloseModal = () => {
        setTicketsEndModalShow(false)
        isThemesTickets ? navigate('/tickets/themes') : navigate('/tickets')
    }

    const handleShowTimerEnd = () => {
        {
            /*!todo На старом лидере при завершении таймера при решении билетов ничего не происходит, возможно так задумано*/
        }
        setTimerEndModalShow(true)
    }

    const handleCloseTimerEndModal = () => {
        setTimerEndModalShow(false)
        isThemesTickets ? navigate('/tickets/themes') : navigate('/tickets')
    }

    return (
        <>
            <div className="question__card-header">
                {!isThemesTickets ? (
                    <h2>
                        Билет {question?.bn} - Вопрос {question?.qn}
                    </h2>
                ) : (
                    <div className="question__card-header-badged">
                        <h2>
                            Вопрос {currentQuestionIndex} из {questions?.length}
                        </h2>
                        <Badge bg="success p-1">
                            Б{question?.bn} В{question?.qn}
                        </Badge>
                    </div>
                )}
                {timer && (
                    <CountDownTimer
                        initialMinutes={20}
                        onTimerEnd={handleShowTimerEnd}
                        className="question__card-header-timer"
                    />
                )}
            </div>
            <div className="question__card-content">
                <div className="question__card-content-indicators">
                    {[...Array(questions.length)].map((_, idx) => (
                        <FontAwesomeIcon
                            key={idx}
                            className="question__card-content-indicators-indicator"
                            icon={
                                answerIndicators[idx] === 'correct'
                                    ? ['fas', 'circle']
                                    : answerIndicators[idx] === 'incorrect'
                                      ? ['fas', 'circle']
                                      : ['fal', 'circle']
                            }
                            size="sm"
                            color={
                                answerIndicators[idx] === 'correct'
                                    ? 'green'
                                    : answerIndicators[idx] === 'incorrect'
                                      ? 'red'
                                      : ''
                            }
                        />
                    ))}
                </div>
                <div className="question__card-content-image">
                    <Image
                        src={question?.img}
                        alt={`question${question?.id}`}
                        className="img-fluid w-100"
                    />
                </div>
                <div className="question__card-content-question">
                    <h3 className="question__card-content-question-text">
                        {question?.qst}
                    </h3>
                    <div className="question__card-content-question-answers">
                        {question &&
                            Object?.keys(question)
                                .filter(
                                    (key) =>
                                        key?.startsWith('ans_') &&
                                        question[key],
                                )
                                .map((ansKey, idx) => (
                                    <div
                                        key={idx}
                                        className="question__card-content-question-answers-answer"
                                    >
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name={`question_${currentQuestionIndex}`}
                                            id={`question_${idx}`}
                                            autoComplete="off"
                                            checked={
                                                selectedAnswer === ansKey &&
                                                !isAnswerConfirmed
                                            }
                                            onChange={() => null}
                                            disabled={isAnswerConfirmed}
                                        />
                                        <label
                                            className="btn btn-check-tickets p-2 w-100"
                                            htmlFor={`question_${idx}`}
                                            onClick={() =>
                                                handleSelectAnswer(ansKey)
                                            }
                                            style={{
                                                backgroundColor:
                                                    ansKey === trueAnswer &&
                                                    isAnswerConfirmed
                                                        ? '#4CAE4FFF'
                                                        : selectedAnswer ===
                                                                ansKey &&
                                                            isAnswerConfirmed
                                                          ? '#E41B21FF'
                                                          : '',
                                                color:
                                                    ansKey === trueAnswer &&
                                                    isAnswerConfirmed
                                                        ? 'white'
                                                        : selectedAnswer ===
                                                                ansKey &&
                                                            isAnswerConfirmed
                                                          ? 'white'
                                                          : '',
                                            }}
                                        >
                                            <div className="btn-check-tickets-items">
                                                {selectedAnswer === ansKey ? (
                                                    <CircleDotChecked />
                                                ) : (
                                                    <CircleDotPrimary />
                                                )}
                                                <p>{question[ansKey]}</p>
                                            </div>
                                        </label>
                                    </div>
                                ))}
                    </div>
                    <Button
                        variant={
                            !showComment ? 'outline-warning' : 'outline-success'
                        }
                        className="question__card-content-submit-btn"
                        onClick={
                            isAnswerSelected && isAnswerConfirmed
                                ? handleNextQuestion
                                : handleConfirmAnswer
                        }
                    >
                        {isAnswerConfirmed
                            ? 'Следующий вопрос'
                            : 'Подтвердить ответ'}
                        {/*!todo Последний вопрос "Завершить тест"*/}
                        {/*!todo Нету "Сообщить об ошибке в билете"*/}
                    </Button>
                </div>
                {showComment && (
                    <div className="question__card-content-comment">
                        <p
                            className="question__card-content-comment-text"
                            style={{
                                borderLeft:
                                    selectedAnswer !== trueAnswer
                                        ? '5px solid red'
                                        : '5px solid green',
                            }}
                        >
                            {question?.comment}
                        </p>
                    </div>
                )}
            </div>
            <TicketsEndModal
                show={ticketsEndModalShow}
                body={
                    <TicketsEndModalBody
                        correctAnswersCount={correctAnswersCount}
                        errorAnswersCount={errorAnswersCount}
                        errorsList={errorsList}
                    />
                }
                handleClose={handleCloseModal}
                bodyClassName="m-0"
                closeButton={true}
            />

            <TimerEndModal
                timerEndModalShow={timerEndModalShow}
                handleClose={handleCloseTimerEndModal}
            />
        </>
    )
}

export default Questions
