import './Tickets.scss'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useLoadingStateStore } from '@/store/LoadingStateStore.ts'
import { useTicketsTypeStore } from '@/store/TicketsTypeStore.ts'
import api from '@/api/api.ts'
import { useTicketsQuestionsStore } from '@/store/TicketsQuestionsStore.ts'
import TicketCard from '@/Pages/Tickets/TicketCard.tsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TicketsCardLayoutSkeleton from '@/Pages/Tickets/TicketsCardLayoutSkeleton.tsx'
import { FadeInOutAnimate } from '@/components/FadeInOutAnimate.tsx'
import TicketCardSkeleton from '@/Pages/Tickets/TicketCardSkeleton.tsx'
import { useQuery } from '@tanstack/react-query'
import { useUserQuery } from '@/lib/useUserQuery.ts'

const TicketsCardLayout = () => {
    const { pageLoading, setPageLoading: setIsPageLoading } =
        useLoadingStateStore()

    const { activeTicketsType } = useTicketsTypeStore()
    const navigate = useNavigate()

    const { currentTicketSelected } = useTicketsQuestionsStore()

    const user = useUserQuery()
    const schoolInfo = useQuery({
        queryKey: ['school', 'info', user],
        queryFn: async () => await api.get('info'),
        staleTime: Infinity,
        enabled: !!user,
    })

    const ticketsQuery = useQuery({
        queryKey: ['tickets', '40', activeTicketsType],
        queryFn: async () =>
            await api.get(`/tickets/list/${activeTicketsType}`),
        enabled: !!activeTicketsType && !!schoolInfo.data,
    })

    const Tickets = ticketsQuery?.data?.data /*!todo camelCase*/

    const TicketsList = Tickets?.map((ticket, index) => {
        return (
            <div key={index + ticket.id} className="tickets__list-body-button">
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{ticket.title}</Tooltip>}
                >
                    {ticket.status !== null ? (
                        // !todo if лишний, если только variant меняется
                        <Button
                            variant={
                                ticket.status
                                    ? 'outline-success'
                                    : 'outline-danger'
                            }
                            onClick={() => navigate(`/tickets/${ticket.id}`)}
                        >
                            Билет {ticket.id}
                        </Button>
                    ) : (
                        <Button
                            variant="outline-default"
                            onClick={() => navigate(`/tickets/${ticket.id}`)}
                        >
                            Билет {ticket.id}
                        </Button>
                    )}
                </OverlayTrigger>
            </div>
        )
    })

    return (
        <div className="page">
            {/*!todo Упрости условия - if(currentTicketSelected === null) { isPending ? 'skeleton' : 'list' } else {isLoading ? 'skeleton' : 'ticket'}'*/}
            {ticketsQuery.isPending && currentTicketSelected === null && (
                <TicketsCardLayoutSkeleton />
            )}

            {!ticketsQuery.isPending && currentTicketSelected === null && (
                <>
                    <h1 className="common__title">
                        Все билеты {activeTicketsType}
                    </h1>
                    <div className="tickets__list card">
                        <h2 className="tickets__list-title">
                            <FontAwesomeIcon
                                icon={['fas', 'check']}
                                size="lg"
                                color="green"
                                className="me-1"
                            />
                            Билеты соответствуют ПДД от <b>01 марта 2023г</b>
                        </h2>
                        <div className="tickets__list-body">{TicketsList}</div>
                        <div className="tickets__list-footer">
                            <Link
                                to="/tickets/exam"
                                className="tickets__list-footer-link"
                            >
                                <Button
                                    variant="outline-warning"
                                    className="tickets__list-footer-link-button"
                                >
                                    Режим экзамена
                                </Button>
                            </Link>
                            <Link
                                to="/tickets/themes"
                                className="tickets__list-footer-link"
                            >
                                <Button
                                    variant="outline-warning"
                                    className="tickets__list-footer-link-button"
                                >
                                    Билеты по темам
                                </Button>
                            </Link>
                            <Link
                                to="/tickets/statistics"
                                className="tickets__list-footer-link"
                            >
                                <Button
                                    variant="outline-warning"
                                    className="tickets__list-footer-link-button"
                                >
                                    Статистика
                                </Button>
                            </Link>
                        </div>
                    </div>
                </>
            )}
            {!pageLoading && currentTicketSelected !== null && (
                <FadeInOutAnimate>
                    <TicketCard timer={true} />
                </FadeInOutAnimate>
            )}
            {pageLoading && currentTicketSelected !== null && (
                <div className="card">
                    <TicketCardSkeleton />
                </div>
            )}
        </div>
    )
}

export default TicketsCardLayout
