import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import LightBox from '@/Pages/InstructorsPage/InstructorProfile/Lightbox.tsx'
import { IInstructors } from '@/types/IInstructors.ts'

interface Props {
    instructor: IInstructors
}

function InstructorHeader({ instructor }: Props) {
    const [showFromAvatar, setShowFromAvatar] = useState(false)
    const carSlides = instructor?.cars[0].images.map((image) => ({
        src: image.full,
    })) /*!todo+ Не хватает изображения инструктора*/

    return (
        <div className="instructor__header">
            <LightBox
                setShowLightbox={setShowFromAvatar}
                showLightbox={showFromAvatar}
                slides={[{ src: instructor.image.full }, ...carSlides]}
            />
            <div className="instructor__header-avatar-block">
                <img
                    src={instructor.image.small}
                    alt="instructor-avatar"
                    className="img-fluid"
                    onClick={() => setShowFromAvatar(true)}
                />
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>{`Отзывов: ${instructor.reviews}`}</Tooltip>
                    }
                >
                    <div>
                        {Array.from(
                            { length: instructor.rating },
                            (_, index) => (
                                <FontAwesomeIcon
                                    key={index}
                                    icon={['fas', 'star']}
                                    color="orange"
                                />
                            ),
                        )}
                    </div>
                </OverlayTrigger>
            </div>
            <div className="instructor__header-info-block">
                <h2>{instructor.surname}</h2>
                <p className="instructor__header-info-block-item">
                    <FontAwesomeIcon icon={['fas', 'check']} color="green" />
                    {instructor.types.join(', ')}
                </p>
                <p className="instructor__header-info-block-item">
                    <FontAwesomeIcon icon={['fal', 'calendar-alt']} />
                    Возраст: {instructor.age}
                </p>
                <p className="instructor__header-info-block-item">
                    <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />
                    Место встречи: {instructor.location}
                </p>
                {!instructor.is_my_instructor ? (
                    <p className="instructor__header-info-block-item text-danger">
                        <FontAwesomeIcon icon={['fas', 'phone']} />
                        Телефон недоступен {instructor.phone}
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip>
                                    Номер скрыт, потому что это не ваш
                                    инструктор. Если хотите заниматься у этого
                                    инструктора, свяжитесь с менеджером вашего
                                    филиала
                                </Tooltip>
                            }
                        >
                            <FontAwesomeIcon
                                icon={['fas', 'question-circle']}
                            />
                        </OverlayTrigger>
                    </p>
                ) : (
                    <a
                        href={`tel:${instructor.phone}`}
                        type="tel"
                        className="instructor__header-info-block-item"
                    >
                        <FontAwesomeIcon icon={['fas', 'phone']} />
                        Телефон инструктора: {instructor.phone}
                    </a>
                )}
                <p className="instructor__header-info-block-item">
                    <FontAwesomeIcon icon={['fas', 'file-text']} />
                    {instructor.about ||
                        'Описание инструктора еще не добавлено'}
                </p>
            </div>
        </div>
    )
}

export default InstructorHeader
