import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const CircleDotChecked = () => {
    return (
        <FontAwesomeIcon
            icon={['fad', 'circle-dot']}
            style={{
                ['--fa-primary-color' as string]: 'black',
                ['--fa-secondary-color' as string]: '#fed321',
                ['--fa-secondary-opacity' as string]: '1',
            }}
            size="sm"
            className="cursor-pointer"
        />
    )
}

export const CircleDotPrimary = () => {
    return (
        <FontAwesomeIcon
            icon={['fad', 'circle-dot']}
            style={{
                ['--fa-primary-color' as string]: 'lightgray',
                ['--fa-secondary-color' as string]: 'lightgray',
                ['--fa-secondary-opacity' as string]: '1',
            }}
            className="cursor-pointer"
            size="sm"
        />
    )
}

export const SquareCheck = ({ checked, style = {}, ...rest }) => {
    const checkedStyle = checked
        ? {
              '--fa-primary-color': 'black',
              '--fa-secondary-color': '#fed321',
              '--fa-secondary-opacity': '1',
          }
        : {
              '--fa-primary-color': 'lightgray',
              '--fa-secondary-color': 'lightgray',
              '--fa-secondary-opacity': '1',
          }
    return (
        <FontAwesomeIcon
            icon={['fad', checked ? 'square-check' : 'square']}
            style={{
                ...style,
                ...checkedStyle,
                cursor: 'pointer',
            }}
            size="1x"
            {...rest}
        />
    )
}
