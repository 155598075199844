import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import cenev from '@/assets/img/cenev.jpg'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function FooterCard() {
    return (
        <>
            <div className="footer__card card">
                <Image
                    src={cenev}
                    roundedCircle={true}
                    className="footer__card-image"
                />
                <div className="footer__card-contacts">
                    <h2 className="footer__card-contacts-title">
                        Обучение должно доставлять Вам только положительные
                        эмоции!
                    </h2>
                    <p>
                        Если по какой-то причине во время обучения у Вас
                        возникла проблема - сообщите об этом Вячеславу Ценеву
                    </p>
                    <div className="footer__card-contacts-links">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Позвонить</Tooltip>}
                        >
                            <Link
                                to="tel:+79524082339"
                                className="footer__card-contacts-links-item btn btn-outline-warning"
                            >
                                <FontAwesomeIcon icon={['fas', 'phone-alt']} />
                            </Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Написать ВКонтакте</Tooltip>}
                        >
                            <Link
                                to="https://vk.com/id12901418"
                                className="footer__card-contacts-links-item btn btn-outline-warning"
                            >
                                <FontAwesomeIcon icon={['fab', 'vk']} />
                            </Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Написать в Instagram</Tooltip>}
                        >
                            <Link
                                to="https://instagram.com/tsenev_v"
                                className="footer__card-contacts-links-item btn btn-outline-warning"
                            >
                                <FontAwesomeIcon icon={['fab', 'instagram']} />
                            </Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Написать в Whatsapp</Tooltip>}
                        >
                            <Link
                                to="https://wa.me/79524082339"
                                className="footer__card-contacts-links-item btn btn-outline-warning"
                            >
                                <FontAwesomeIcon icon={['fab', 'whatsapp']} />
                            </Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Написать в Viber</Tooltip>}
                        >
                            <Link
                                to="https://viber.click/79524082339"
                                className="footer__card-contacts-links-item btn btn-outline-warning"
                            >
                                <FontAwesomeIcon icon={['fab', 'viber']} />
                            </Link>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
        </>
    )
}
