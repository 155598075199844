import { ForwardedRef, forwardRef } from 'react'
import { ICategory } from '@/types/ICategory.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IExamCardProps {
    category: ICategory
}

export const ExamsCard = forwardRef(
    ({ category }: IExamCardProps, ref: ForwardedRef<HTMLDivElement>) => {
        const gibdd = category.gibdd

        return (
            <>
                <div className="learning__step-container" ref={ref}>
                    <div className="learning__step-icon">4</div>
                    <h1 className="learning__step-title">
                        {/*!todo+ if category == 'AI' "Экзамен в Гостехнадзоре"*/}
                        {category.name === 'Категория AI'
                            ? 'Экзамен в Гостехнадзоре'
                            : 'Экзамен в ГИБДД'}
                    </h1>
                </div>
                <div className="exams__card card">
                    <h2>{gibdd.title}</h2>
                    {typeof gibdd.exams !== 'string' &&
                        gibdd.exams.map((exam, index) => (
                            /*!todo+ Формат не тот*/
                            <p key={index}>
                                {exam.status ? (
                                    <FontAwesomeIcon
                                        icon={['fas', 'check']}
                                        color="green"
                                        size="lg"
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={['fas', 'xmark']}
                                        color="red"
                                        size="lg"
                                    />
                                )}
                                {exam.date} -{' '}
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: exam.title
                                            .replaceAll(' ', '&nbsp;')
                                            .replace(',&nbsp;', ', '),
                                    }}
                                />
                            </p>
                        ))}
                    {typeof gibdd.exams === 'string' && (
                        <span>{gibdd.exams}</span>
                    )}
                </div>
            </>
        )
    },
)
