import { useTicketsTypeStore } from '@/store/TicketsTypeStore.ts'
import { useMarathonStore } from '@/store/MarathonStore.ts'
import api from '@/api/api.ts'
import { useLoadingStateStore } from '@/store/LoadingStateStore.ts'
import { customToast } from '@/lib/CustomToast.ts'
import { useTimerStore } from '@/store/DefaultTimerStore.ts'

export const getMarathonQuestions = async () => {
    const { setPageLoading } = useLoadingStateStore.getState()
    const { activeTicketsType } = useTicketsTypeStore.getState()
    const { setMinutes, setSeconds } = useTimerStore.getState()
    const {
        setTickets,
        setSavedQuestions,
        setAutomaticTransitionMode,
        setDoubleClickMode,
    } = useMarathonStore.getState()
    setPageLoading(true)
    const res = await api /*!todo+ без then*/
        .get(`/tickets/marathon/${activeTicketsType}/start`)
    if (res.data.savedTime) {
        const savedTime = res.data.savedTime
        const timeParts = savedTime.split(':')
        setMinutes(parseInt(timeParts[0]))
        setSeconds(parseInt(timeParts[1]))
    }
    setTickets(res.data.tickets.flat())
    setSavedQuestions(res.data?.savedQuestion.flat())
    setAutomaticTransitionMode(res.data.is_auto_transition_question)
    setDoubleClickMode(res.data.is_dblclick_answer)
    setPageLoading(false)
}

export const handleSelectAnswer = (answer) => {
    const {
        selectedAnswers,
        currentTicketIndex,
        setSelectedAnswers,
        setIsAnswerSelected,
    } = useMarathonStore.getState()

    setSelectedAnswers({
        ...selectedAnswers,
        [currentTicketIndex]: answer,
    })
    setIsAnswerSelected(true)
}

export const handleConfirmAnswer = async ({
    answersCount,
    navigate,
    setIsMarathonEnding,
}) => {
    const {
        setIsAnswerSelected,
        setIsAnswerConfirmed,
        setShowComment,
        questionsAndAnswers,
    } = useMarathonStore.getState()
    const { minutes, seconds } = useTimerStore.getState()
    const { setButtonLoading } = useLoadingStateStore.getState()
    const { activeTicketsType } = useTicketsTypeStore.getState()

    if (answersCount === 800) {
        /*!todo+ Неправильный тип?*/
        handleMarathonEnd(navigate, setIsMarathonEnding)
    } else {
        setButtonLoading(true)
        setIsAnswerSelected(true)
        setIsAnswerConfirmed(true)
        setShowComment(true)
        await api
            .post(`tickets/marathon/${activeTicketsType}/add`, {
                questionsAndAnswers: questionsAndAnswers,
                time: `${minutes}:${seconds}`,
            })
            .catch((error) =>
                customToast({
                    message: error.response.data.message,
                    type: 'error',
                }),
            )
            .finally(() => setButtonLoading(false))
    }
}

export const handleNextQuestion = async () => {
    const { setButtonLoading } = useLoadingStateStore.getState()
    const { activeTicketsType } = useTicketsTypeStore.getState()
    const {
        currentTicketIndex,
        setIsAnswerConfirmed,
        setIsAnswerSelected,
        setShowComment,
        answeredIndexes,
        setCurrentTicketIndex,
        tickets,
    } = useMarathonStore.getState()
    setButtonLoading(true)
    if (currentTicketIndex < tickets?.length - 1) {
        await api.get(`tickets/marathon/${activeTicketsType}/checking-sum`)
        setIsAnswerConfirmed(false)
        setShowComment(false)
        answeredIndexes.push(currentTicketIndex)
        setCurrentTicketIndex(currentTicketIndex + 1)
        setIsAnswerSelected(false)
        setButtonLoading(false)
    }
}

export const handleMarathonEnd = async (navigate, setIsMarathonEnding) => {
    const { activeTicketsType } = useTicketsTypeStore.getState()
    const { setShowMarathonEndModal, setMarathonSingleStatistic } =
        useMarathonStore.getState()
    setIsMarathonEnding(true)
    try {
        const res = await api.get(`tickets/marathon/${activeTicketsType}/end`)
        setMarathonSingleStatistic(res.data)
        navigate('/tickets/marathon/statistics')
        setShowMarathonEndModal(true)
    } catch (e: any) {
        customToast({
            message: e.response.data.message,
            type: 'error',
        })
    } finally {
        setIsMarathonEnding(false)
    }
}

export const automaticTransition = (answer) => {
    const { setShowComment, isAnswerConfirmed } = useMarathonStore.getState()
    handleSelectAnswer(answer)
    isAnswerConfirmed && setShowComment(true)
}

export const formatTime = (time: string) => {
    const match = time?.match(/(\d+):(\d+)/)
    // || statisticsTime?.match(/(\d+):(\d+)/)
    if (!match) return null
    let [minutes, seconds] = match.slice(1).map(Number)
    const hours = Math.floor(minutes / 60)
    minutes %= 60
    return [hours, minutes, seconds]
        .map((item) => `${item}`.padStart(2, '0'))
        .join(':')
}

export const handleChangeMarathonSettings = async () => {
    const {
        setShowMarathonSettings,
        doubleClickMode,
        automaticTransitionMode,
    } = useMarathonStore.getState()
    const { activeTicketsType } = useTicketsTypeStore.getState()
    setShowMarathonSettings(false)
    try {
        const res = await api.post(
            `/tickets/marathon/${activeTicketsType}/edit-settings`,
            {
                is_auto_transition_question: automaticTransitionMode,
                is_dblclick_answer: doubleClickMode,
            },
        )
        customToast({
            message: res.data.message,
            type: 'success',
        })
    } catch (e: any) {
        customToast({
            message: e.response.data.message,
            type: 'error',
        })
    }
}
