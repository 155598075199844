import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-indiana-drag-scroll/dist/style.css'
import './App.scss'
import { Outlet, useLocation } from 'react-router-dom'
import { useEffect } from 'react'

function App() {
    const { pathname } = useLocation()

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'instant' })
        }, 350)
    }, [pathname])

    return <Outlet />
}

export default App
