import './Exam.scss'
import ExamQuestionsPreview from '@/Pages/Tickets/Exam/ExamQuestionsPreview.tsx'
import ExamGuide from '@/Pages/Tickets/Exam/ExamGuide.tsx'
import { useTicketsQuestionsStore } from '@/store/TicketsQuestionsStore.ts'
import { useExamStore } from '@/store/ExamStore.ts'
import ExamResults from '@/Pages/Tickets/Exam/ExamResults.tsx'
import {
    TicketsEndModalBody,
    TimerEndModal,
} from '@/components/Modals/TicketModals.tsx'
import { useEffect } from 'react'
import api from '@/api/api.ts'
import ExamEndModal from '@/components/Modals/DefaultModal/DefaultModal.tsx'

function ExamLayout() {
    const { ExamTicketsQuestions, setExamTicketsQuestions } =
        useTicketsQuestionsStore()
    const {
        correctAnswersCount,
        errorAnswersCount,
        errorsList,
        ticketsEndModalShow,
        showResults,
        timerEndModalShow,
        setTicketsEndModalShow,
        setTimerEndModalShow,
        reset,
    } = useExamStore()

    const handleCloseTimerEndModal = () => {
        setTimerEndModalShow(false)
        reset()
        setExamTicketsQuestions(null)
    }
    const handleCloseModal = () => {
        setTicketsEndModalShow(false)
    }

    const postOvertime = async () => {
        return await api.post('/tickets/exam/save', { overtime: true })
    }
    useEffect(() => {
        if (timerEndModalShow) {
            postOvertime()
        }
    }, [timerEndModalShow])

    const errorsArray = errorsList?.map(([str1, str2]) => {
        const formattedStr1 = `Б${str1}`
        const formattedStr2 = `В${str2}`

        return `${formattedStr1}:${formattedStr2}`
    })

    const postExamEnd = async () => {
        return await api.post('/tickets/exam/save', {
            errors: errorsArray.join(', '),
        })
    }

    useEffect(() => {
        if (ticketsEndModalShow) {
            postExamEnd()
        }
    }, [ticketsEndModalShow])

    useEffect(() => {
        setExamTicketsQuestions(null)
    }, [location.pathname])

    return (
        <>
            {!showResults ? (
                <>
                    {ExamTicketsQuestions !== null ? (
                        <ExamQuestionsPreview />
                    ) : (
                        <ExamGuide />
                    )}
                </>
            ) : (
                <ExamResults />
            )}

            <ExamEndModal
                show={ticketsEndModalShow}
                handleClose={handleCloseModal}
                body={
                    <TicketsEndModalBody
                        correctAnswersCount={correctAnswersCount}
                        errorAnswersCount={errorAnswersCount}
                        errorsList={errorsList}
                    />
                }
                bodyClassName="m-0"
                closeButton={true}
            />

            <TimerEndModal
                timerEndModalShow={timerEndModalShow}
                handleClose={handleCloseTimerEndModal}
            />
        </>
    )
}
export default ExamLayout
