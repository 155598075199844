import api from '@/api/api'
import { Controller, useForm } from 'react-hook-form'
import PhoneFormat from '@/components/PhoneFormat.js'
import { FormToastError } from '@/lib/FormToastError.ts'
import { useState } from 'react'
import { useRestoreAccountStore } from '@/store/RestoreAccountStore.ts'
import { useFadeTransition } from '@/components/FadeTransition.tsx'
import { animated } from 'react-spring'
import { Link } from 'react-router-dom'
import { LoadingSpinner } from '@/components/Forms/Loading.tsx'
import { AxiosError } from 'axios'
import { useMutation } from '@tanstack/react-query'
import { customToast } from '@/lib/CustomToast.ts'

const RESTORE_PHONE_URL = '/auth/restore'

export default function RestorePhone() {
    const { control, handleSubmit } = useForm()
    const [isButtonVisible, setIsButtonVisible] = useState(true)
    const { setPhone, setRestorePhoneChecked, restorePhoneChecked } =
        useRestoreAccountStore()

    const animate = useFadeTransition(isButtonVisible)

    const checkMutation = useMutation<any, AxiosError<any>, string>({
        mutationFn(formattedPhone) {
            return api.post(
                RESTORE_PHONE_URL,
                { phone: formattedPhone },
                { headers: { 'Content-Type': 'application/json' } },
            )
        },
        onSuccess(res) {
            customToast({
                message: res.data.message,
                type: 'success',
            })
            setIsButtonVisible(false)
            setRestorePhoneChecked(true)
        },
        onError(error) {
            customToast({
                message: error.response.data.message,
                type: 'error',
            })
            setIsButtonVisible(true)
            setRestorePhoneChecked(false)
        },
    })

    async function checkPhone({ phone }) {
        const formattedPhone = phone.replace(/[\s-\D]/g, '')
        setPhone(formattedPhone)
        //!todo+ Убрать then
        checkMutation.mutate(formattedPhone)
    }

    return (
        <form
            id="restore-phone"
            autoComplete="off"
            onSubmit={handleSubmit(checkPhone, FormToastError)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                }
            }}
        >
            <label
                //!todo+ В местах не совпадает for и id
                htmlFor="login-phone"
                className="authorization__form-group-label"
            >
                Номер телефона
            </label>
            <Controller
                name="phone"
                control={control}
                rules={{
                    required: 'Поле Номер телефона обязательно для заполнения',
                    validate: {
                        minLengthPhone: (v: string) =>
                            v.replace(/[^\d]/g, '').length == 11
                                ? true
                                : 'Введите корректный номер телефона',
                    },
                }}
                disabled={restorePhoneChecked}
                render={({ field }) => (
                    <PhoneFormat
                        {...field}
                        id="login-phone"
                        className="authorization__form-group-input form-control"
                    />
                )}
            />
            {animate(
                (style, item) =>
                    item && (
                        <animated.div style={style}>
                            <button
                                type="submit"
                                className="btn btn-danger authorization__form-button"
                            >
                                {checkMutation.isPending ? (
                                    <LoadingSpinner
                                        isLoading={checkMutation.isPending}
                                        primaryColor="white"
                                        secondaryColor="white"
                                    />
                                ) : (
                                    'Восстановить'
                                )}
                            </button>
                            <Link
                                to="/login"
                                className="authorization__form-forgot-link"
                            >
                                Назад
                            </Link>
                        </animated.div>
                    ),
            )}
        </form>
    )
}
