import { Modal } from 'react-bootstrap'
import './DefaultModal.scss'
import { ReactNode } from 'react'

interface IDefaultModalProps {
    show: boolean
    handleClose: () => void
    closeButton: boolean
    body: ReactNode
    title?: ReactNode
    className?: string
    bodyClassName?: string
    size?: 'lg' | 'xl' | 'sm'
    centered?: boolean
}

const DefaultModal = ({
    show,
    handleClose,
    closeButton,
    body,
    title,
    className,
    bodyClassName,
    size,
    centered,
    ...rest
}: IDefaultModalProps) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            {...rest}
            contentClassName={className ? className : 'modal-container'}
            dialogClassName="modal-dialog"
            backdrop
            size={size}
            centered={centered || false}
        >
            {closeButton && (
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
            )}
            <Modal.Body className={bodyClassName}>{body}</Modal.Body>
        </Modal>
    )
}
export default DefaultModal
