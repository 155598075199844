import api from '@/api/api.ts'
import { usePaymentsStore } from '@/store/PaymentsStore.ts'
import { useLoadingStateStore } from '@/store/LoadingStateStore.ts'
import { Dispatch, SetStateAction } from 'react'

export const getPaymentServices = async (
    id: number,
    category: object,
    setSelectedCategoryId: (id: number) => void,
    setStep: Dispatch<SetStateAction<string>>,
) => {
    const { setPaymentServices, selectedPaymentServices } =
        usePaymentsStore.getState()
    const { setButtonLoading } = useLoadingStateStore.getState()
    setSelectedCategoryId(id)
    try {
        setButtonLoading(true)
        const paymentVariantResponse = await api.get(
            `/payment/categories/${id}/services`,
        )
        setPaymentServices(paymentVariantResponse.data)
        selectedPaymentServices.push({
            category: category,
        })
    } catch (e) {
        return null
    } finally {
        setStep('services')
    }
}

export const setConstructedPaymentData = () => {
    const { selectedPaymentServices, paymentsData, setPaymentsData } =
        usePaymentsStore.getState()
    const newPaymentsData = paymentsData
    const newPayments = []
    selectedPaymentServices.forEach((item, index) => {
        newPayments.push({
            userCategory: item.category.id,
            serviceId: item.services.id,
            price: Math.floor(item.services.cost),
        })
    })
    newPaymentsData.payments = Object.values(newPayments)
    setPaymentsData(newPaymentsData)
}
