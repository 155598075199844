import { Dispatch, SetStateAction, useEffect } from 'react'
import ReactSelect, { components } from 'react-select'
import { customStyles } from '@/Pages/InstructorsPage/InstructorsPageHelpers.ts'
import { IFilters, ISelectedFilters } from '@/types/IInstructors.ts'

interface Props {
    selectedMarks: string[]
    setSelectedMarks: Dispatch<SetStateAction<any>>
    selectedModels: string[]
    setSelectedModels: Dispatch<SetStateAction<any>>
    selectedGender: string[]
    setSelectedGender: Dispatch<SetStateAction<any>>
    selectedTransmission: string[]
    setSelectedTransmission: Dispatch<SetStateAction<any>>
    allFilters: IFilters
    setAllFilters?: Dispatch<SetStateAction<any>>
    selectedFilters: ISelectedFilters
    setSelectedFilters: Dispatch<SetStateAction<any>>
}

export default function ReactMultiSelect({
    selectedMarks,
    setSelectedMarks,
    selectedModels,
    setSelectedModels,
    selectedGender,
    setSelectedGender,
    selectedTransmission,
    setSelectedTransmission,
    allFilters,
    selectedFilters,
    setSelectedFilters,
}: Props) {
    const { MenuList } = components

    const emptyOptions = [
        {
            value: '',
            label: 'Сначала выберите марку',
            isDisabled: true,
        },
    ]

    const ifMarksSelected = selectedFilters.car_marks.flatMap(
        (mark) =>
            allFilters?.car_mark_models[mark].map((model) => ({
                value: model,
                label: model,
            })),
    )

    const kpp = ['АКПП', 'МКПП']
    const instructorSex = ['Мужчина', 'Женщина']
    const marks = selectedMarks.map((mark: string) => mark.value)
    const models = selectedModels.map((model) => model.value)
    const kppTypes = selectedTransmission.map((kpp) => kpp.value)
    const genders = selectedGender.map((sex) => sex.value)

    const handleMarkChange = (mark: string) => {
        setSelectedMarks(mark)
    }

    const handleModelChange = (model: string) => {
        setSelectedModels(model)
    }

    const handleTransmissionChange = (kppType: string) => {
        setSelectedTransmission(kppType)
    }

    const handleGenderChange = (gender: string) => {
        setSelectedGender(gender)
    }

    const markOptions = allFilters.car_marks.map((mark) => ({
        value: mark,
        label: mark,
    }))

    const modelOptions = selectedFilters.car_marks.length
        ? ifMarksSelected
        : emptyOptions

    const kppOptions = kpp.map((kppType) => ({
        value: kppType,
        label: kppType,
    }))

    const sexOptions = instructorSex.map((gender) => ({
        value: gender,
        label: gender,
    }))

    useEffect(() => {
        setSelectedFilters({
            car_marks: marks,
            car_models: !selectedMarks.length ? [] : models,
            kpp: kppTypes,
            sex: genders,
        })
    }, [selectedMarks, selectedModels, selectedTransmission, selectedGender])

    function CustomMarksControl({ children, selectProps, ...props }) {
        const handleSelectAll = () => {
            selectedMarks.length < allFilters.car_marks.length &&
                setSelectedMarks(
                    allFilters.car_marks.map((mark) => ({
                        value: mark,
                        label: mark,
                    })),
                )
        }

        const handleClearAll = () => {
            selectedMarks.length !== 0 && setSelectedMarks([])
            setSelectedModels([])
        }

        return (
            <>
                <MenuList {...props} className="p-0">
                    <div className="d-flex p-2">
                        <button
                            className="w-100 p-0 border-0 text-black"
                            onClick={handleSelectAll}
                            style={{ height: '30px' }}
                        >
                            Выбрать все
                        </button>
                        <button
                            className="w-100 p-0 border-0 text-black"
                            onClick={handleClearAll}
                            style={{ height: '30px' }}
                        >
                            Снять все
                        </button>
                    </div>
                    {children}
                </MenuList>
            </>
        )
    }

    function CustomModelsControl({ children, selectProps, ...props }) {
        const handleSelectAll = () => {
            selectedModels.length < ifMarksSelected.length &&
                setSelectedModels(ifMarksSelected)
        }

        const handleClearAll = () => {
            selectedModels.length !== 0 && setSelectedModels([])
        }

        return (
            <div>
                <MenuList {...props} className="p-0">
                    <div className="d-flex p-2">
                        <button
                            onClick={handleSelectAll}
                            style={{ height: '30px' }}
                            className="w-100 p-0 border-0 text-black"
                        >
                            Выбрать все
                        </button>
                        <button
                            onClick={handleClearAll}
                            style={{ height: '30px' }}
                            className="w-100 p-0 border-0 text-black"
                        >
                            Снять все
                        </button>
                    </div>
                    {children}
                </MenuList>
            </div>
        )
    }

    function CustomKppControl({ children, selectProps, ...props }) {
        const handleSelectAll = () => {
            selectedTransmission.length < kppOptions.length &&
                setSelectedTransmission(kppOptions)
        }

        const handleClearAll = () => {
            selectedTransmission.length !== 0 && setSelectedTransmission([])
        }

        return (
            <div>
                <MenuList {...props} className="p-0 overflow-hidden">
                    <div className="d-flex p-2">
                        <button
                            onClick={handleSelectAll}
                            style={{ height: '30px' }}
                            className="w-100 p-0 border-0 text-black"
                        >
                            Выбрать все
                        </button>
                        <button
                            onClick={handleClearAll}
                            style={{ height: '30px' }}
                            className="w-100 p-0 border-0 text-black"
                        >
                            Снять все
                        </button>
                    </div>
                    {children}
                </MenuList>
            </div>
        )
    }

    function CustomGenderControl({ children, selectProps, ...props }) {
        const handleSelectAll = () => {
            selectedGender.length < sexOptions.length &&
                setSelectedGender(sexOptions)
        }

        const handleClearAll = () => {
            selectedGender.length !== 0 && setSelectedGender([])
        }

        return (
            <div>
                <MenuList {...props} className="p-0 overflow-hidden">
                    <div className="d-flex p-2">
                        <button
                            onClick={handleSelectAll}
                            style={{ height: '30px' }}
                            className="w-100 p-0 border-0 text-black"
                        >
                            Выбрать все
                        </button>
                        <button
                            onClick={handleClearAll}
                            style={{ height: '30px' }}
                            className="w-100 p-0 border-0 text-black"
                        >
                            Снять все
                        </button>
                    </div>
                    {children}
                </MenuList>
            </div>
        )
    }

    return (
        <div className="instructors__selects">
            <div className="instructors__selects-select">
                <label className="text-body-tertiary">Марка авто</label>
                <ReactSelect
                    isMulti
                    options={markOptions}
                    value={selectedMarks}
                    onChange={handleMarkChange}
                    styles={customStyles}
                    placeholder="Выбрать марку"
                    closeMenuOnSelect={false}
                    backspaceRemovesValue={false}
                    hideSelectedOptions={false}
                    blurInputOnSelect={false}
                    components={{
                        MenuList: CustomMarksControl,
                    }}
                />
            </div>
            <div className="instructors__selects-select">
                <label className="text-body-tertiary">Модель авто</label>
                <ReactSelect
                    isMulti
                    options={modelOptions}
                    onChange={handleModelChange}
                    value={selectedModels}
                    styles={customStyles}
                    placeholder={
                        !selectedMarks?.length
                            ? 'Сначала выберите марку'
                            : 'Выбрать модель'
                    }
                    closeMenuOnSelect={false}
                    backspaceRemovesValue={false}
                    hideSelectedOptions={false}
                    blurInputOnSelect={false}
                    components={{ MenuList: CustomModelsControl }}
                />
            </div>
            <div className="instructors__selects-select">
                <label className="text-body-tertiary">Тип КПП</label>
                <ReactSelect
                    isMulti
                    options={kppOptions}
                    onChange={handleTransmissionChange}
                    value={selectedTransmission}
                    styles={customStyles}
                    placeholder="Выбрать КПП"
                    closeMenuOnSelect={false}
                    backspaceRemovesValue={false}
                    hideSelectedOptions={false}
                    blurInputOnSelect={false}
                    components={{ MenuList: CustomKppControl }}
                />
            </div>
            <div className="instructors__selects-select">
                <label className="text-body-tertiary">Пол инструктора</label>
                <ReactSelect
                    isMulti
                    options={sexOptions}
                    onChange={handleGenderChange}
                    value={selectedGender}
                    styles={customStyles}
                    placeholder="Выбрать пол"
                    closeMenuOnSelect={false}
                    backspaceRemovesValue={false}
                    hideSelectedOptions={false}
                    blurInputOnSelect={false}
                    components={{ MenuList: CustomGenderControl }}
                />
            </div>
        </div>
    )
}
