import { useMarathonStore } from '@/store/MarathonStore.ts'
import { Button, Form } from 'react-bootstrap'
import { SquareCheck } from '@/components/Forms/TicketsInputIcons.tsx'
import { handleChangeMarathonSettings } from '@/Pages/Tickets/Marathon/MarathonHelpers.tsx'

export function SettingsModalBody() {
    /*!todo+ Почему не компонент?*/
    const {
        setDoubleClickMode,
        setAutomaticTransitionMode,
        automaticTransitionMode,
        doubleClickMode,
    } = useMarathonStore.getState()

    const DoubleClickMode = () => {
        setDoubleClickMode(!doubleClickMode)
    }
    const AutomaticTransitionMode = () => {
        setAutomaticTransitionMode(!automaticTransitionMode)
    }
    return (
        <div className="marathon__settings">
            {/*!todo+ Почему не инпуты? По крайней мере сделай их квадратными, круглые намекают что они radio и взаимоисключающие*/}
            <div
                className="marathon__settings-mode"
                id="marathon-settings"
                onClick={() => AutomaticTransitionMode()}
            >
                <h3 className="marathon__settings-mode-check">
                    <SquareCheck checked={automaticTransitionMode} />
                    Автоматический переход вопроса
                </h3>
                <p className="marathon__settings-mode-descr">
                    При правильном ответе на вопрос, будет выполнен
                    автоматический переход к следующему вопросу
                </p>
            </div>
            <div
                className="marathon__settings-mode"
                id="marathon-settings"
                onClick={() => DoubleClickMode()}
            >
                <h3 className="marathon__settings-mode-check">
                    <SquareCheck checked={doubleClickMode} />
                    Двойной клик
                </h3>
                <p className="marathon__settings-mode-descr">
                    Выберите вариант ответа, а затем снова нажмите на него,
                    чтобы ответить на вопрос
                </p>
            </div>
            <Button
                variant="outline-warning"
                onClick={() => handleChangeMarathonSettings()}
                className="w-100"
            >
                Сохранить
            </Button>
        </div>
    )
}
