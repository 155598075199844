import { useState } from 'react'
import { usePaymentsStore } from '@/store/PaymentsStore.ts'
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { LoadingSpinner } from '@/components/Forms/Loading.tsx'
import { IPaymentProps } from '@/types/IPayments.ts'
import { customToast } from '@/lib/CustomToast.ts'
import api from '@/api/api.ts'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import PaymentMethodSkeleton from '@/Pages/PaymentsPage/PaymentMethodSkeleton.tsx'
import mirIcon from '@/assets/paymentIcons/mir.svg'
import visaIcon from '@/assets/paymentIcons/visa.svg'
import mastercardIcon from '@/assets/paymentIcons/mastercard.svg'
import unionpayIcon from '@/assets/paymentIcons/unionpay.svg'
import sberpayIcon from '@/assets/paymentIcons/sberpay.svg'
import sbpIcon from '@/assets/paymentIcons/sbp.svg'
import {
    CircleCheckGreen,
    CircleCheckPrimary,
    PaymentMethodsHeader,
} from '@/Pages/PaymentsPage/PaymentCategoryHelpers.tsx'

const PaymentMethod = ({ setStep }: IPaymentProps) => {
    const { paymentsData, setPaymentsData } = usePaymentsStore()
    const [isCardSaved, setIsCardSaved] = useState(false)
    const [cardNumber, setCardNumber] = useState('')
    const [selectedPaymentType, setSelectedPaymentType] = useState('')

    const { data: savedCardsData, isLoading: isSavedCardsLoading } = useQuery({
        queryKey: ['saved', 'cards'],
        queryFn: () => api.get('/payment/cards'),
    })

    const postPayment = useMutation<any, AxiosError<any>>({
        mutationFn: () => api.post('/payment/start', paymentsData),
        onSuccess(response) {
            window.open(response.data.redirect, '_self', 'noopener,noreferrer')
        },
        onError(error) {
            customToast({
                message: error.response.data.message,
                type: 'error',
            })
        },
    })

    const handleSelectPaymentType = (type: string) => {
        setSelectedPaymentType(type) // 'sberpay' || 'sbp' || 'card'
        paymentsData.paymentType = type
        if (type === 'sbp' || type === 'sberpay') {
            setCardNumber('')
            delete paymentsData.card
        }
    }

    const handleSelectCard = (card: string) => {
        setSelectedPaymentType('card')
        setCardNumber(card)

        setPaymentsData({
            ...paymentsData,
            paymentType: 'card',
            card: card,
            is_save_card: card !== 'new' ? true : isCardSaved,
        })
    }

    const handleChangeIsSaveCard = () => {
        setIsCardSaved(!isCardSaved)
        paymentsData.is_save_card = !paymentsData.is_save_card
    }

    const handlePostPayment = () => {
        if (paymentsData.card === '' || paymentsData.paymentType === '') {
            customToast({
                message: 'Выберите метод оплаты или карту',
                type: 'error',
            })
        } else {
            postPayment.mutate()
        }
    }

    return (
        <>
            {isSavedCardsLoading ? (
                <PaymentMethodSkeleton />
            ) : (
                <>
                    <PaymentMethodsHeader setStep={setStep} />
                    <div className="main__card-body-methods">
                        {savedCardsData?.data &&
                            savedCardsData?.data.cards.map(
                                (cardItem, index) => (
                                    <div
                                        className={`main__card-body-methods-method ${
                                            cardItem.guid === cardNumber &&
                                            'active'
                                        }`}
                                        key={index}
                                        onClick={() =>
                                            handleSelectCard(cardItem?.guid)
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'xmark']}
                                            className="xmark-card"
                                        />
                                        <div className="main__card-body-methods-method-info">
                                            <Image
                                                src={cardItem?.bank_logo}
                                                alt="payment-card"
                                                className="main__card-body-methods-method-info-card-img"
                                            />
                                            <div className="main__card-body-methods-method-info-card-data">
                                                <p className="main__card-body-methods-method-info-card-data-number">
                                                    <FontAwesomeIcon
                                                        icon={[
                                                            'fal',
                                                            'credit-card',
                                                        ]}
                                                    />
                                                    {cardItem?.masked_number}
                                                </p>
                                                <p>11/15</p>
                                                <Image
                                                    src={cardItem?.brand_logo}
                                                    alt="card-brand"
                                                    className="main__card-body-methods-method-info-card-data-brand-img"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ),
                            )}

                        <div
                            className={`main__card-body-methods-method ${
                                cardNumber === 'new' && 'active'
                            }`}
                            onClick={() => handleSelectCard('new')} // 'new card'
                        >
                            <div className="main__card-body-methods-method-info">
                                <h4>Новая карта</h4>
                                <div className="main__card-body-methods-method-info-card-data">
                                    <Image
                                        src={mirIcon}
                                        alt="mir"
                                        width="20%"
                                    />
                                    <Image
                                        src={mastercardIcon}
                                        alt="mastercard"
                                        width="20%"
                                    />
                                    <Image
                                        src={visaIcon}
                                        alt="visa"
                                        width="20%"
                                    />
                                    <Image
                                        src={unionpayIcon}
                                        alt="unionpay"
                                        width="20%"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main__card-footer">
                        <div className="main__card-footer-methods">
                            <button
                                className={`main__card-footer-methods-btn ${
                                    selectedPaymentType === 'sberpay' &&
                                    'active'
                                }`}
                                onClick={() =>
                                    handleSelectPaymentType('sberpay')
                                }
                            >
                                {selectedPaymentType !== 'sberpay' ? (
                                    <div className="main__card-footer-methods-btn-check-icon">
                                        <CircleCheckPrimary />
                                    </div>
                                ) : (
                                    <div className="main__card-footer-methods-btn-check-icon">
                                        <CircleCheckGreen />
                                    </div>
                                )}
                                <Image
                                    src={sberpayIcon}
                                    alt="sberpay"
                                    height="30px"
                                />
                            </button>
                            <button
                                className={`main__card-footer-methods-btn ${
                                    selectedPaymentType === 'sbp' && 'active'
                                }`}
                                onClick={() => handleSelectPaymentType('sbp')}
                            >
                                {selectedPaymentType !== 'sbp' ? (
                                    <div className="main__card-footer-methods-btn-check-icon">
                                        <CircleCheckPrimary />
                                    </div>
                                ) : (
                                    <div className="main__card-footer-methods-btn-check-icon">
                                        <CircleCheckGreen />
                                    </div>
                                )}
                                <Image src={sbpIcon} alt="sbp" height="30px" />
                            </button>
                        </div>
                        <div className="main__card-footer-tooltip">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        <p>
                                            Здесь отображаются карты с которых
                                            вы совершили хотя бы 1 успешный
                                            платеж.
                                            <br />
                                            Не переживайте - это безопасно!
                                            <br />
                                            Данные карт хранятся в обезличенном
                                            виде и никто не сможет списать
                                            денежные средства без вашего ведома
                                        </p>
                                    </Tooltip>
                                }
                            >
                                <span className="main__card-footer-tooltip-main-text">
                                    Почему я вижу свои карты?
                                </span>
                            </OverlayTrigger>
                        </div>

                        {cardNumber === 'new' && (
                            <div className="main__card-footer-save-card">
                                <input
                                    type="checkbox"
                                    checked={isCardSaved}
                                    onChange={() => handleChangeIsSaveCard()}
                                />
                                <span onClick={() => handleChangeIsSaveCard()}>
                                    Сохранить карту для платежей
                                </span>
                            </div>
                        )}

                        <div
                            className="main__card-footer-pay-btn btn btn-success"
                            onClick={() => handlePostPayment()}
                        >
                            {postPayment.isPending ? (
                                <LoadingSpinner
                                    isLoading={postPayment.isPending}
                                    primaryColor="black"
                                    secondaryColor="white"
                                />
                            ) : (
                                'Оплатить'
                            )}
                        </div>

                        <p className="main__card-footer-pay-btn-agreement">
                            Нажимая кнопку «Оплатить», Вы подтверждаете, что
                            ознакомились с &nbsp;
                            <Link
                                to="https://xn--80asehdb.xn--d1achlsfgb.xn--p1ai/legal/policy.pdf"
                                className="text-danger"
                                target="_blank"
                            >
                                Политикой конфиденциальности
                            </Link>
                            &nbsp; и принимаете &nbsp;
                            <Link
                                to="https://xn--80asehdb.xn--d1achlsfgb.xn--p1ai/legal/rules.pdf"
                                className="text-danger"
                                target="_blank"
                            >
                                Пользовательское соглашение
                            </Link>
                        </p>
                        <p className="main__card-footer-pay-btn-payment-description">
                            Для оплаты Вы будете перенаправлены на платежный
                            шлюз ООО НКО «ЮМани» (ОГРН 1127711000031, ИНН
                            7750005725, лицензия на осуществление банковских
                            операций № 3510-К). Соединение с платежным шлюзом и
                            передача информации осуществляется в защищенном
                            режиме с использованием протокола шифрования SSL. В
                            случае если Ваш банк поддерживает технологию
                            безопасного проведения интернет-платежей Verified By
                            Visa или MasterCard SecureCode для проведения
                            платежа также может потребоваться ввод специального
                            одноразового пароля.
                        </p>
                    </div>
                </>
            )}
        </>
    )
}

export default PaymentMethod
