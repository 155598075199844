import './ThemesTickets.scss'
import { useTicketsTypeStore } from '@/store/TicketsTypeStore.ts'
import { Badge } from 'react-bootstrap'
import api from '@/api/api.ts'
import ThemesTicketsSkeleton from '@/Pages/Tickets/ThemesTickets/ThemesTicketsSkeleton.tsx'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { ITheme } from '@/types/ITickets.ts'
import { useUserQuery } from '@/lib/useUserQuery.ts'

interface IThemeRowProps {
    ticket: ITheme
    indent?: string | undefined
}

function ThemeRow({ ticket, indent }: IThemeRowProps) {
    const className = typeof indent === 'undefined' ? '' : indent
    return (
        <div className="theme__ticket-card-body">
            <div className={`theme__ticket-card-body-title ${className}`}>
                <div
                    className="badge px-2 py-1"
                    style={{
                        backgroundColor: ticket.result.color,
                    }}
                >
                    {ticket.result.message}
                </div>
                <p>{ticket.name}</p>
            </div>
            <p
                className={`${className} theme__ticket-card-body-questions-count p-md-0`}
            >
                {ticket.questions}
            </p>
            <Link
                to={`/tickets/themes/${ticket.id}`}
                className={`theme__ticket-card-body-button btn btn-outline-warning ${className}`}
            >
                Решать
            </Link>
        </div>
    )
}

function ThemesTickets() {
    const { activeTicketsType } = useTicketsTypeStore()

    const user = useUserQuery()
    const schoolInfo = useQuery({
        queryKey: ['school', 'info', user],
        queryFn: async () => await api.get('info'),
        staleTime: Infinity,
        enabled: !!user,
    })

    const themeTicketsAll = useQuery({
        queryKey: ['tickets', 'themes', activeTicketsType],
        queryFn: async () =>
            await api
                .get(`/tickets/themes/${activeTicketsType}`)
                .then((res) => res.data),
        enabled: !!activeTicketsType && !!schoolInfo.data,
    })

    const themeTickets = themeTicketsAll.data /*!todo+ camelCase*/

    const themeTicketsList = themeTickets?.map((ticket, index) => {
        return (
            /*!todo+ Объедини темы и подтемы в один компонент с пропом indent (просто px-3 ставит)*/
            <div className="theme__ticket-card card" key={index}>
                <ThemeRow ticket={ticket} />
                {ticket?.sub_themes?.map((sub_theme) => (
                    <ThemeRow ticket={sub_theme} indent="ms-3" />
                ))}
            </div>
        )
    })

    return (
        <div className="page">
            {!themeTickets && <ThemesTicketsSkeleton />}
            {themeTickets && (
                <div>
                    <h1 className="common__title">
                        Билеты по темам &nbsp;
                        <Badge bg="success" className="p-1">
                            {activeTicketsType}
                        </Badge>
                    </h1>
                    <>{themeTicketsList}</>
                </div>
            )}
        </div>
    )
}

export default ThemesTickets
