import s from './TelegramAuthModal.module.scss'
import api from '@/api/api.ts'
import OtpInput from 'react-otp-input'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { FormToastError } from '@/lib/FormToastError.ts'
import { useEffect, useState } from 'react'
import { useLoadingStateStore } from '@/store/LoadingStateStore.ts'
import { useAuthWithTelegramStore } from '@/store/AuthWithTelegramStore.ts'
import { useAuthUserStore } from '@/store/AuthUserStore.ts'
import { useAuthTokenStore } from '@/store/AuthTokenStore.ts'
import { LoadingSpinner } from '@/components/Forms/Loading.tsx'
import { customToast } from '@/lib/CustomToast.ts'
import { useNavigate } from 'react-router-dom'

const RESEND_TELEGRAM = '/auth/telegram/code'
const AUTH_TELEGRAM = '/auth/telegram/login'

export default function TelegramAuthModal(props) {
    const { phone } = useAuthUserStore()
    const { setToken } = useAuthTokenStore()
    const { setTelegramConfirm } = useAuthWithTelegramStore()
    const [timer, setTimer] = useState(60)
    const [showResend, setShowResend] = useState(false)
    const { handleSubmit, control, setValue, watch } = useForm({
        defaultValues: {
            telegram_code: '',
        },
    })
    const {
        buttonLoading: isButtonLoading,
        setButtonLoading: setIsButtonLoading,
    } = useLoadingStateStore()

    const navigate = useNavigate()

    const onSubmitLogin = async ({ telegram_code }) => {
        const code = telegram_code
        setIsButtonLoading(true)
        await api
            .post(
                AUTH_TELEGRAM,
                { code, phone: phone },
                { headers: { 'Content-Type': 'application/json' } },
            )
            .then((response) => {
                setToken(response.data.access_token)
            })
            .then(() => navigate('/'))
            .catch((error) =>
                customToast({
                    message: error.response.data.message,
                    type: 'error',
                }),
            )
            .finally(() => setIsButtonLoading(false))
    }

    useEffect(() => {
        let timerId
        if (props.show && !showResend) {
            timerId = setInterval(() => {
                setTimer((timer) => {
                    if (timer <= 1) {
                        setShowResend(true)
                        clearInterval(timerId)
                        return 60
                    } else {
                        return timer - 1
                    }
                })
            }, 1000)
        }
        return () => {
            clearInterval(timerId)
        }
    }, [props.show, showResend])

    const onSubmit = handleSubmit(onSubmitLogin, FormToastError)

    useEffect(() => {
        const subscription = watch((data) => {
            if (data.telegram_code.length === 6) {
                onSubmit()
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const resend = async () => {
        setValue('telegram_code', '')
        setShowResend(false)
        await api
            .post(
                RESEND_TELEGRAM,
                { phone },
                { headers: { 'Content-Type': 'application/json' } },
            )
            .then((response) => setTelegramConfirm(response.data.tg_confirm))
            .catch((error) =>
                customToast({
                    message: error.response.data.message,
                    type: 'error',
                }),
            )
    }

    return (
        <Modal
            {...props}
            backdrop
            onSubmit={handleSubmit(onSubmitLogin)}
            dialogClassName="modal-dialog"
            contentClassName={s.modalContent}
            size="lg"
        >
            <Modal.Header closeButton />
            <Modal.Body className={s.modalBody}>
                <Modal.Title>
                    <div className={s.modalTitle}>
                        <h4>Введите код из Telegram</h4>
                    </div>
                </Modal.Title>
                <p className={s.phoneInfo}>
                    <span>Код отправлен на номер телефона </span>
                    {phone?.replace(
                        /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
                        '$1 $2 $3-$4-$5',
                    )}
                </p>
                <div className={s.modalInputSection}>
                    <form
                        onSubmit={handleSubmit(onSubmitLogin, FormToastError)}
                        className="d-flex"
                    >
                        {!isButtonLoading ? (
                            <Controller
                                name={`telegram_code`}
                                control={control}
                                rules={{
                                    required: 'Это поле обязательно',
                                }}
                                render={({ field }) => (
                                    <OtpInput
                                        {...field}
                                        numInputs={6}
                                        skipDefaultStyles
                                        inputType={'number'}
                                        renderInput={(props) => (
                                            <div className={s.inputDiv}>
                                                <input
                                                    {...props}
                                                    className={s.input}
                                                />
                                            </div>
                                        )}
                                    />
                                )}
                            />
                        ) : (
                            <LoadingSpinner
                                isLoading={isButtonLoading}
                                primaryColor="black"
                                secondaryColor="white"
                            />
                        )}
                    </form>
                </div>
                {!showResend && (
                    <p style={{ opacity: '0.5', fontSize: '12px' }}>
                        Запросить код повторно: {timer} секунд
                    </p>
                )}
                {showResend && (
                    <button
                        className={s.btn + ' btn-danger fw-bolder'}
                        onClick={resend}
                    >
                        Запросить код повторно
                    </button>
                )}
            </Modal.Body>
        </Modal>
    )
}
