import { create } from 'zustand'
import { IPaymentsData, IPaymentServices } from '@/types/IPayments.ts'

interface PaymentsStore {
    paymentServices: IPaymentServices | null
    setPaymentServices: (arg: IPaymentServices) => void
    selectedPaymentServices: IPaymentServices[]
    setSelectedPaymentServices: (arg: IPaymentServices[]) => void

    totalCost: number
    setTotalCost: (arg: number) => void
    inputCost: number | string
    setInputCost: (arg: number | string) => void
    confirmedIndexes: number[]

    paymentsData: IPaymentsData
    setPaymentsData: (arg: IPaymentsData) => void

    card: string
    setCard: (arg: string) => void
    savedCards: object | null
    setSavedCards: (arg: object) => void
    paymentType: string
    setPaymentType: (arg: string) => void
}

export const usePaymentsStore = create<PaymentsStore>()((set) => ({
    paymentServices: null, // { id: 217, name: '(М) Экзамен в ГИБДД (открытая площадка)', cost: 400 }
    setPaymentServices: (paymentServices) => set({ paymentServices }),
    selectedPaymentServices: [],
    setSelectedPaymentServices: (selectedPaymentServices) =>
        set({ selectedPaymentServices }),

    totalCost: null,
    setTotalCost: (totalCost) => set({ totalCost }),
    inputCost: null,
    setInputCost: (inputCost) => set({ inputCost }),
    confirmedIndexes: [],

    paymentsData: {
        payments: [{ price: 0, serviceId: 0, userCategory: 0 }],
        paymentType: '',
        card: '',
        is_save_card: false,
    },
    setPaymentsData: (paymentsData) => set({ paymentsData }),

    card: '',
    setCard: (card) => set({ card }),
    savedCards: null,
    setSavedCards: (savedCards) => set({ savedCards }),
    paymentType: '',
    setPaymentType: (paymentType) => set({ paymentType }),
}))
