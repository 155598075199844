import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { IPracticeData } from '@/types/IPracticeData.ts'

interface Props {
    practiceData: IPracticeData
}

const ExamCards = ({ practiceData }: Props) => {
    return (
        <>
            {!practiceData.exams.length && (
                <p className="text-body-tertiary">Занятий еще не было</p>
            )}

            {practiceData.exams.length > 0 &&
                practiceData.exams.map((exam, index) => (
                    <div key={index} className="practice__page-card card">
                        <div className="practice__page-card-body">
                            <div>
                                <p className="text-body-secondary">
                                    Предоставление авто{' | '}
                                    {format(exam.date, 'Pp', {
                                        locale: ru,
                                    })}
                                </p>
                                <h4>{exam.instructor.name}</h4>
                            </div>
                        </div>
                    </div>
                ))}
        </>
    )
}

export default ExamCards
