import Skeleton from 'react-loading-skeleton'

const PracticePageSkeleton = () => {
    return (
        <div className="page">
            <div className="col-12 mb-3">
                <div
                    className="col-6 col-md-4 col-lg-2 mb-3"
                    style={{ flex: '1' }}
                >
                    <Skeleton height={20} />
                </div>
                <div></div>
            </div>

            <div className="card shadow-sm mb-3">
                <div
                    className="col-12 position-relative d-flex justify-content-between align-items-center"
                    style={{ height: '50px' }}
                >
                    <div className="d-flex flex-column col-4 col-md-3 col-lg-2">
                        <Skeleton width="75%" />
                        <Skeleton width="80%" />
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <Skeleton width={120} />
                    </div>
                </div>
            </div>
            <div className="card shadow-sm mb-3">
                <div
                    className="col-12 position-relative d-flex justify-content-between align-items-center"
                    style={{ height: '50px' }}
                >
                    <div className="d-flex flex-column col-4 col-md-3 col-lg-2">
                        <Skeleton width="75%" />
                        <Skeleton width="80%" />
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <Skeleton width={120} />
                    </div>
                </div>
            </div>
            <div className="card shadow-sm mb-3">
                <div
                    className="col-12 position-relative d-flex justify-content-between align-items-center"
                    style={{ height: '50px' }}
                >
                    <div className="d-flex flex-column col-4 col-md-3 col-lg-2">
                        <Skeleton width="75%" />
                        <Skeleton width="80%" />
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <Skeleton width={120} />
                    </div>
                </div>
            </div>
            <div className="card shadow-sm mb-3">
                <div
                    className="col-12 position-relative d-flex justify-content-between align-items-center"
                    style={{ height: '50px' }}
                >
                    <div className="d-flex flex-column col-4 col-md-3 col-lg-2">
                        <Skeleton width="75%" />
                        <Skeleton width="80%" />
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <Skeleton width={120} />
                    </div>
                </div>
            </div>
            <div className="card shadow-sm mb-5">
                <div
                    className="col-12 position-relative d-flex justify-content-between align-items-center"
                    style={{ height: '50px' }}
                >
                    <div className="d-flex flex-column col-4 col-md-3 col-lg-2">
                        <Skeleton width="75%" />
                        <Skeleton width="80%" />
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <Skeleton width={120} />
                    </div>
                </div>
            </div>

            <div className="col-6 col-md-3 col-lg-2 mb-3" style={{ flex: '1' }}>
                <Skeleton height={20} />
            </div>
            <div className="card shadow-sm mb-3">
                <div
                    className="col-12 position-relative d-flex justify-content-between align-items-center"
                    style={{ height: '50px' }}
                >
                    <div className="d-flex flex-column col-4 col-md-3 col-lg-2">
                        <Skeleton width="75%" />
                        <Skeleton width="80%" />
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <Skeleton width={120} />
                    </div>
                </div>
            </div>
            <div className="card shadow-sm mb-3">
                <div
                    className="col-12 position-relative d-flex justify-content-between align-items-center"
                    style={{ height: '50px' }}
                >
                    <div className="d-flex flex-column col-4 col-md-3 col-lg-2">
                        <Skeleton width="75%" />
                        <Skeleton width="80%" />
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <Skeleton width={120} />
                    </div>
                </div>
            </div>
            <div className="card shadow-sm mb-3">
                <div
                    className="col-12 position-relative d-flex justify-content-between align-items-center"
                    style={{ height: '50px' }}
                >
                    <div className="d-flex flex-column col-4 col-md-3 col-lg-2">
                        <Skeleton width="75%" />
                        <Skeleton width="80%" />
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <Skeleton width={120} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PracticePageSkeleton
