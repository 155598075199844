import { useTicketsTypeStore } from '@/store/TicketsTypeStore.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function StatisticsHeader() {
    const { setActiveTicketsType, activeTicketsType } = useTicketsTypeStore()
    return (
        <div className="statistics__navbar navbar navbar-light ">
            <div className="statistics__navbar-tabs nav-tabs">
                <button
                    className={`nav-link statistics__navbar-tabs-tab ${
                        activeTicketsType === 'ABM' ? 'active' : ''
                    }`}
                    onClick={() => setActiveTicketsType('ABM')}
                >
                    <FontAwesomeIcon icon={['fal', 'car-rear']} size="1x" />
                    ABM
                </button>
                <button
                    className={`nav-link statistics__navbar-tabs-tab ${
                        activeTicketsType === 'CD' ? 'active' : ''
                    }`}
                    onClick={() => setActiveTicketsType('CD')}
                >
                    <FontAwesomeIcon icon={['fal', 'truck']} size="1x" />
                    CD
                </button>
            </div>
        </div>
    )
}
export default StatisticsHeader
