import { useTicketsTypeStore } from '@/store/TicketsTypeStore.ts'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMarathonStore } from '@/store/MarathonStore.ts'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function MarathonPreview() {
    const { activeTicketsType } = useTicketsTypeStore()
    const { marathonStatistic, setShowMarathonStatistic } = useMarathonStore()

    const navigate = useNavigate()

    useEffect(() => {
        marathonStatistic !== null ? setShowMarathonStatistic(true) : null
    }, [marathonStatistic])

    return (
        <div className="page">
            <h1 className="common__title">Марафон {activeTicketsType}</h1>
            <div className="marathon__preview-card card">
                <p className="marathon__preview-card-text">
                    <FontAwesomeIcon
                        icon={['fas', 'check']}
                        size="lg"
                        color="green"
                        className="me-1"
                    />
                    Билеты соответствуют ПДД от <b>01 марта 2023г</b>
                    <br />
                    Уверены, что выучили все билеты? Как насчет решить все 800
                    вопросов не по порядку?
                    <br />
                    Если захотите выйти и продолжить решать в другое время, то
                    промежуточный результат при прохождении марафона не будет
                    потерян. Вы продолжите прохождение с того места, где
                    остановились
                    <br />
                    Если захотите начать заново - удалите сохраненный марафон,
                    нажав кнопку "Завершить марафон"
                    <br />В марафоне доступны два вида настроек для удобства
                    решения. Если вы хотите изменить настройки, перейдите в
                    раздел "Настройки" под карточкой с вопросами. Изменить
                    настройки можно в любой момент решения марафона.
                </p>
                <div className="marathon__preview-card-buttons">
                    <Button
                        variant="outline-warning"
                        onClick={() => navigate('/tickets/marathon/start')}
                    >
                        Начать / Продолжить решение
                    </Button>
                    <Button
                        variant="outline-warning"
                        onClick={() => navigate('/tickets/marathon/statistics')}
                    >
                        Статистика
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default MarathonPreview
