import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTicketsTypeStore } from '@/store/TicketsTypeStore.ts'
import api from '@/api/api.ts'
import { useLoadingStateStore } from '@/store/LoadingStateStore.ts'
import { useTicketsQuestionsStore } from '@/store/TicketsQuestionsStore.ts'
import { useEffect } from 'react'
import QuestionsPreviewSkeleton from '@/Pages/Tickets/Exam/QuestionsPreviewSkeleton.tsx'
import { useUserQuery } from '@/lib/useUserQuery.ts'
import { IconName } from '@fortawesome/fontawesome-svg-core'

const icons: IconName[] = [
    'arrow-left',
    'arrow-up',
    'arrow-down',
    'arrow-right',
]

function ExamGuide() {
    const { activeTicketsType } = useTicketsTypeStore()
    const { setExamTicketsQuestions } = useTicketsQuestionsStore()
    const { setPageLoading: setIsPageLoading, pageLoading } =
        useLoadingStateStore()
    const user = useUserQuery() /*!todo+ useUserQuery*/

    const getExamQuestions = async () => {
        const getExamQuestionsURL = `/tickets/exam/${activeTicketsType}`
        setIsPageLoading(true)
        try {
            const res = await api.get(getExamQuestionsURL, {
                headers: { 'Content-Type': 'application/json' },
            })
            setExamTicketsQuestions(res.data)
        } catch (error) {
            console.error('Ошибка при получении', error)
        } finally {
            setIsPageLoading(false)
        }
    }

    const handleKeyboardInput = (e) => {
        /*!todo+ Используй e.key, эти поля устарели*/
        e.preventDefault()
        if (e.key === ' ') {
            getExamQuestions()
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyboardInput)

        return () => {
            window.removeEventListener('keydown', handleKeyboardInput)
        }
    }, [])

    return (
        <>
            {pageLoading && <QuestionsPreviewSkeleton />}
            {!pageLoading && (
                <>
                    <div className="exam__page-timer">
                        На решение экзамена дается 20 минут
                    </div>
                    <div className="exam__page">
                        <div className="exam__page-card card">
                            <div className="exam__page-card-title">
                                <h2>
                                    ЭКЗАМЕН НА ПРАВО УПРАВЛЕНИЯ ТРАНСПОРТНЫМ
                                    СРЕДСТВОМ
                                </h2>
                                <h2>
                                    <u>
                                        КАНДИДАТ,&nbsp;
                                        {user?.name}
                                    </u>
                                    {/*!todo+ Тут ФИО заглавными. В АПИ ФИО сейчас нет, добавлю, но заглавными делай сам (есть css стиль)*/}
                                </h2>
                            </div>
                            <div className="exam__page-card-start">
                                <p>Здравствуйте!</p>
                                <p>
                                    Чтобы начать ЭКЗАМЕН, ознакомьтесь с
                                    инструкцией ниже и нажмите <b>ПРОБЕЛ</b> или{' '}
                                    <Button
                                        variant="outline-warning py-1"
                                        onClick={() => getExamQuestions()}
                                    >
                                        Начать
                                    </Button>
                                </p>
                            </div>
                            <div className="exam__page-card-controls">
                                <p className="exam__page-card-controls-title">
                                    Инструкция по использованию:
                                </p>
                                <table className="table exam__page-card-controls-table">
                                    <tbody>
                                        <tr className="exam__page-card-controls-table-row">
                                            <td className="exam__page-card-controls-table-cell">
                                                Выбор экзаменационного вопроса
                                            </td>
                                            <td className="exam__page-card-controls-table-cell">
                                                клавишами
                                                {icons.map((icon, index) => (
                                                    <FontAwesomeIcon
                                                        key={index}
                                                        icon={['fas', icon]}
                                                        className="mx-1"
                                                    />
                                                ))}
                                            </td>
                                        </tr>
                                        <tr className="exam__page-card-controls-table-row">
                                            <td className="exam__page-card-controls-table-cell">
                                                Выбор варианта ответа
                                            </td>
                                            <td className="exam__page-card-controls-table-cell">
                                                клавиши
                                                {icons
                                                    .filter(
                                                        (_, index) =>
                                                            index === 1 ||
                                                            index === 2,
                                                    )
                                                    .map((icon, index) => (
                                                        <FontAwesomeIcon
                                                            key={index}
                                                            icon={['fas', icon]}
                                                            className="mx-1"
                                                        />
                                                    ))}
                                            </td>
                                        </tr>
                                        <tr className="exam__page-card-controls-table-row">
                                            <td className="exam__page-card-controls-table-cell">
                                                Подтверждение выбранного
                                                варианта ответа
                                            </td>
                                            <td className="exam__page-card-controls-table-cell">
                                                клавиша <b>Пробел</b>
                                            </td>
                                        </tr>
                                        <tr className="exam__page-card-controls-table-row">
                                            <td className="exam__page-card-controls-table-cell">
                                                Перейти к следующему вопросу
                                            </td>
                                            <td className="exam__page-card-controls-table-cell">
                                                клавиши
                                                {icons
                                                    .filter(
                                                        (_, index) =>
                                                            index === 0 ||
                                                            index === 3,
                                                    )
                                                    .map((icon, index) => (
                                                        <FontAwesomeIcon
                                                            key={index}
                                                            icon={['fas', icon]}
                                                            className="mx-1"
                                                        />
                                                    ))}
                                            </td>
                                        </tr>
                                        <tr className="exam__page-card-controls-table-row">
                                            <td className="exam__page-card-controls-table-cell">
                                                Вернуться к списку вопросов
                                            </td>
                                            <td className="exam__page-card-controls-table-cell">
                                                клавиша <b>ESC</b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default ExamGuide
