import { create } from 'zustand'

interface ProfileCountDownStore {
    initialDate: Date
    setInitialDate: (arg: Date) => void
    minutes: number
    setMinutes: (arg: number) => void
    seconds: number
    setSeconds: (arg: number) => void
    resetCount: () => void
}

export const useProfileCountdownStore = create<ProfileCountDownStore>()(
    (set) => ({
        minutes: 0,
        setMinutes: (minutes) => set({ minutes }),
        initialDate: null,
        setInitialDate: (initialDate) => set({ initialDate }),
        seconds: 60,
        setSeconds: (seconds) => set({ seconds }),
        resetCount: () => {
            set({
                minutes: 0,
                seconds: 60,
            })
        },
    }),
)
