import { useEffect, useRef, useState } from 'react'
import { useVideoListStore } from '@/store/VideoListStore.ts'
import Plyr, { Options } from 'plyr'
import Hls from 'hls.js'
import 'plyr/dist/plyr.css'
import {
    postCompleteVideo,
    updateTime,
} from '@/Pages/VideoLessons/VideoLessonsHelpers.ts'
import VideoPlayerFooter from '@/Pages/VideoLessons/VideoPlayerFooter.tsx'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const i18nObject = {
    restart: 'Перезапустить',
    rewind: '{seektime} сек.',
    play: 'Смотреть',
    pause: 'Пауза',
    fastForward: 'Forward {seektime}s',
    seek: 'Seek',
    seekLabel: '{currentTime} из {duration}',
    played: 'Played',
    buffered: 'Buffered',
    currentTime: 'Текущее время',
    duration: 'Продолжительность',
    volume: 'Громкость',
    mute: 'Заглушить звук',
    unmute: 'Вернуть звук',
    enableCaptions: 'Enable captions',
    disableCaptions: 'Disable captions',
    download: 'Download',
    enterFullscreen: 'На весь экран',
    exitFullscreen: 'Свернуть в окно',
    frameTitle: 'Player for {title}',
    captions: 'Captions',
    settings: 'Настройки',
    menuBack: 'Go back to previous menu',
    speed: 'Скорость',
    normal: 'Обычная',
    quality: 'Качество',
    loop: 'Loop',
    start: 'Start',
    end: 'End',
    all: 'All',
    reset: 'Reset',
    disabled: 'Disabled',
    enabled: 'Enabled',
    advertisement: 'Ad',
    qualityBadge: {
        2160: '4K',
        1440: 'QHD',
        1080: 'FHD',
        720: 'HD',
        576: 'SD',
        480: 'SD',
    },
}

const VideoLessonsPlayer = () => {
    const { currentVideo, setCurrentVideo } = useVideoListStore()
    const hlsSource = currentVideo?.s3
    const mp4Source = currentVideo?.link
    const isSeekable = currentVideo?.rewind
    const currentTime = currentVideo?.time
    const isViewed = currentVideo?.viewed
    const isOpened = currentVideo?.open

    const [defaultOptions, setDefaultOptions] = useState<Options>({
        debug: true /*!todo+ Думаю надо поставить false*/,
        controls: [
            'play-large',
            'current-time',
            'play',
            'restart',
            'rewind',
            'progress',
            'mute',
            'volume',
            'settings',
            'fullscreen',
        ],
        displayDuration: false,
        tooltips: {
            controls: true,
            seek: isSeekable,
        },
        speed: {
            selected: 1,
            options: [1, 1.25, 1.5, 1.75, 2],
        },
        invertTime: true,
        i18n: i18nObject,
        resetOnEnd: true,
        listeners: {
            seek(e) {
                if (!isSeekable && (!isOpened || !isViewed)) {
                    e.preventDefault()
                    return false
                }
            },
        },
    })

    const videoContainerRef = useRef(null)
    const playerRef = useRef<Plyr | null>(null)
    let updateInterval

    function clearUpdateInterval() {
        clearInterval(updateInterval) // clear updating time when video ended
    }

    function startUpdateInterval(instance) {
        /*!todo Вместо setInterval лучше рекурсивный async setTimeout
            При медленной скорости может запросы слишком часто отправлять
            setTimeout(async function updateTimePoll() { await updateTime(); updateInterval = setTimeout(updateTimePoll)})
        *    */
        updateInterval = setInterval(() => {
            updateTime(instance.currentTime, instance.duration) // send post request to update time
        }, 5000)
    }

    const registerEvents = () => {
        if (isViewed) {
            playerRef.current.on('ready', (event) => {
                const instance = event.detail.plyr /*!todo Дублируется */
                instance.currentTime = 0 // setup current time to 0
                instance.poster =
                    'https://онлайн.лидерпро.рф/img/video-poster.jpg'
            })
        } else {
            playerRef.current.on('ready', (event) => {
                const instance = event.detail.plyr
                instance.poster =
                    'https://онлайн.лидерпро.рф/img/video-poster.jpg'
            })
            playerRef.current.once('canplaythrough', (event) => {
                const instance = event.detail.plyr
                instance.currentTime = currentTime / 1000 // setup last viewed time
            })
        }
        playerRef.current.on('play', (event) => {
            const instance = event.detail.plyr
            startUpdateInterval(instance)
        })
        playerRef.current.on('pause', (event) => {
            clearUpdateInterval()
        })
        playerRef.current.once('ended', (event) => {
            clearUpdateInterval()
            const instance = event.detail.plyr
            updateTime(instance.currentTime, instance.duration).then(
                () => postCompleteVideo(), // update time then post complete video when video ended
            )
        })
    }

    useEffect(() => {
        let hls
        const videoElement = videoContainerRef.current
        if (!hlsSource && mp4Source) {
            videoElement.source = mp4Source
                .replace('https://онлайн.лидерпро.рф/s3/media/', '')
                .replace('/video.mp4', '')
            const youtubeLink = `https://www.youtube.com/watch?v=${videoElement.source}`

            playerRef.current = new Plyr(videoElement, defaultOptions)
            playerRef.current.source = {
                type: 'video',
                sources: [
                    {
                        src: youtubeLink,
                        provider: 'youtube',
                    },
                ],
            }
            registerEvents()
        }

        if (hlsSource) {
            if (Hls.isSupported()) {
                hls = new Hls()
                hls.loadSource(hlsSource)

                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    const qualities = hls.levels.map((level) => level.height)
                    defaultOptions.quality = {
                        default: qualities[0],
                        options: qualities,
                        forced: true,
                        onChange: (newQuality) => {
                            hls.levels.forEach((level, levelIndex) => {
                                if (level.height === newQuality) {
                                    hls.currentLevel = levelIndex
                                }
                            })
                        },
                    }
                    playerRef.current = new Plyr(videoElement, defaultOptions)
                    registerEvents()
                })

                hls.attachMedia(videoElement)
            } else if (
                videoElement.canPlayType('application/vnd.apple.mpegurl')
            ) {
                hls = new Hls()
                hls.loadSource(hlsSource)
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    const availableSizes = hls.levels.map((l) => l.height)
                    const availableUrls = hls.levels.map((l) => l.url[0])

                    availableUrls.forEach((level, index) => {
                        const videoSource = document.createElement('source')
                        videoSource.setAttribute('src', level)
                        videoSource.setAttribute('size', availableSizes[index])
                        videoElement.append(videoSource)
                    })
                })
                videoElement.addEventListener('loadedmetadata', () => {
                    playerRef.current = new Plyr(videoElement, defaultOptions)
                    registerEvents()
                })
            } else {
                toast('Видео не поддерживается вашим браузером')
            }
        }

        return () => {
            hls?.destroy()
            playerRef.current?.destroy()
            playerRef.current = null
            clearUpdateInterval()
            // clean player
        }
    }, [currentVideo])

    return (
        <div className="video__player">
            <FontAwesomeIcon
                icon={['fas', 'xmark']}
                className="video__player-close-icon"
                onClick={() => setCurrentVideo(null)}
            />
            <video ref={videoContainerRef} playsInline muted={false}></video>
            <VideoPlayerFooter />
        </div>
    )
}

export default VideoLessonsPlayer
