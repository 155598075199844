import PaymentConfirmList from '@/Pages/PaymentsPage/PaymentConfirmList.tsx'
import PaymentMethod from '@/Pages/PaymentsPage/PaymentMethod.tsx'
import {
    PaymentCategoryServices,
    PaymentCategoryVariants,
} from '@/Pages/PaymentsPage/PaymentCategoryHelpers.tsx'
import { useState } from 'react'

const Payment = () => {
    const [step, setStep] = useState('variants')
    const currentStep = () => {
        if (step === 'method') {
            return <PaymentMethod setStep={setStep} />
        } else if (step === 'confirm') {
            return <PaymentConfirmList setStep={setStep} />
        } else if (step === 'services') {
            return <PaymentCategoryServices setStep={setStep} />
        } else if (step === 'variants') {
            return (
                <>
                    <h3 className="main__card-title common__title">
                        Выберите категорию
                    </h3>
                    <div className="main__card-body">
                        <PaymentCategoryVariants setStep={setStep} />
                    </div>
                </>
            )
        } else {
            return null
        }
    }

    return <div className="main__card card">{currentStep()}</div>
}

export default Payment
