import { formatTime } from '@/Pages/Tickets/Marathon/MarathonHelpers.tsx'
import { IMarathonStatistics } from '@/types/IStatistics.ts'
import { useState } from 'react'

interface Props {
    statistic: IMarathonStatistics[]
}

const MarathonStatistic = ({ statistic }: Props) => {
    const [marathonStatistic, setMarathonStatistic] = useState(statistic)

    return (
        <>
            <h4 className="common__title">
                Статистика по марафонам
                <p className="fw-normal">
                    Здесь отображаются все попытки решения марафона
                </p>
            </h4>
            <div className="tickets__statistic-card card mb-0">
                <table className="table table-sm table-striped table-bordered mb-0">
                    <thead className="thead-invert">
                        <tr className="text-center">
                            <th>Дата</th>
                            <th>Прав.</th>
                            <th>Непр.</th>
                            <th>Время решения</th>
                        </tr>
                    </thead>
                    <tbody>
                        {marathonStatistic?.map((item, index) => (
                            <tr key={index} className="text-center">
                                <td>{item.date}</td>
                                <td>
                                    {item.correctAnswers} {/*!todo+ без ?. */}
                                </td>
                                <td>{item.incorrectAnswers}</td>
                                <td>{formatTime(item.time)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default MarathonStatistic
