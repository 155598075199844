import { useActiveTabStore } from '@/store/ActiveTabStore.ts'
import { useQuery } from '@tanstack/react-query'
import api from '@/api/api.ts'
import SchoolExamsModalSkeleton from '@/Pages/MainPage/MainPageCards/SchoolExamsModalSkeleton.tsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SchoolExamResultsModalBody = () => {
    const activeTab = useActiveTabStore((state) => state.activeTab)
    const schoolExamResultsQuery = useQuery({
        queryKey: ['schoolExamResults', 'category', activeTab],
        queryFn: () =>
            api
                .get(`/user/category/${activeTab}/theory_exam_results`)
                .then((res) => res.data),
        staleTime: 60000,
    })

    const isResultsLoading = schoolExamResultsQuery.isLoading
    const resultsData = schoolExamResultsQuery?.data

    return (
        <>
            {isResultsLoading && <SchoolExamsModalSkeleton />}
            {!isResultsLoading && (
                <>
                    <h4 className="fs-lg-18 fs-md-16 fs-sm-14 mt-1 mb-4">
                        Внутренние экзамены «Теория»
                    </h4>
                    {resultsData.map((exam, index) => (
                        <div
                            key={index}
                            className="fs-lg-14 fs-md-12 fs-sm-11 mb-3"
                        >
                            <div className="d-flex fw-bold">
                                <span className="me-1">
                                    {exam.status ? (
                                        <FontAwesomeIcon
                                            icon={['fas', 'check']}
                                            color="green"
                                            width={15}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={['fas', 'xmark']}
                                            color="red"
                                            width={15}
                                        />
                                    )}
                                    {' | '}
                                </span>
                                <span className="me-1">
                                    <FontAwesomeIcon
                                        icon={['fal', 'calendar']}
                                        color="black"
                                        className="me-1"
                                    />
                                    {exam.date}
                                    {' | '}
                                </span>
                                <span>{exam.name}</span>
                            </div>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: exam.result,
                                }}
                            />
                        </div>
                    ))}
                </>
            )}
        </>
    )
}

export default SchoolExamResultsModalBody
