import { NumberFormatBase, usePatternFormat } from 'react-number-format'
import { forwardRef } from 'react'

const PhoneFormat = forwardRef((props: any, ref) => {
    const { format, getCaretBoundary, ...rest } = usePatternFormat({
        ...props,
        placeholder: '+7 ',
        format: '+# ### ###-##-##',
        mask: '_',
    })

    /**
     *
     * @param {string} value
     * @returns {string}
     * @private
     */
    const _format = (value: string) => {
        if (!value) return format(value)
        if (value.startsWith('7') || value.startsWith('8')) {
            value = '7' + value.slice(1)
        }
        if (!value.startsWith('7')) {
            value = '7' + value
        }
        return format(value)
    }

    const _getCaretBoundary = (formattedValue) => {
        const boundary = getCaretBoundary(formattedValue)
        //prevents placing numbers before prefix
        boundary[0] = false
        boundary[1] = false
        return boundary
    }

    return (
        <NumberFormatBase
            getInputRef={ref}
            format={_format}
            getCaretBoundary={_getCaretBoundary}
            {...rest}
        />
    )
})
PhoneFormat.displayName = 'PhoneFormat'
export default PhoneFormat
