import './SchoolExams.scss'
import SchoolExamsCards from '@/Pages/SchoolExamsPage/SchoolExamsCards.tsx'
import { useActiveTabStore } from '@/store/ActiveTabStore.ts'
import SchoolExamsSkeleton from '@/Pages/SchoolExamsPage/SchoolExamsSkeleton.tsx'
import MainPageHeader from '@/Pages/MainPage/MainPageHeader.tsx'
import api from '@/api/api.ts'
import { useQuery } from '@tanstack/react-query'
import { useUserQuery } from '@/lib/useUserQuery.ts'

const SchoolExamsPage = () => {
    const { activeTab } = useActiveTabStore()

    const user = useUserQuery()
    const schoolInfo = useQuery({
        queryKey: ['school', 'info', user],
        queryFn: async () => await api.get('info'),
        staleTime: Infinity,
        enabled: !!user,
    })

    const { data: schoolExamsData, isSuccess } = useQuery({
        queryKey: ['schoolExamResults', activeTab],
        queryFn: async () =>
            await api.get(`/school_exam/category/${activeTab}`),
        enabled: !!activeTab && !!schoolInfo.data,
    })

    return (
        <>
            <MainPageHeader />
            {!isSuccess ? (
                <SchoolExamsSkeleton />
            ) : (
                <SchoolExamsCards examResults={schoolExamsData.data.exams} />
            )}
        </>
    )
}

export default SchoolExamsPage
