import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { SubTheme, Ticket } from '@/types/IStatistics.ts'

interface Props {
    statistic: Ticket[]
}

function ThemeTicketsStatistics({ statistic }: Props) {
    const tableRef = useRef(null)
    const subThemeTableRef = useRef(null)
    const [themeTicketsStatistic, setThemeTicketsStatistic] =
        useState(statistic)
    const [currentThemeTicket, setCurrentThemeTicket] = useState<Ticket>(null)
    const [subThemeTicketsStatistic, setSubThemeTicketsStatistic] =
        useState<SubTheme>(null)

    useEffect(() => {
        tableRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        })
        subThemeTableRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        })
    }, [currentThemeTicket])

    return (
        <>
            <h2 className="common__title">
                Статистика по темам
                <p className="fw-normal">
                    Выберите номер билета, чтобы посмотреть статистику{' '}
                    {/*!todo Выберите тему*/}
                </p>
            </h2>
            <div className="tickets__statistic-card card">
                <div className="tickets__statistic-card-buttons">
                    {themeTicketsStatistic?.map((ticket, index) => (
                        <Button
                            variant="outline-warning"
                            key={index}
                            onClick={() => {
                                setCurrentThemeTicket(ticket)
                                setSubThemeTicketsStatistic(null)
                            }}
                            className={`tickets__statistic-card-buttons-theme-btn ${
                                currentThemeTicket === ticket ? 'active' : ''
                            }`}
                        >
                            {ticket.name}
                        </Button>
                    ))}
                </div>
                {/*!todo currentThemeTicket && (Для родительских темы тоже можно решать, сейчас их попытки не показываются*/}
                {currentThemeTicket?.statistics && (
                    <div className="tickets__statistic-card-body">
                        {currentThemeTicket.statistics.length > 0 ? (
                            <>
                                <table
                                    className="table table-bordered table-striped table-sm m-0"
                                    ref={tableRef}
                                >
                                    <thead className="thead-invert">
                                        <tr className="text-center">
                                            <th>Дата</th>
                                            <th>Верно</th>
                                            <th>Неверно</th>
                                            <th>Ошибки</th>
                                            <th>Результат</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentThemeTicket.statistics.map(
                                            (statistic, index) => (
                                                <tr
                                                    className="text-center"
                                                    key={index}
                                                >
                                                    <td className="text-break">
                                                        {statistic.date}
                                                    </td>
                                                    <td className="text-break">
                                                        {statistic.correct}
                                                    </td>
                                                    <td className="text-break">
                                                        {statistic.incorrect}
                                                    </td>
                                                    <td className="text-break">
                                                        {statistic.errors}
                                                    </td>
                                                    <td className="text-break">
                                                        {statistic.status ? (
                                                            <FontAwesomeIcon
                                                                icon={[
                                                                    'fal',
                                                                    'circle-check',
                                                                ]}
                                                                color="green"
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={[
                                                                    'fal',
                                                                    'circle-xmark',
                                                                ]}
                                                                color="red"
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <h4 className="common__title" ref={tableRef}>
                                Не хватает данных для выбранной темы
                            </h4>
                        )}
                    </div>
                )}
                <div>
                    {currentThemeTicket?.sub_themes && (
                        <>
                            <h4
                                className="common__title mb-2"
                                ref={subThemeTableRef}
                            >
                                Выберите подтему:
                            </h4>
                            <div className="tickets__statistic-card-buttons">
                                {currentThemeTicket.sub_themes.map(
                                    (sub_theme, index) => (
                                        <Button
                                            variant="outline-warning"
                                            key={index}
                                            onClick={() =>
                                                setSubThemeTicketsStatistic(
                                                    sub_theme,
                                                )
                                            }
                                            className={`tickets__statistic-card-buttons-theme-btn ${
                                                subThemeTicketsStatistic ===
                                                sub_theme
                                                    ? 'active'
                                                    : ''
                                            }`}
                                        >
                                            {sub_theme?.name}{' '}
                                            {/*!todo+ Без ID*/}
                                        </Button>
                                    ),
                                )}
                            </div>
                            <>
                                {subThemeTicketsStatistic?.statistics?.length >
                                0 ? (
                                    <table
                                        className="table table-bordered table-striped table-sm mt-3 mb-0"
                                        ref={subThemeTableRef}
                                    >
                                        <thead className="thead-invert">
                                            <tr className="text-center">
                                                <th>Дата</th>
                                                <th>Верно</th>
                                                <th>Неверно</th>
                                                <th>Ошибки</th>
                                                <th>Результат</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subThemeTicketsStatistic.statistics.map(
                                                (sub_theme, index) => (
                                                    <tr
                                                        key={index}
                                                        className="text-center"
                                                    >
                                                        <td className="text-break">
                                                            {sub_theme.date}
                                                        </td>
                                                        <td className="text-break">
                                                            {sub_theme.correct}
                                                        </td>
                                                        <td className="text-break">
                                                            {
                                                                sub_theme.incorrect
                                                            }
                                                        </td>
                                                        <td className="text-break">
                                                            {sub_theme.errors}
                                                        </td>
                                                        <td className="text-break">
                                                            {sub_theme.status ? (
                                                                <FontAwesomeIcon
                                                                    icon={[
                                                                        'fal',
                                                                        'circle-check',
                                                                    ]}
                                                                    color="green"
                                                                />
                                                            ) : (
                                                                <FontAwesomeIcon
                                                                    icon={[
                                                                        'fal',
                                                                        'circle-xmark',
                                                                    ]}
                                                                    color="red"
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                        </tbody>
                                    </table>
                                ) : (
                                    <h4
                                        className="mt-3"
                                        ref={subThemeTableRef}
                                        style={{
                                            display:
                                                subThemeTicketsStatistic !==
                                                null
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                    >
                                        Не хватает данных для выбранной подтемы
                                    </h4>
                                )}
                            </>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}
export default ThemeTicketsStatistics
