import { Controller, useForm } from 'react-hook-form'
import api from '@/api/api.ts'
import { Link, useNavigate } from 'react-router-dom'
import { PatternFormat } from 'react-number-format'
import PhoneFormat from '@/components/PhoneFormat.tsx'
import { FormToastError } from '@/lib/FormToastError.ts'
import s from '@/Pages/LoginPage/LoginPage.module.scss'
import { LoadingSpinner } from '@/components/Forms/Loading.tsx'
import { useState } from 'react'
import PasswordRequirements from '@/Pages/LoginPage/PasswordRequirements.tsx'
import { customToast } from '@/lib/CustomToast.ts'
import { useAuthStatusStore } from '@/store/AuthStatusStore.ts'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

const REGISTER_URL = '/auth/register'
interface IData {
    code: string
    password: string
    password_confirmation: string
    phone: string
}

export default function RegisterPage() {
    const { setRegisterConfirmed } = useAuthStatusStore()
    const navigate = useNavigate()
    const { register, watch, handleSubmit, control } = useForm({
        defaultValues: {
            phone: '',
            code: '',
            password: '',
            password_confirmation: '',
        },
    })

    //!todo+ Эти поля уже есть в форме, лучше использовать watch из useForm
    const phoneEntered = watch('phone')
    const codeEntered = watch('code')
    //!todo+ Эти поля можно убрать из state и вычислять просто в рендере
    // const [passwordFieldVisible, setPasswordFieldVisible] = useState(false)
    // const [phoneFieldVisible, setPhoneFieldVisible] = useState(false)
    const [isPasswordValidatorVisible, setIsPasswordValidatorVisible] =
        useState(false)

    const cleanPhone = (phone: string) => phone.replace(/[\s-\D]/g, '')
    const passwordValidatorVisible = isPasswordValidatorVisible
        ? 'visible'
        : 'hidden'

    const registerMutation = useMutation<any, AxiosError<any>, IData>({
        mutationFn(data) {
            return api.post(REGISTER_URL, data, {
                headers: { 'Content-Type': 'application/json' },
            })
        },
        onSuccess(response) {
            customToast({
                message: response.data.message,
                type: 'success',
            })
            setRegisterConfirmed(true)
            navigate('/login')
        },
        onError(error) {
            customToast({
                message: error.response.data.message,
                type: 'error',
            })
        },
    })

    async function registerUser(data: IData) {
        data.phone = cleanPhone(data.phone)
        registerMutation.mutate(data)
        //!todo+ Убрать then
    }

    //!todo+ Эти поля можно убрать из state и вычислять просто в рендере
    const phoneFieldVisible = codeEntered.length === 13
    const passwordFieldVisible = phoneEntered.length === 11

    return (
        <div className="authorization__form-container">
            <h1 className="authorization__form-header">Регистрация</h1>
            <form onSubmit={handleSubmit(registerUser, FormToastError)}>
                <div className="authorization__form-group">
                    <label
                        htmlFor="code"
                        className="authorization__form-group-label"
                    >
                        Код регистрации
                    </label>
                    <Controller
                        name="code"
                        control={control}
                        rules={{
                            required: 'Поле Код обязательно для заполнения',
                            validate: {
                                minLengthMask: (v: string) =>
                                    v.replace(/[^\d]/g, '').length == 13
                                        ? true
                                        : 'Поле Код обязательно для заполнения',
                            },
                        }}
                        render={({ field: { onChange } }) => (
                            <PatternFormat
                                id="code"
                                className="authorization__form-group-input form-control"
                                placeholder="xx-xxxxxx-xxxxx"
                                format="##-######-#####"
                                mask="_"
                                onValueChange={(values) => {
                                    onChange(values.value)
                                }}
                            />
                        )}
                    />
                    {phoneFieldVisible && (
                        <>
                            <label
                                htmlFor="phone"
                                className="authorization__form-group-label"
                            >
                                Номер телефона
                            </label>
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    required:
                                        'Поле Номер телефона обязательно для заполнения',
                                    validate: {
                                        minLengthPhone: (v: string) =>
                                            v.replace(/[\s-\D]/g, '').length ===
                                            11
                                                ? true
                                                : 'Поле обязательно для заполнения',
                                    },
                                }}
                                render={({ field: { onChange } }) => (
                                    <PhoneFormat
                                        id="login-phone"
                                        className="authorization__form-group-input form-control"
                                        onValueChange={(values) => {
                                            onChange(values.value)
                                        }}
                                    />
                                )}
                            />
                        </>
                    )}
                    {passwordFieldVisible && (
                        <>
                            <label
                                htmlFor="password"
                                className="authorization__form-group-label"
                            >
                                Введите пароль
                            </label>
                            <div className="position-relative">
                                <input
                                    className="authorization__form-group-input form-control"
                                    type="password"
                                    id="password"
                                    autoComplete="off"
                                    onFocus={() =>
                                        setIsPasswordValidatorVisible(true)
                                    }
                                    {...register('password', {
                                        onBlur: () =>
                                            setIsPasswordValidatorVisible(
                                                false,
                                            ),
                                        required:
                                            'Поле пароль обязательно для заполнения',
                                        validate: {
                                            minLength: (v) =>
                                                v.length >= 8 ||
                                                'Пароль должен содержать минимум 8 символов',
                                            hasUppercase: (v) =>
                                                /[A-Z]/.test(v) ||
                                                'Пароль должен содержать хотя бы одну заглавную букву',
                                            hasLowercase: (v) =>
                                                /[a-z]/.test(v) ||
                                                'Пароль должен содержать хотя бы одну строчную букву',
                                            hasDigit: (v) =>
                                                /[0-9]/.test(v) ||
                                                'Пароль должен содержать хотя бы одну цифру',
                                            hasSpecialChar: (v) =>
                                                /[!@#$%^&*(),.?":{}|<>]/.test(
                                                    v,
                                                ) ||
                                                'Пароль должен содержать хотя бы один специальный символ',
                                        },
                                    })}
                                />

                                <PasswordRequirements
                                    password={watch('password')}
                                    className={passwordValidatorVisible}
                                />
                            </div>

                            <>
                                <label
                                    htmlFor="password_confirmation"
                                    className="authorization__form-group-label"
                                >
                                    Повторите пароль
                                </label>
                                <input
                                    className="authorization__form-group-input form-control"
                                    type="password"
                                    id="password_confirmation"
                                    autoComplete="off"
                                    {...register('password_confirmation', {
                                        required:
                                            'Поле Повторите пароль обязательно для заполнения',
                                        validate: (val: string) => {
                                            if (watch('password') != val) {
                                                return 'Пароли не совпадают'
                                            }
                                        },
                                    })}
                                />
                            </>
                        </>
                    )}
                </div>

                <p className="authorization__page-footer-text mb-2">
                    Нажимая кнопку «Регистрация», Вы подтверждаете, что
                    ознакомились c <br />
                    <Link
                        to="https://xn--80asehdb.xn--d1achlsfgb.xn--p1ai/legal/policy.pdf"
                        target="_blank"
                    >
                        Политикой конфиденциальности
                    </Link>
                    &nbsp; и принимаете &nbsp;
                    <Link
                        to="https://xn--80asehdb.xn--d1achlsfgb.xn--p1ai/legal/rules.pdf"
                        target="_blank"
                    >
                        Пользовательское соглашение
                    </Link>
                </p>

                <button
                    type="submit"
                    className="btn btn-danger authorization__form-button"
                >
                    {!registerMutation.isPending ? (
                        <>Зарегистрироваться</>
                    ) : (
                        <LoadingSpinner
                            isLoading={registerMutation.isPending}
                            primaryColor="white"
                            secondaryColor="white"
                        />
                    )}
                </button>

                <Link
                    to="/login"
                    className="authorization__form-already-registered-link"
                >
                    Уже зарегистрированы?
                </Link>
            </form>
        </div>
    )
}
