import './Payment.scss'
import Payment from '@/Pages/PaymentsPage/Payment.tsx'
import UserBalance from '@/Pages/PaymentsPage/UserBalance.tsx'
import PaymentHistory from '@/Pages/PaymentsPage/PaymentHistory.tsx'
import { PaymentCategoryHeader } from '@/Pages/PaymentsPage/PaymentCategoryHelpers.tsx'
import PaymentsPageSkeleton from '@/Pages/PaymentsPage/PaymentsPageSkeleton.tsx'
import { useQuery } from '@tanstack/react-query'
import api from '@/api/api.ts'
import { useUserQuery } from '@/lib/useUserQuery.ts'

const PaymentsPage = () => {
    const user = useUserQuery()
    const schoolInfo = useQuery({
        queryKey: ['school', 'info', user],
        queryFn: async () => await api.get('info'),
        staleTime: Infinity,
        enabled: !!user,
    })

    const { data: balanceData } = useQuery({
        queryKey: ['user', 'balance'],
        queryFn: () => api.get('/payment/categories/balance'),
        enabled: !!schoolInfo.data,
    })

    const { data: historyData } = useQuery({
        queryKey: ['user', 'payment', 'history'],
        queryFn: () => api.get('/payment/history'),
        enabled: !!balanceData,
    })

    return (
        <div className="payments__page page">
            {!historyData ? (
                <PaymentsPageSkeleton />
            ) : (
                <>
                    <div className="payments__page-content">
                        <div className="payments__page-content-main">
                            <PaymentCategoryHeader />
                            <Payment />
                        </div>
                        <div className="payments__page-content-balance">
                            <UserBalance
                                userBalance={balanceData?.data.balance}
                            />
                        </div>
                        <div className="payments__page-content-history">
                            <PaymentHistory
                                paymentHistory={historyData?.data.refills}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default PaymentsPage
