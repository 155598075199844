import { create } from 'zustand'

interface ExamStore {
    answers: Array<string>
    setAnswers: (answers: Array<string> | null) => void
    answeredIndexes: Array<string>
    setAnsweredIndexes: (answeredIndexes: Array<string>) => void
    correctAnswersCount: number
    setCorrectAnswersCount: (correctAnswersCount: number) => void
    errorAnswersCount: number
    setErrorAnswersCount: (errorAnswersCount: number) => void
    errorsList: Array<string>
    setErrorsList: (errorsList: Array<string> | null) => void
    showResults: boolean
    setShowResults: (showResults: boolean) => void
    ticketsEndModalShow: boolean
    setTicketsEndModalShow: (ticketsEndModalShow: boolean) => void
    timerEndModalShow: boolean
    setTimerEndModalShow: (timerEndModalShow: boolean) => void
    reset: () => void
}

export const useExamStore = create<ExamStore>()((set) => ({
    answers: [],
    setAnswers: (answers) => set({ answers }),
    answeredIndexes: [],
    setAnsweredIndexes: (answeredIndexes) => set({ answeredIndexes }),
    correctAnswersCount: 0,
    setCorrectAnswersCount: (correctAnswersCount) =>
        set({ correctAnswersCount }),
    errorAnswersCount: 0,
    setErrorAnswersCount: (errorAnswersCount) => set({ errorAnswersCount }),
    errorsList: [],
    setErrorsList: (errorsList) => set({ errorsList }),
    showResults: false,
    setShowResults: (showResults) => set({ showResults }),
    ticketsEndModalShow: false,
    setTicketsEndModalShow: (ticketsEndModalShow) =>
        set({ ticketsEndModalShow }),
    timerEndModalShow: false,
    setTimerEndModalShow: (timerEndModalShow) => set({ timerEndModalShow }),
    reset: () => {
        set({
            answers: [],
            answeredIndexes: [],
            correctAnswersCount: 0,
            errorAnswersCount: 0,
            errorsList: [],
            showResults: false,
            ticketsEndModalShow: false,
            timerEndModalShow: false,
        })
    },
}))
