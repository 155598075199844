import {
    UndefinedInitialDataOptions,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from '@tanstack/react-query'
import api from '@/api/api.ts'
import { useEffect } from 'react'
import { useActiveTabStore } from '@/store/ActiveTabStore.ts'
import { useUserDataStore } from '@/store/UserDataStore.ts'
import { IUser } from '@/types/IUser.ts'

export const useUserQuery = (
    options: Omit<UndefinedInitialDataOptions, 'queryKey' | 'queryFn'> = {},
) => {
    const { activeTab, setActiveTab } = useActiveTabStore()
    const { setUser, user } = useUserDataStore()
    const queryClient = useQueryClient()

    const { data: userData }: UseQueryResult<IUser> = useQuery({
        queryKey: ['user'],
        queryFn: () =>
            api
                .get('/user')
                .then(
                    (res) => res.data,
                ) /*!todo Стоит сразу возвращать дату из ответа - api.get().then(res => res.data)*/,
        staleTime: Infinity,
        ...options,
    })

    const currentCategory = userData?.category

    useEffect(() => {
        if (currentCategory) {
            queryClient.setQueryData(
                ['user', 'category', currentCategory.id],
                currentCategory,
            )

            if (activeTab === null) {
                setActiveTab(currentCategory.id)
            }

            if (!user) {
                setUser(userData)
            }
        }
    }, [currentCategory, activeTab, user])
    return userData
}
