import Skeleton from 'react-loading-skeleton'

const NotificationsModalSkeleton = () => {
    return (
        <>
            <div className="d-flex flex-row align-items-center py-3">
                <div>
                    <Skeleton
                        circle
                        enableAnimation={false}
                        width={70}
                        height={70}
                    />
                </div>
                <div className="flex-grow-1 px-3">
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="d-flex flex-row align-items-center py-3">
                <div>
                    <Skeleton
                        circle
                        enableAnimation={false}
                        width={70}
                        height={70}
                    />
                </div>
                <div className="flex-grow-1 px-3">
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="d-flex flex-row align-items-center py-3">
                <div>
                    <Skeleton
                        circle
                        enableAnimation={false}
                        width={70}
                        height={70}
                    />
                </div>
                <div className="flex-grow-1 px-3">
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="d-flex flex-row align-items-center py-3">
                <div>
                    <Skeleton
                        circle
                        enableAnimation={false}
                        width={70}
                        height={70}
                    />
                </div>
                <div className="flex-grow-1 px-3">
                    <Skeleton count={3} />
                </div>
            </div>
        </>
    )
}

export default NotificationsModalSkeleton
