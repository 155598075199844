import { create } from 'zustand'

interface LoadingStateStore {
    pageLoading: boolean
    setPageLoading: (isLoading: boolean) => void
    buttonLoading: boolean
    setButtonLoading: (isLoading: boolean) => void
    modalLoading: boolean
    setModalLoading: (isLoading: boolean) => void
}

export const useLoadingStateStore = create<LoadingStateStore>()((set) => ({
    pageLoading: false,
    setPageLoading: (isLoading: boolean) =>
        set(() => ({ pageLoading: isLoading })),
    buttonLoading: false,
    setButtonLoading: (isLoading: boolean) =>
        set(() => ({ buttonLoading: isLoading })),
    modalLoading: false,
    setModalLoading: (isLoading: boolean) =>
        set(() => ({ modalLoading: isLoading })),
}))
