import { Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const ThemesTicketsSkeleton = () => {
    return (
        <>
            <div className="col-2 my-0">
                <Skeleton height={30} />
            </div>
            <div className="card my-3">
                <div className="card-body p-0">
                    <Row className="my-1 align-items-center">
                        <div className="col-12 col-lg-6 px-2 mb-1 mb-md-0">
                            <div className="col-7 col-sm-5 col-md-3 col-lg-4">
                                <Skeleton height={24} />
                            </div>
                        </div>
                        <div className="px-2 col-12 col-lg-4 text-center">
                            <Skeleton width="25%" />
                        </div>
                        <div className="col-12 col-lg-2 px-3">
                            <Skeleton height={38} />
                        </div>
                    </Row>
                </div>
            </div>
            <div className="card my-3">
                <div className="card-body p-0">
                    <Row className="my-1 align-items-center">
                        <div className="col-12 col-lg-6 px-2 mb-1 mb-md-0">
                            <div className="col-7 col-sm-5 col-md-3 col-lg-4">
                                <Skeleton height={24} />
                            </div>
                        </div>
                        <div className="px-2 col-12 col-lg-4 text-center">
                            <Skeleton width="25%" />
                        </div>
                        <div className="col-12 col-lg-2 px-3">
                            <Skeleton height={38} />
                        </div>
                    </Row>
                </div>
            </div>
            <div className="card my-3">
                <div className="card-body p-0">
                    <Row className="my-1 align-items-center">
                        <div className="col-6 px-2">
                            <Skeleton height={24} width={180} />
                        </div>
                        <div className="px-2 col-12 col-lg-4 text-center">
                            <Skeleton width="25%" />
                        </div>
                        <div className="col-12 col-lg-2 px-3 mb-2 mb-lg-0">
                            <Skeleton height={38} />
                        </div>
                    </Row>
                    <Row className="align-items-center">
                        <div className="col-6 px-4">
                            <Skeleton height={24} width={180} />
                        </div>
                        <div className="px-2 col-12 col-lg-4 text-center">
                            <Skeleton width="25%" />
                        </div>
                        <div className="col-12 col-lg-2 px-3 mb-2 mb-lg-0">
                            <Skeleton height={38} />
                        </div>
                    </Row>
                    <Row className="align-items-center">
                        <div className="col-6 px-4">
                            <Skeleton height={24} width={180} />
                        </div>
                        <div className="px-2 col-12 col-lg-4 text-center">
                            <Skeleton width="25%" />
                        </div>
                        <div className="col-12 col-lg-2 px-3 mb-2 mb-lg-0">
                            <Skeleton height={38} />
                        </div>
                    </Row>
                    <Row className="align-items-center">
                        <div className="col-6 px-4">
                            <Skeleton height={24} width={180} />
                        </div>
                        <div className="px-2 col-12 col-lg-4 text-center">
                            <Skeleton width="25%" />
                        </div>
                        <div className="col-12 col-lg-2 px-3 mb-2 mb-lg-0">
                            <Skeleton height={38} />
                        </div>
                    </Row>
                    <Row className="align-items-center">
                        <div className="col-6 px-4">
                            <Skeleton height={24} width={180} />
                        </div>
                        <div className="px-2 col-12 col-lg-4 text-center">
                            <Skeleton width="25%" />
                        </div>
                        <div className="col-12 col-lg-2 px-3 mb-2 mb-lg-0">
                            <Skeleton height={38} />
                        </div>
                    </Row>
                    <Row className="align-items-center">
                        <div className="col-6 px-4">
                            <Skeleton height={24} width={180} />
                        </div>
                        <div className="px-2 col-12 col-lg-4 text-center">
                            <Skeleton width="25%" />
                        </div>
                        <div className="col-12 col-lg-2 px-3 mb-2 mb-lg-0">
                            <Skeleton height={38} />
                        </div>
                    </Row>
                    <Row className="align-items-center">
                        <div className="col-6 px-4">
                            <Skeleton height={24} width={180} />
                        </div>
                        <div className="px-2 col-12 col-lg-4 text-center">
                            <Skeleton width="25%" />
                        </div>
                        <div className="col-12 col-lg-2 px-3 mb-2 mb-lg-0">
                            <Skeleton height={38} />
                        </div>
                    </Row>
                    <Row className="align-items-center">
                        <div className="col-6 px-4">
                            <Skeleton height={24} width={180} />
                        </div>
                        <div className="px-2 col-12 col-lg-4 text-center">
                            <Skeleton width="25%" />
                        </div>
                        <div className="col-12 col-lg-2 px-3 mb-2 mb-lg-0">
                            <Skeleton height={38} />
                        </div>
                    </Row>
                    <Row className="align-items-center">
                        <div className="col-6 px-4">
                            <Skeleton height={24} width={180} />
                        </div>
                        <div className="px-2 col-12 col-lg-4 text-center">
                            <Skeleton width="25%" />
                        </div>
                        <div className="col-12 col-lg-2 px-3 mb-2 mb-lg-0">
                            <Skeleton height={38} />
                        </div>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default ThemesTicketsSkeleton
