import { FieldErrors } from 'react-hook-form'
import { customToast } from '@/lib/CustomToast.ts'

interface ErrorObject {
    [key: string]: string | { message: string }
}

export function FormToastError(errors: FieldErrors<ErrorObject>) {
    for (const error of Object.values(errors)) {
        if (typeof error === 'object' && 'message' in error) {
            customToast({ message: error.message, type: 'error' })
        }
    }
}
