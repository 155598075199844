import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { IInstructors } from '@/types/IInstructors.ts'

interface Props {
    instructors: IInstructors[]
}

function InstructorsCards({ instructors }: Props) {
    const [showSchedule, setShowSchedule] = useState([])

    const toggleSchedule = (index) => {
        const newShowSchedule = [...showSchedule]
        newShowSchedule[index] = !newShowSchedule[index]
        setShowSchedule(newShowSchedule)
    }

    return (
        <div className="instructors__cards">
            {instructors &&
                instructors.map((instructor, index) => {
                    return (
                        <div key={index} className="instructors__cards-card">
                            <Link
                                to={`/instructors/${instructor.id}`}
                                className="instructors__cards-card-link"
                            >
                                <img
                                    src={instructor.image?.small}
                                    alt="instructor-avatar"
                                    className="instructors__cards-card-link-avatar"
                                />
                                <div>
                                    <p className="instructors__cards-card-link-name">
                                        {instructor.surname}
                                    </p>
                                    <p className="instructors__cards-card-link-about">
                                        Возраст: {instructor.age}
                                    </p>
                                    <p className="instructors__cards-card-link-about">
                                        {instructor.about}
                                    </p>
                                </div>
                            </Link>
                            <div className="instructors__cards-card-cell-car">
                                <p>
                                    <FontAwesomeIcon
                                        icon={['fas', 'car']}
                                        size="sm"
                                        className="me-1"
                                    />
                                    {`${instructor.car.mark} ${instructor.car.model}`}
                                </p>
                                <p>{instructor.car.kpp_type}</p>
                                <p className="instructors__cards-card-cell-car-number">
                                    {instructor.car.number}
                                </p>
                            </div>
                            <div className="instructors__cards-card-cell-location">
                                <OverlayTrigger
                                    key={index}
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            Место встречи с курсантами
                                        </Tooltip>
                                    }
                                >
                                    <p>{instructor.location}</p>
                                </OverlayTrigger>
                            </div>
                            <div className="instructors__cards-card-cell-reviews">
                                <Link
                                    to={`/instructors/${instructor.id}/#reviews`}
                                >
                                    <p className="lh-sm">
                                        <FontAwesomeIcon
                                            className="mx-1"
                                            icon={['fal', 'comment']}
                                            size="1x"
                                        />
                                        {instructor.reviews}
                                    </p>
                                    <p className="text-danger lh-sm">
                                        {instructor.reviews % 10 === 1 &&
                                        instructor.reviews % 100 !== 11
                                            ? 'отзыв'
                                            : instructor.reviews % 10 >= 2 &&
                                                instructor.reviews % 10 <= 4 &&
                                                (instructor.reviews % 100 <
                                                    10 ||
                                                    instructor.reviews % 100 >=
                                                        20)
                                              ? 'отзыва'
                                              : 'отзывов'}
                                    </p>
                                </Link>
                            </div>
                            <OverlayTrigger
                                key={index}
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Средний рейтинг инструктора -&nbsp;
                                        {instructor.rating}
                                    </Tooltip>
                                }
                            >
                                <div className="instructors__cards-card-cell-rating">
                                    {Array.from(
                                        Array(
                                            Math.floor(
                                                Number(instructor.rating),
                                            ),
                                        ),
                                    ).map((_, index) => (
                                        <FontAwesomeIcon
                                            key={index}
                                            icon={
                                                index ===
                                                    Math.floor(
                                                        Number(
                                                            instructor.rating,
                                                        ),
                                                    ) -
                                                        1 &&
                                                Number(instructor.rating) %
                                                    1 !==
                                                    0
                                                    ? ['fas', 'star-half-alt']
                                                    : ['fas', 'star']
                                            }
                                            size="sm"
                                            color="orange"
                                        />
                                    ))}
                                </div>
                            </OverlayTrigger>
                            <div className="instructors__cards-card-cell-schedule">
                                <OverlayTrigger
                                    key={index}
                                    placement="left"
                                    show={
                                        showSchedule[index] || true[index]
                                    } /*!todo Странное условие. showSchedule вообще нужен?*/
                                    overlay={
                                        <Tooltip>
                                            <p>
                                                Будни:&nbsp;
                                                {instructor.schedule.weekdays
                                                    ? instructor.schedule
                                                          .weekdays
                                                    : 'Нет занятий'}
                                            </p>
                                            <p>
                                                Сб:&nbsp;
                                                {instructor.schedule.saturday
                                                    ? instructor.schedule
                                                          .saturday
                                                    : 'Нет занятий'}
                                            </p>
                                            <p>
                                                Вс:&nbsp;
                                                {instructor.schedule.sunday
                                                    ? instructor.schedule.sunday
                                                    : 'Нет занятий'}
                                            </p>
                                        </Tooltip>
                                    }
                                >
                                    <button
                                        className="btn btn-outline-success"
                                        onClick={() =>
                                            toggleSchedule(index)
                                        } /*!todo При нажатии переходит на страницу инструктора. При наведении расписание и так показывает, стейт думаю не нужен*/
                                    >
                                        <FontAwesomeIcon
                                            icon={['fal', 'clock-nine']}
                                            size="sm"
                                        />
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}

export default InstructorsCards
