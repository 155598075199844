import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

export const HistoryItemsCollapse = ({
    item,
    historyIndex,
    collapsedHistoryItems,
    showPaymentHistoryItems,
}) => {
    let btnClass
    let btnTooltip
    let btnIcon
    let bgClass
    if (!item.orderStatus) {
        btnClass = 'btn btn-outline-warning'
        btnTooltip = 'Платеж в обработке'
        btnIcon = <FontAwesomeIcon icon={['fal', 'clock-five']} size="2xs" />
        bgClass = 'bg-history-warning'
    } else if (item.orderStatus === 2) {
        btnClass = 'btn btn-outline-success'
        btnTooltip = 'Проведена полная авторизация суммы заказа'
        btnIcon = <FontAwesomeIcon icon={['fal', 'check-circle']} size="2xs" />
        bgClass = 'bg-history-success'
    } else if (item.orderStatus === 6) {
        btnClass = 'btn btn-outline-danger'
        btnTooltip = 'По транзакции была проведена операция возврата'
        btnIcon = (
            <FontAwesomeIcon
                icon={['fal', 'arrow-turn-down-left']}
                size="2xs"
            />
        )
        bgClass = 'bg-history-danger'
    }
    return (
        <Collapse in={collapsedHistoryItems?.includes(historyIndex)}>
            <div
                id={`historyIndex-${historyIndex}`}
                className={bgClass}
                onClick={() => showPaymentHistoryItems(historyIndex)}
            >
                <div className="payments__history-item border-0">
                    <div className="payments__history-item-icon">
                        {item.payType === 'Онлайн' ? (
                            <FontAwesomeIcon
                                icon={['fas', 'credit-card']}
                                color="#2196f3"
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={['fas', 'rub']}
                                color="#5db85c"
                            />
                        )}
                    </div>
                    <div className="payments__history-item-info">
                        <p className="payments__history-item-info-date">
                            № {item.id} |&nbsp;
                            {format(item.orderDate, 'Pp', {
                                locale: ru,
                            })}
                        </p>
                        <p className="payments__history-item-info-payments-info">
                            {item.service.name}
                        </p>
                    </div>
                    <div className="payments__history-item-payment-sum">
                        <h4 className="payments__history-item-payment-sum-amount">
                            {item.amount}&nbsp;
                            <FontAwesomeIcon icon={['fas', 'rub']} />
                        </h4>
                        <div className="payments__history-item-payment-sum-status">
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>{btnTooltip}</Tooltip>}
                            >
                                <button
                                    className={`${btnClass} payments__history-item-payment-sum-status-btn`}
                                >
                                    {btnIcon}
                                </button>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </div>
        </Collapse>
    )
}

export const MultiHistoryItem = ({
    historyItem,
    historyIndex,
    showPaymentHistoryItems,
    collapsedHistoryItems,
}) => {
    const declineWord = (count, titles) => {
        const cases = [2, 0, 1, 1, 1, 2]
        return titles[
            count % 100 > 4 && count % 100 < 20
                ? 2
                : cases[count % 10 < 5 ? count % 10 : 5]
        ]
    }

    let btnClass
    let btnTooltip
    let btnIcon
    if (!historyItem[historyIndex]?.orderStatus) {
        btnClass = 'btn btn-outline-warning'
        btnTooltip = 'Платеж в обработке'
        btnIcon = <FontAwesomeIcon icon={['fal', 'clock-five']} size="2xs" />
    } else if (historyItem[historyIndex]?.orderStatus === 2) {
        btnClass = 'btn btn-outline-success'
        btnTooltip = 'Проведена полная авторизация суммы заказа'
        btnIcon = <FontAwesomeIcon icon={['fal', 'check-circle']} size="2xs" />
    } else if (historyItem[historyIndex]?.orderStatus === 6) {
        btnClass = 'btn btn-outline-danger'
        btnTooltip = 'По транзакции была проведена операция возврата'
        btnIcon = (
            <FontAwesomeIcon
                icon={['fal', 'arrow-turn-down-left']}
                size="2xs"
            />
        )
    }

    return (
        <div
            className="payments__history-item"
            onClick={() => showPaymentHistoryItems(historyIndex)}
            aria-controls={`historyIndex-${historyIndex}`}
            aria-expanded={collapsedHistoryItems?.includes(historyIndex)}
        >
            <div className="payments__history-item-icon">
                {historyItem[0].payType === 'Онлайн' ? (
                    <FontAwesomeIcon
                        icon={['fas', 'credit-card']}
                        color="#2196f3"
                    />
                ) : (
                    <FontAwesomeIcon icon={['fas', 'rub']} color="#5db85c" />
                )}
            </div>
            <div className="payments__history-item-info">
                <p className="payments__history-item-info-date">
                    {format(historyItem[0].orderDate, 'Pp', {
                        locale: ru,
                    })}
                </p>
                <p className="payments__history-item-info-payments-info">
                    {historyItem[0].service.name} &nbsp;
                    <span className="fst-italic">
                        {`+ ${historyItem.length - 1} ${declineWord(
                            historyItem.length - 1,
                            ['платеж', 'платежа', 'платежей'],
                        )}`}
                    </span>
                </p>
            </div>
            <div className="payments__history-item-payment-sum">
                <h4 className="payments__history-item-payment-sum-amount">
                    {historyItem.reduce((sum, item) => sum + item.amount, 0)}
                    &nbsp;
                    <FontAwesomeIcon icon={['fas', 'rub']} />
                </h4>
                <div className="payments__history-item-payment-sum-status">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{btnTooltip}</Tooltip>}
                    >
                        <button
                            className={`${btnClass} payments__history-item-payment-sum-status-btn`}
                        >
                            {btnIcon}
                        </button>
                    </OverlayTrigger>
                </div>
            </div>
        </div>
    )
}

export const SingleHistoryItem = ({ historyItem }) => {
    let btnClass
    let btnTooltip
    let btnIcon
    if (!historyItem[0].orderStatus) {
        btnClass = 'btn btn-outline-warning'
        btnTooltip = 'Платеж в обработке'
        btnIcon = <FontAwesomeIcon icon={['fal', 'clock-five']} size="2xs" />
    } else if (historyItem[0].orderStatus === 2) {
        btnClass = 'btn btn-outline-success'
        btnTooltip = 'Проведена полная авторизация суммы заказа'
        btnIcon = <FontAwesomeIcon icon={['fal', 'check-circle']} size="2xs" />
    } else if (historyItem[0].orderStatus === 6) {
        /*!todo Лучше добавлю в API текст, иконку и цвет*/
        btnClass = 'btn btn-outline-danger'
        btnTooltip = 'По транзакции была проведена операция возврата'
        btnIcon = (
            <FontAwesomeIcon
                icon={['fal', 'arrow-turn-down-left']}
                size="2xs"
            />
        )
    }
    return (
        <div className="payments__history-item">
            <div className="payments__history-item-icon">
                {historyItem[0].payType === 'Онлайн' ? (
                    <FontAwesomeIcon
                        icon={['fas', 'credit-card']}
                        color="#2196f3"
                    />
                ) : (
                    <FontAwesomeIcon icon={['fas', 'rub']} color="#5db85c" />
                )}
            </div>
            <div className="payments__history-item-info">
                <p className="payments__history-item-info-date">
                    № {historyItem[0].id} |&nbsp;
                    {format(historyItem[0].orderDate, 'Pp', {
                        locale: ru,
                    })}
                </p>
                <p className="payments__history-item-info-payments-info">
                    {historyItem[0].service.name}
                </p>
            </div>
            <div className="payments__history-item-payment-sum">
                <h4 className="payments__history-item-payment-sum-amount">
                    {historyItem[0].amount}&nbsp;
                    <FontAwesomeIcon icon={['fas', 'rub']} />
                </h4>
                {(historyItem[0].orderStatus === 2 ||
                    historyItem[0].orderStatus === 6 ||
                    !historyItem[0].orderStatus) && (
                    <div className="payments__history-item-payment-sum-status">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{btnTooltip}</Tooltip>}
                        >
                            <button
                                className={`${btnClass} payments__history-item-payment-sum-status-btn`}
                            >
                                {btnIcon}
                            </button>
                        </OverlayTrigger>
                    </div>
                )}
            </div>
        </div>
    )
}
