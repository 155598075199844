import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Sidebar.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useLoadingStateStore } from '@/store/LoadingStateStore.ts'
import {
    changeToABMTicketsType,
    changeToCDTicketsType,
    moveToAnotherPage,
} from '@/components/Sidebar/SidebarHelpers.tsx'
import Skeleton from 'react-loading-skeleton'
import { useQuery } from '@tanstack/react-query'
import { ISchoolInfo } from '@/types/IUser.ts'

function SideBar({ setSidebarVisible, isSidebarVisible }) {
    const { pageLoading } = useLoadingStateStore()
    const { data }: ISchoolInfo = useQuery({
        queryKey: ['school', 'info'],
    })

    const schoolInfo = data

    const changeToABM = () => {
        changeToABMTicketsType(setSidebarVisible, isSidebarVisible)
    }
    const changeToCD = () => {
        changeToCDTicketsType(setSidebarVisible, isSidebarVisible)
    }
    const changePage = () => {
        moveToAnotherPage(setSidebarVisible, isSidebarVisible)
    }

    const location = useLocation()
    const navigate = useNavigate()

    /*!todo Не работает открыть в новой вкладке (средней кнопкой мыши)*/
    return (
        <Sidebar>
            <Menu className="menu">
                <MenuItem
                    className="menu-item"
                    onClick={() => {
                        changePage()
                        if (location.pathname !== '/') {
                            navigate('/')
                        }
                    }}
                >
                    <FontAwesomeIcon
                        icon={['fal', 'home-alt']}
                        color="black"
                        size="lg"
                        className="me-2"
                    />
                    Профиль
                </MenuItem>
                <label className="menu-subheader">ТЕОРИЯ</label>
                <SubMenu
                    className="sub-menu"
                    label={
                        <>
                            <FontAwesomeIcon
                                icon={['fal', 'car-alt']}
                                color="black"
                                size="lg"
                                className="me-2"
                            />
                            Билеты ПДД
                            <span
                                className="bg-success text-white"
                                style={{
                                    marginLeft: '50px',
                                    fontSize: '11px',
                                    borderRadius: '4px',
                                }}
                            >
                                ABM
                            </span>
                        </>
                    }
                >
                    <MenuItem
                        className="menu-item submenu-item"
                        onClick={() => {
                            if (location.pathname !== '/tickets') {
                                navigate('/tickets')
                            }
                            changeToABM()
                        }}
                        style={{ pointerEvents: pageLoading ? 'none' : 'auto' }}
                    >
                        40 билетов
                    </MenuItem>
                    <MenuItem
                        className="menu-item submenu-item"
                        onClick={() => {
                            if (location.pathname !== '/tickets/themes') {
                                navigate('/tickets/themes')
                            }
                            changeToABM()
                        }}
                        style={{ pointerEvents: pageLoading ? 'none' : 'auto' }}
                    >
                        По темам
                    </MenuItem>
                    <MenuItem
                        className="menu-item submenu-item"
                        onClick={() => {
                            if (location.pathname !== '/tickets/exam') {
                                navigate('/tickets/exam')
                            }
                            changeToABM()
                        }}
                        style={{ pointerEvents: pageLoading ? 'none' : 'auto' }}
                    >
                        Экзамен
                    </MenuItem>
                    <MenuItem
                        className="menu-item submenu-item"
                        onClick={() => {
                            if (location.pathname !== '/tickets/marathon') {
                                navigate('/tickets/marathon')
                            }
                            changeToABM()
                        }}
                        style={{ pointerEvents: pageLoading ? 'none' : 'auto' }}
                    >
                        Марафон 800 {/*!todo+ 800*/}
                    </MenuItem>
                </SubMenu>
                <SubMenu
                    className="sub-menu"
                    label={
                        <>
                            <FontAwesomeIcon
                                icon={['fal', 'truck']}
                                color="black"
                                size="lg"
                                className="me-2"
                            />
                            Билеты ПДД
                            <span
                                className="bg-success text-white"
                                style={{
                                    marginLeft: '50px',
                                    fontSize: '11px',
                                    borderRadius: '4px',
                                }}
                            >
                                CD
                            </span>
                        </>
                    }
                >
                    <MenuItem
                        className="menu-item submenu-item"
                        onClick={() => {
                            if (location.pathname !== '/tickets') {
                                navigate('/tickets')
                            }
                            changeToCD()
                        }}
                        style={{ pointerEvents: pageLoading ? 'none' : 'auto' }}
                    >
                        40 билетов
                    </MenuItem>
                    <MenuItem
                        className="menu-item submenu-item"
                        onClick={() => {
                            if (location.pathname !== '/tickets/themes') {
                                navigate('/tickets/themes')
                            }
                            changeToCD()
                        }}
                        style={{ pointerEvents: pageLoading ? 'none' : 'auto' }}
                    >
                        По темам
                    </MenuItem>
                    <MenuItem
                        className="menu-item submenu-item"
                        onClick={() => {
                            if (location.pathname !== '/tickets/exam') {
                                navigate('/tickets/exam')
                            }
                            changeToCD()
                        }}
                        style={{ pointerEvents: pageLoading ? 'none' : 'auto' }}
                    >
                        Экзамен
                    </MenuItem>
                    <MenuItem
                        className="menu-item submenu-item"
                        onClick={() => {
                            if (location.pathname !== '/tickets/marathon') {
                                navigate('/tickets/marathon')
                            }
                            changeToCD()
                        }}
                        style={{ pointerEvents: pageLoading ? 'none' : 'auto' }}
                    >
                        Марафон 800 {/*!todo+ 800*/}
                    </MenuItem>
                </SubMenu>
                <MenuItem
                    className="menu-item"
                    onClick={() => {
                        if (location.pathname !== '/tickets/statistics') {
                            navigate('/tickets/statistics')
                        }
                    }}
                    style={{ pointerEvents: pageLoading ? 'none' : 'auto' }}
                >
                    <FontAwesomeIcon
                        icon={['fal', 'chart-pie']}
                        color="black"
                        size="lg"
                        className="me-2"
                    />
                    Статистика по билетам
                </MenuItem>
                <MenuItem
                    className="menu-item"
                    onClick={() => {
                        if (location.pathname !== '/lessons') {
                            navigate('/lessons')
                        }
                        changePage()
                    }}
                    style={{ pointerEvents: pageLoading ? 'none' : 'auto' }}
                >
                    <FontAwesomeIcon
                        icon={['fal', 'video']}
                        color="black"
                        size="lg"
                        className="me-2"
                    />
                    Видеоуроки
                </MenuItem>
                <label className="menu-subheader">ПРАКТИКА</label>
                <MenuItem
                    className="menu-item"
                    onClick={() => {
                        changePage()
                        if (location.pathname !== '/instructors') {
                            navigate('/instructors')
                        }
                    }}
                    style={{ pointerEvents: pageLoading ? 'none' : 'auto' }}
                >
                    <FontAwesomeIcon
                        icon={['fal', 'users']}
                        color="black"
                        size="lg"
                        className="me-2"
                    />
                    Все инструкторы
                </MenuItem>
                <MenuItem
                    className="menu-item"
                    onClick={() => {
                        if (location.pathname !== '/practice') {
                            navigate('/practice')
                        }
                        changePage()
                    }}
                    style={{ pointerEvents: pageLoading ? 'none' : 'auto' }}
                >
                    <FontAwesomeIcon
                        icon={['fal', 'car-alt']}
                        color="black"
                        size="lg"
                        className="me-2"
                    />
                    Занятие с инструктором
                </MenuItem>
                <MenuItem
                    className="menu-item"
                    onClick={() => {
                        if (location.pathname !== '/exams') {
                            navigate('/exams')
                        }
                        changePage()
                    }}
                    style={{ pointerEvents: pageLoading ? 'none' : 'auto' }}
                >
                    <FontAwesomeIcon
                        icon={['fal', 'car-alt']}
                        color="black"
                        size="lg"
                        className="me-2"
                    />
                    Внутренние экзамены
                </MenuItem>
                <label className="menu-subheader">ФИНАНСЫ</label>
                <MenuItem
                    className="menu-item"
                    onClick={() => {
                        if (location.pathname !== '/payments') {
                            navigate('/payments')
                        }
                        changePage()
                    }}
                    style={{
                        pointerEvents: pageLoading ? 'none' : 'auto',
                    }}
                >
                    <FontAwesomeIcon
                        icon={['fal', 'ruble-sign']}
                        color="black"
                        size="lg"
                        className="me-2"
                    />
                    Оплатить обучение
                </MenuItem>
                {!schoolInfo && (
                    <div className="mt-5 lh-sm">
                        <Skeleton width="25%" />
                        <Skeleton width="75%" />
                        <Skeleton width="85%" />
                        <Skeleton width="50%" />
                    </div>
                )}
                {schoolInfo && (
                    <p className="text-body-secondary mt-5">
                        v. {schoolInfo.version}
                        <br />
                        {schoolInfo.company_name}
                        <br />
                        {schoolInfo.license}
                        <br />
                        Сделано в{' '}
                        <Link
                            to="https://ceratex.ru/"
                            target="_blank"
                            className="text-black fw-bold"
                        >
                            CERATEX
                        </Link>{' '}
                        {/*!todo+ Ссылка*/}
                    </p>
                )}
            </Menu>
        </Sidebar>
    )
}

export default SideBar
