import { useState, useEffect, FC } from 'react'
import s from './LoginPage.module.scss'

interface PasswordRequirementsProps {
    password: string
    className: string
}

const PasswordRequirements: FC<PasswordRequirementsProps> = ({
    password,
    className,
}) => {
    const [state, setState] = useState({
        length: false,
        hasUppercase: false,
        hasDigit: false,
        hasSpecialChar: false,
    })

    useEffect(() => {
        setState({
            length: password?.length >= 8,
            hasUppercase: /[A-Z]/.test(password),
            hasDigit: /[0-9]/.test(password),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        })
    }, [password])

    return (
        <div className={`passwordValidator ${className}`}>
            <div className="fw-bold fs-lg-14 fs-md-12 fs-sm-11 mb-2">
                Правила хорошего пароля:
            </div>
            <ul className="list-unstyled fw-bold fs-lg-12 fs-md-10 fs-sm-9 m-0">
                <li
                    className={
                        state.hasUppercase ? 'text-success' : 'text-danger'
                    }
                >
                    Минимум одна заглавная буква
                </li>

                <li className={state.hasDigit ? 'text-success' : 'text-danger'}>
                    Хотя бы одна цифра
                </li>
                <li
                    className={
                        state.hasSpecialChar ? 'text-success' : 'text-danger'
                    }
                >
                    Хотя бы один специальный символ
                </li>
                <li className={state.length ? 'text-success' : 'text-danger'}>
                    Минимум 8 символов
                </li>
            </ul>
        </div>
    )
}

export default PasswordRequirements
