import { useEffect } from 'react'
import { useTimerStore } from '@/store/DefaultTimerStore.ts'

export const DefaultTimer = () => {
    const { seconds, setSeconds, minutes, setMinutes, isPaused } =
        useTimerStore()

    useEffect(() => {
        let timer
        if (!isPaused) {
            timer = setInterval(() => {
                const totalSeconds = minutes * 60 + seconds + 1
                setMinutes(Math.floor(totalSeconds / 60))
                setSeconds(totalSeconds % 60)
            }, 1000)
        }
        return () => clearInterval(timer)
    }, [minutes, seconds, isPaused])

    useEffect(() => {
        return () => {
            setMinutes(0)
            setSeconds(0)
        }
    }, [])

    return (
        <h2 className="question__card-header-timer">
            {`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}
        </h2>
    )
}
