import { getPaymentServices } from '@/Pages/PaymentsPage/PaymentPageHelpers.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { headerIcons } from '@/Pages/MainPage/MainPageHeader.tsx'
import { usePaymentsStore } from '@/store/PaymentsStore.ts'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useState } from 'react'
import { useUserQuery } from '@/lib/useUserQuery.ts'
import { IPaymentProps } from '@/types/IPayments.ts'

export const PaymentCategoryHeader = () => {
    return (
        <h2 className="common__title">
            Оплата обучения{' '}
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip>
                        Для оплаты Вы будете перенаправлены на платежный шлюз
                        ООО НКО «ЮМани» (ОГРН 1127711000031, ИНН 7750005725,
                        лицензия на осуществление банковских операций № 3510-К).
                        Соединение с платежным шлюзом и передача информации
                        осуществляется в защищенном режиме с использованием
                        протокола шифрования SSL. В случае если Ваш банк
                        поддерживает технологию безопасного проведения
                        интернет-платежей Verified By Visa или MasterCard
                        SecureCode для проведения платежа также может
                        потребоваться ввод специального одноразового пароля.
                    </Tooltip>
                }
            >
                <FontAwesomeIcon
                    icon={['fas', 'exclamation-circle']}
                    className="text-warning"
                />
            </OverlayTrigger>
            <p className="fw-normal">Выбери категорию и необходимые услуги</p>
        </h2>
    )
}

export const PaymentCategoryVariants = ({ setStep }: IPaymentProps) => {
    const [selectedCategoryId, setSelectedCategoryId] = useState(0)

    const user = useUserQuery()
    const categories = user?.tabs
    const icons = headerIcons

    return (
        <div className="main__card-body-variants">
            {categories?.map((category, index) => (
                <div className="main__card-body-variants-variant" key={index}>
                    <button
                        className="main__card-body-variants-variant-btn btn btn-outline-warning"
                        onClick={() =>
                            getPaymentServices(
                                category.id,
                                category,
                                setSelectedCategoryId,
                                setStep,
                            )
                        }
                    >
                        {selectedCategoryId === category.id ? (
                            <FontAwesomeIcon
                                icon={['fad', 'spinner-third']}
                                size="2x"
                                spin
                            />
                        ) : (
                            <FontAwesomeIcon icon={icons[index]} size="2x" />
                        )}
                        <p>{category.name}</p>
                    </button>
                </div>
            ))}
        </div>
    )
}

export const PaymentCategoryServices = ({ setStep }: IPaymentProps) => {
    const { paymentServices, selectedPaymentServices } = usePaymentsStore()

    const showPaymentList = (service: any, index: number) => {
        selectedPaymentServices[selectedPaymentServices.length - 1].services =
            service
        setStep('confirm')
    }

    return (
        <>
            <PaymentServicesHeader setStep={setStep} />
            <div className="main__card-body-services">
                {paymentServices?.services?.map((service, index) => (
                    <button
                        key={index}
                        className="main__card-body-services-btn btn btn-outline-warning"
                        onClick={() => showPaymentList(service, index)}
                    >
                        {service.name}
                    </button>
                ))}
            </div>
        </>
    )
}

export const PaymentServicesHeader = ({ setStep }: IPaymentProps) => {
    const { selectedPaymentServices } = usePaymentsStore()
    const stepBack = () => {
        setStep('variants')
        selectedPaymentServices.pop()
    }
    return (
        <h3 className="main__card-title common__title">
            <FontAwesomeIcon
                className="main__card-title-arrow-back"
                icon={['fas', 'arrow-left']}
                size="lg"
                onClick={() => stepBack()}
            />
            Выберите назначение платежа
        </h3>
    )
}
export const PaymentMethodsHeader = ({ setStep }: IPaymentProps) => {
    const stepBack = () => {
        setStep('confirm')
    }
    return (
        <h3 className="main__card-title common__title">
            <FontAwesomeIcon
                className="main__card-title-arrow-back"
                icon={['fas', 'arrow-left']}
                size="lg"
                onClick={() => stepBack()}
            />
            Выберите способ оплаты
        </h3>
    )
}

export const CircleCheckGreen = () => {
    return (
        <FontAwesomeIcon
            icon={['fad', 'circle-check']}
            style={{
                ['--fa-primary-color' as string]: 'white',
                ['--fa-secondary-color' as string]: '#08A652',
                ['--fa-secondary-opacity' as string]: '1',
            }}
            size="lg"
        />
    )
}

export const CircleCheckPrimary = () => {
    return (
        <FontAwesomeIcon icon={['fas', 'circle']} color="lightgray" size="lg" />
    )
}
