import Skeleton from 'react-loading-skeleton'

const MarathonStatisticsSkeleton = () => {
    return (
        <div className="page">
            <div className="col-3 mb-2">
                <Skeleton />
            </div>
            <div className="col-5 mb-2">
                <Skeleton />
            </div>
            <div className="card px-0">
                <div className="card-body px-0">
                    <table className="table table-striped table-sm">
                        <thead className="thead-invert">
                            <tr className="text-center">
                                <th className="px-3">
                                    <Skeleton height={30} />
                                </th>
                                <th className="px-3">
                                    <Skeleton height={33} />
                                </th>
                                <th className="px-3">
                                    <Skeleton height={33} />
                                </th>
                                <th className="px-3">
                                    <Skeleton height={33} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="text-center">
                                <td className="px-3 bg-white">
                                    <Skeleton height={30} count={10} />
                                </td>
                                <td className="px-3">
                                    <Skeleton height={30} count={10} />
                                </td>
                                <td className="px-3">
                                    <Skeleton height={30} count={10} />
                                </td>
                                <td className="px-3">
                                    <Skeleton height={30} count={10} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default MarathonStatisticsSkeleton
