import { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { ScrollContainer } from 'react-indiana-drag-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IPositive } from '@/types/IPracticeData.ts'

interface Props {
    positiveItems: IPositive[]
    selectedPositives: string[]
    setSelectedPositives: Dispatch<SetStateAction<string[]>>
}

const PositivesList = ({
    positiveItems,
    selectedPositives,
    setSelectedPositives,
}: Props) => {
    const scrollRef = useRef<HTMLElement>(null)

    useEffect(() => {
        scrollRef.current?.scrollTo({
            left: 15,
        })
    }, [])

    const handleSelectPositives = (name: string) => {
        if (selectedPositives.includes(name)) {
            setSelectedPositives(
                selectedPositives.filter((item) => item !== name),
            )
        } else {
            setSelectedPositives([...selectedPositives, name])
        }
    }

    return (
        <div className="practice__review-modal-positives">
            <h3 className="practice__review-modal-positives-title">
                Что вам понравилось на занятии?
            </h3>
            <ScrollContainer className="practice__review-modal-positives-list">
                {positiveItems?.map((positive, index) => (
                    <div
                        key={index}
                        className="practice__review-modal-positives-list-item"
                        onClick={() => handleSelectPositives(positive.name)}
                    >
                        <div
                            className={
                                positive.details.name == 'smile-wink' ||
                                positive.details.name == 'grin-hearts'
                                    ? 'svg-circle-bg'
                                    : positive.details.name == 'air-freshener'
                                      ? 'svg-square-bg'
                                      : ''
                            }
                        >
                            <FontAwesomeIcon
                                icon={{
                                    prefix: positive.details.type,
                                    iconName: positive.details.name,
                                }}
                                size={positive.details.size}
                                color={
                                    selectedPositives.includes(positive.name)
                                        ? positive.color
                                        : 'lightgray'
                                }
                            />
                        </div>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: positive.name,
                            }}
                        ></p>
                    </div>
                ))}
            </ScrollContainer>
        </div>
    )
}

export default PositivesList
