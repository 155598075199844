import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ICategory } from '@/types/ICategory.ts'
import { useUserQuery } from '@/lib/useUserQuery.ts'

interface WelcomeCardProps {
    scrollToRef: (index: number) => void
    category: ICategory
}

export const WelcomeCard = ({ category, scrollToRef }: WelcomeCardProps) => {
    const user = useUserQuery() //!todo+ useUserQuery

    const categoryBranch = category.branch
    const categoryGroup = category.branch.group

    return (
        <div className="card welcome__card">
            <div className="welcome__card-user">
                <h1 className="welcome__card-title">
                    <FontAwesomeIcon
                        icon={['fas', 'heart']}
                        color="red"
                        size="1x"
                        className="me-1"
                    />
                    {category.welcome_title}
                </h1>
                <p className="welcome__card-info">
                    <span>
                        Баланс:&nbsp;
                        <b>
                            {category.balance === null
                                ? '0 руб.'
                                : category.balance}
                        </b>
                    </span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Доступные денежные средства для практики с
                                инструктором
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon
                            icon={['fas', 'info-circle']}
                            color="orange"
                        />
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                В связи с обновлением приложения могут быть
                                временные расхождения с фактическим балансом
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon
                            icon={['fas', 'exclamation-circle']}
                            color="red"
                        />
                    </OverlayTrigger>
                </p>
                <>
                    <p className="welcome__card-info">
                        <span>
                            Ученик:&nbsp;<b>{user?.name}</b>{' '}
                        </span>
                    </p>
                    {categoryGroup.status !== false && (
                        <p className="welcome__card-info">
                            <span>
                                Учебная группа:&nbsp;<b>{categoryGroup.name}</b>{' '}
                            </span>

                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Сроки обучения:
                                        <br />
                                        {categoryGroup.start} -{' '}
                                        {categoryGroup.end}
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'info-circle']}
                                    color="orange"
                                />
                            </OverlayTrigger>
                        </p>
                    )}
                    {categoryBranch.secretary.status !== false && (
                        <p className="welcome__card-info">
                            <span>
                                Менеджер:&nbsp;
                                <b>{categoryBranch.secretary.name}</b>
                            </span>
                            <a
                                href={`tel:${categoryBranch.secretary.phone}`}
                                className="welcome__card-manager-phone btn btn-outline-warning"
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'phone-alt']}
                                    size="sm"
                                />
                            </a>
                        </p>
                    )}
                    {categoryBranch.teacher.status && (
                        <p className="welcome__card-info mb-0">
                            <span>
                                Преподаватель:&nbsp;
                                <b>{categoryBranch.teacher.name}</b>{' '}
                            </span>
                        </p>
                    )}
                </>
            </div>
            <div className="welcome__card-goal">
                <h2 className="welcome__card-title">
                    Чтобы стать хорошим водителем, тебе нужно:
                </h2>
                <ul className="welcome__card-goal-list">
                    {category.main_checklist.map((item, index) => (
                        <li
                            key={index}
                            className="welcome__card-goal-list-item"
                        >
                            <FontAwesomeIcon
                                icon={['fal', 'square']}
                                color="red"
                                size="sm"
                            />
                            <span
                                className="welcome__card-goal-list-item-text"
                                onClick={() => scrollToRef(index)}
                            >
                                {item}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
