import { create } from 'zustand'
import { IQuestion, IThemeQuestion } from '@/types/ITickets.ts'

interface TicketsQuestionsStore {
    currentTicketSelected: string
    setCurrentTicketSelected: (arg: string) => void
    TicketsQuestions: IQuestion | null
    setTicketsQuestions: (arg: IQuestion) => void
    currentThemeTicketSelected: string
    setCurrentThemeTicketSelected: (arg: string) => void
    ThemeTicketsQuestions: IThemeQuestion | null
    setThemeTicketsQuestions: (arg: IThemeQuestion) => void
    ExamTicketsQuestions: IQuestion[] | null
    setExamTicketsQuestions: (arg: IQuestion[]) => void
    resetExamQuestions: () => void
}

export const useTicketsQuestionsStore = create<TicketsQuestionsStore>()(
    (set) => ({
        currentTicketSelected: null,
        setCurrentTicketSelected: (currentTicketSelected: string) =>
            set({ currentTicketSelected }),
        TicketsQuestions: null,
        setTicketsQuestions: (TicketsQuestions) => set({ TicketsQuestions }),
        currentThemeTicketSelected: null,
        setCurrentThemeTicketSelected: (currentThemeTicketSelected: string) =>
            set({ currentThemeTicketSelected }),
        ThemeTicketsQuestions: null,
        setThemeTicketsQuestions: (ThemeTicketsQuestions) =>
            set({ ThemeTicketsQuestions }),
        ExamTicketsQuestions: null,
        setExamTicketsQuestions: (ExamTicketsQuestions) =>
            set({ ExamTicketsQuestions }),
        resetExamQuestions: () => {
            set({
                ExamTicketsQuestions: null,
            })
        },
    }),
)
