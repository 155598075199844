import { Card, Col, Container, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

export default function MainPageSkeleton() {
    return (
        <div className="page">
            <Row>
                <div className="col-12">
                    <Card>
                        <div className="col-10 col-sm-6 col-md-5 col-lg-2 px-3 mb-4 lh-1">
                            <Skeleton height={22} width="70%" />
                        </div>
                        <Card.Body className="p-0">
                            <Row>
                                <div className="col-lg-6 px-3">
                                    <div className="col-12">
                                        <div className="col-5 col-md-3 mb-4 lh-1">
                                            <Skeleton height={20} />
                                        </div>
                                        <div className="col-6 col-md-5 lh-1">
                                            <Skeleton
                                                count={2}
                                                height={20}
                                                className="mb-2"
                                            />
                                        </div>
                                        <div className="col-8 col-md-7 col-lg-6 mb-2 lh-1">
                                            <Skeleton height={20} />
                                        </div>
                                        <div className="col-6 col-md-5 col-lg-4 lh-1">
                                            <Skeleton height={20} width={200} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-10 col-md-5 px-3 mt-1">
                                    <div className="mb-2 lh-1">
                                        <Skeleton height={17} />
                                    </div>
                                    <div className="col-sm-6 col-md-8 col-lg-6 lh-1">
                                        <Skeleton
                                            width="65%"
                                            height={17}
                                            className="mb-1"
                                        />
                                        <Skeleton
                                            width="80%"
                                            height={17}
                                            className="mb-1"
                                        />
                                        <Skeleton
                                            width="75%"
                                            height={17}
                                            className="mb-1"
                                        />
                                        <Skeleton
                                            width="65%"
                                            height={17}
                                            className="mb-1"
                                        />
                                    </div>
                                </div>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
            <Row>
                <div className="col-12 mt-4 pt-2">
                    <div id="step-1-1" className="mb-3 col-2">
                        <Skeleton height={30} />
                    </div>
                    <Card>
                        <Card.Body className="p-0">
                            <Row>
                                <Col lg={6} className="m-auto">
                                    <Row>
                                        <div className="px-3 d-flex flex-column flex-md-row">
                                            <div className="col-12 col-md-6 px-0 py-2 px-md-4">
                                                <Skeleton height={35} />
                                            </div>
                                            <div className="col-12 col-md-6 px-0 py-2 px-md-4">
                                                <Skeleton height={35} />
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                                <Col lg={6} className="p-3">
                                    <div className="mb-1 col-3">
                                        <Skeleton />
                                    </div>
                                    <div className="col-10">
                                        <Skeleton />
                                    </div>
                                    <div className="col-12 mb-1">
                                        <Skeleton className="col-12 mb-1" />
                                    </div>
                                    <div className="mt-4 col-11">
                                        <Skeleton />
                                    </div>
                                    <div className="mb-1 col-12">
                                        <Skeleton />
                                    </div>
                                    <div className="mt-4 mb-1 col-2">
                                        <Skeleton />
                                    </div>
                                    <div>
                                        <Skeleton className="col-12" />
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </div>
    )
}
