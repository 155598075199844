import { create } from 'zustand'

interface SystemSettingsStore {
    isWindowWide: boolean
    setIsWindowWide: (arg: boolean) => void
}

export const useSystemSettingsStore = create<SystemSettingsStore>()((set) => ({
    isWindowWide: window.innerWidth > 1200,
    setIsWindowWide: (isWindowWide) => set({ isWindowWide }),
}))
