import { create } from 'zustand'

interface ActiveTabState {
    activeTab: number
    setActiveTab: (id: number) => void
}

export const useActiveTabStore = create<ActiveTabState>((set) => ({
    activeTab: null,
    setActiveTab: (id: number) => set({ activeTab: id }),
}))
