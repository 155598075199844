import Skeleton from 'react-loading-skeleton'

const PaymentsPageSkeleton = () => {
    return (
        <>
            <div className="d-flex flex-column flex-xl-row">
                <div className="col-12 col-xl-8">
                    <div className="px-3">
                        <div className="mb-3">
                            <Skeleton width="20%" />
                            <Skeleton width="35%" />
                        </div>
                        <div className="card px-0 w-100 mb-4">
                            <div className="px-3 text-center">
                                <Skeleton width="20%" />
                            </div>
                            <div className="row">
                                <div className="col-6 col-md-4 col-lg-3 text-center px-3">
                                    <Skeleton height={90} className="mt-3" />
                                </div>
                                <div className="col-6 col-md-4 col-lg-3 text-center px-3">
                                    <Skeleton height={90} className="mt-3" />
                                </div>
                                <div className="col-6 col-md-4 col-lg-3 text-center px-3">
                                    <Skeleton height={90} className="mt-3" />
                                </div>
                                <div className="col-6 col-md-4 col-lg-3 text-center px-3">
                                    <Skeleton height={90} className="mt-3" />
                                </div>
                                <div className="col-6 col-md-4 col-lg-3 text-center px-3">
                                    <Skeleton height={90} className="mt-3" />
                                </div>
                                <div className="col-6 col-md-4 col-lg-3 text-center px-3">
                                    <Skeleton height={90} className="mt-3" />
                                </div>
                                <div className="col-6 col-md-4 col-lg-3 text-center px-3">
                                    <Skeleton height={90} className="mt-3" />
                                </div>
                                <div className="col-6 col-md-4 col-lg-3 text-center px-3">
                                    <Skeleton height={90} className="mt-3" />
                                </div>
                                <div className="col-6 col-md-4 col-lg-3 text-center px-3">
                                    <Skeleton height={90} className="mt-3" />
                                </div>
                                <div className="col-6 col-md-4 col-lg-3 text-center px-3">
                                    <Skeleton height={90} className="mt-3" />
                                </div>
                                <div className="col-6 col-md-4 col-lg-3 text-center px-3">
                                    <Skeleton height={90} className="mt-3" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-4 mb-4">
                    <div className="px-3">
                        <div className="mb-3">
                            <Skeleton width="20%" />
                            <Skeleton width="35%" />
                        </div>

                        <div className="card d-flex flex-row flex-wrap gap-2">
                            <Skeleton height={29} width={27} />
                            <Skeleton height={29} width={27} />
                            <Skeleton height={29} width={27} />
                            <Skeleton height={29} width={27} />
                            <Skeleton height={29} width={27} />
                            <Skeleton height={29} width={27} />
                            <Skeleton height={29} width={27} />
                            <Skeleton height={29} width={27} />
                            <Skeleton height={29} width={27} />
                            <Skeleton height={29} width={27} />
                            <Skeleton height={29} width={27} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-3">
                <Skeleton width="20%" />
                <div className="mt-3 bg-white shadow-sm py-2 px-3">
                    <div className="d-flex flex-grow-1 align-items-center py-2 position-relative">
                        <Skeleton
                            width={19}
                            height={15}
                            className="position-absolute start-0 top-50 translate-middle mx-2"
                        />
                        <div className="col-6" style={{ marginLeft: '50px' }}>
                            <Skeleton width="30%" />
                            <Skeleton width="15%" />
                        </div>
                        <div className="col-5 d-flex align-items-center">
                            <div className="col-4 col-md-6 payment-history-cost flex-grow-1 align-items-center">
                                <Skeleton width="15%" height={22} />
                            </div>
                            <div className="col-2 col-md-6">
                                <Skeleton width={28} height={22} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentsPageSkeleton
