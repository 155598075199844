import { useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import ReviewForm from '@/Pages/InstructorsPage/InstructorProfile/ReviewForm.tsx'
import Reviews from '@/Pages/InstructorsPage/InstructorProfile/Reviews.tsx'
import InstructorHeader from '@/Pages/InstructorsPage/InstructorProfile/InstructorHeader.tsx'
import InstructorPhotoSlider from '@/Pages/InstructorsPage/InstructorProfile/InstructorPhotoSlider.tsx'
import InstructorProfileSkeleton from '@/Pages/InstructorsPage/InstructorProfile/InstructorProfileSkeleton.tsx'
import { useQuery } from '@tanstack/react-query'
import api from '@/api/api.ts'
import { ICurrentInstructorCar } from '@/types/IInstructors.ts'
import { useUserQuery } from '@/lib/useUserQuery.ts'

const InstructorProfile = () => {
    const { id } = useParams<{ id: string }>()

    const user = useUserQuery()
    const schoolInfo = useQuery({
        queryKey: ['school', 'info', user],
        queryFn: async () => await api.get('info'),
        staleTime: Infinity,
        enabled: !!user,
    })

    const { data: currentInstructorData } = useQuery({
        queryKey: ['current', 'instructor', id],
        queryFn: () => api.get(`/instructor/${id}`),
        enabled: !!id && !!schoolInfo.data,
    })
    const { data: instructorReviewsData } = useQuery({
        queryKey: ['instructor', 'reviews', id],
        queryFn: () => api.get(`/instructor/${id}/reviews`),
        enabled: !!id && !!currentInstructorData,
    })

    const location = useLocation()
    const reviewsRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (
            instructorReviewsData?.data !== null &&
            location.hash === '#reviews' &&
            reviewsRef.current
        ) {
            const elementPosition =
                reviewsRef.current.getBoundingClientRect().top - 90
            window.scrollTo({
                top: elementPosition,
                behavior: 'smooth',
            })
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [instructorReviewsData])

    return (
        <>
            {!currentInstructorData?.data ? (
                <InstructorProfileSkeleton />
            ) : (
                <>
                    <InstructorHeader instructor={currentInstructorData.data} />
                    <div className="instructor__profile page">
                        <div className="instructor__profile-cars">
                            {currentInstructorData.data.cars.map(
                                (car: ICurrentInstructorCar, index: number) => (
                                    <InstructorPhotoSlider
                                        key={index}
                                        car={car}
                                    />
                                ),
                            )}
                        </div>
                        <ReviewForm />
                        <div ref={reviewsRef}>
                            {instructorReviewsData?.data && (
                                <Reviews reviews={instructorReviewsData.data} />
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default InstructorProfile
