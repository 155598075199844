import { IExams } from '@/types/ISchoolResults.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

interface Props {
    examResults: IExams[]
}

const SchoolExamsCards = ({ examResults }: Props) => {
    const [openExams, setOpenExams] = useState([])

    const toggleErrorsTable = (exam: IExams) => {
        const isOpen = openExams.includes(exam)
        if (isOpen) {
            setOpenExams(openExams.filter((item) => item !== exam))
        } else {
            setOpenExams([...openExams, exam])
        }
    }

    return (
        <div className="school__exams-page page">
            <h2 className="school__exams-page-title common__title">
                Внутренние экзамены «Город»
                <span className="school__exams-page-title-badge badge">
                    {examResults?.length}
                </span>
            </h2>

            {!examResults.length ? (
                <p>Экзаменов еще не было</p>
            ) : (
                examResults.map((examResult, index) => (
                    <div className="school__exams-card card" key={index}>
                        <p className="school__exams-card-route">
                            Маршрут № {examResult.route}
                        </p>
                        <div className="school__exams-card-info">
                            <div className="school__exams-card-info-date">
                                <p>Дата</p>
                                <p className="fw-bold">
                                    {format(examResult.date, 'P', {
                                        locale: ru,
                                    })}
                                </p>
                            </div>
                            <div className="school__exams-card-info-examiner">
                                <p>Экзаменатор</p>
                                <p className="fw-bold">
                                    {examResult.instructor.name}
                                    {/*!todo ФИО с инициалами. Пофиксить на сервере */}
                                </p>
                            </div>
                            <div className="school__exams-card-info-points">
                                <p>Баллы</p>
                                <p className="fw-bold">{examResult.points}</p>
                            </div>
                            <div className="school__exams-card-info-result">
                                <p>Результат</p>
                                <p>
                                    {examResult.points >= 5 ? (
                                        <FontAwesomeIcon
                                            icon={['fas', 'xmark']}
                                            color="red"
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={['fas', 'check']}
                                            color="green"
                                        />
                                    )}
                                </p>
                            </div>
                            <button
                                className={`school__exams-card-info-errors-btn btn collapsed ${
                                    !examResult.errors.length
                                        ? 'btn-outline-success'
                                        : 'btn-outline-warning'
                                }`}
                                onClick={() => toggleErrorsTable(examResult)}
                                aria-controls={`test-${index}`}
                                aria-expanded={openExams?.includes(examResult)}
                                style={{
                                    pointerEvents: !examResult.errors.length
                                        ? 'none'
                                        : 'auto',
                                }}
                            >
                                {!examResult.errors.length
                                    ? 'Нет ошибок'
                                    : 'Ошибки'}
                            </button>
                        </div>
                        <Collapse in={openExams?.includes(examResult)}>
                            <div id={`test-${index}`}>
                                <table className="table table-sm table-striped table-bordered mt-2 mb-0">
                                    <thead>
                                        <tr>
                                            <th>
                                                Ошибки и нарушения, допущенные в
                                                процессе экзамена
                                            </th>
                                            <th>Баллы</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {examResult.errors.map(
                                            (error, errorIndex) => (
                                                <tr key={errorIndex}>
                                                    <td>{error.name}</td>
                                                    <td>{error.points}</td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Collapse>
                    </div>
                ))
            )}
        </div>
    )
}

export default SchoolExamsCards
