import { create } from 'zustand'

interface AuthWithTelegramState {
    telegramConfirm: boolean
    setTelegramConfirm: (arg: boolean) => void
    passwordFieldVisible: boolean //!todo passwordFieldVisible можно убрать из стейта, в компоненте можно вычислять как telegramConfirm === false
    setPasswordFieldVisible: (arg: boolean) => void
    modalVisible: boolean
    setModalVisible: (arg: boolean) => void
}

export const useAuthWithTelegramStore = create<AuthWithTelegramState>()(
    (set) => ({
        telegramConfirm: null,
        setTelegramConfirm: (telegramConfirm) => set({ telegramConfirm }),
        passwordFieldVisible: false,
        setPasswordFieldVisible: (passwordFieldVisible) =>
            set({ passwordFieldVisible }),
        modalVisible: null,
        setModalVisible: (modalVisible) => set({ modalVisible }),
    }),
)
